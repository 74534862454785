import styled from "styled-components";

export const ModelPageWrapper = styled.div`
  .mt-35 {
    margin-top: 35px;
  }
  .model-button {
    display: flex;
    gap: 16px;
    margin-top: 36px;
    margin-bottom: 36px;
    justify-content: center;
  }

  @media (max-width: 1024px) {
  }
`;
