import { useEffect, useState } from "react";
import { ViewModel } from "./ViewModel";

export function useViewModel<T extends ViewModel>(viewModel: T) {
    let [_, setState] = useState<{}>();
    useEffect(() => {
        const callback = () => setState({});
        viewModel.addView(callback);
        return () => {
            viewModel.removeView(callback);
        };
    }, [viewModel]);
    return viewModel;
}
