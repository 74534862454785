import { Layout, MenuProps, Dropdown, Space, Typography } from "antd";
import { LayoutWrapper } from "./styles";
import { DownOutlined } from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import LogoutIcon from "components/icons/LogoutIcon";
import Paragraph from "components/assets/text/Paragraph";
import { useAppDispatch } from "redux/hooks";
import { signOut } from "redux/slices/user";
import { requestStart } from "redux/slices/app";
import Model from "redux/classes/Model";
import Job from "redux/classes/Job";
import { UserOutlined } from '@ant-design/icons';
import VELOCITY from "components/icons/VELOCITY";
import { useSession } from "../SessionProvider";

const { Content } = Layout;
const { Header } = Layout;

interface LayoutPropsTypes {
  children: JSX.Element;
}

const CustomLayout: React.FC<LayoutPropsTypes> = ({ children }) => {
  const dispatch = useAppDispatch()
  const user = useSelector((state: any) => state.user)
  const app = useSelector((state: any) => state.app)
  const session = useSession();

  const modelIndex = user.modelIndex;
  const jobIndex = user.jobIndex;

  let selectedModel = new Model("", "", "", "", "", "", "", [], [], [], [], "");
  let selectedJob = new Job("", "", "", "", "", "", "", "", "", "", new Date(), new Date());

  if (modelIndex !== -1) {
    selectedModel = user.models[modelIndex];
  }
  if (jobIndex !== -1) {
    selectedJob = user.jobs[jobIndex];
  }

  // Page name on Header
  let homeText = <Link
    to="/"
    className={app.loading || app.submitting ? "disabled breadcrumb" : "breadcrumb"}
    title={app.loading || app.submitting ? "Cannot navigate between pages while loading": "Links to the Home Page"}
  >
    Home
  </Link>;
  const pageLocation = useLocation().pathname;
  let header = <></>;

  if (pageLocation === `/`) {
    header = (
      <div className="breadcrumbs">
        <div
          className="breadcrumb"
          title="You are on the Home Page"
        >Home</div>
      </div>
    );
  }
  else if (pageLocation === `/${encodeURIComponent(selectedModel.alias)}/info`) {
    header = (
      <div className="breadcrumbs">
        {homeText}
        <div className="breadcrumb">{" > "}</div>
        <div
          className="breadcrumb"
          title={"You are on the Model Page for " + selectedModel.name}
        >{selectedModel.name}</div>
      </div>
    );
  }
  else if (pageLocation === `/${encodeURIComponent(selectedModel.alias)}/qa`) {
    header = (
      <div className="breadcrumbs">
        {homeText}
        <div className="breadcrumb">{" > "}</div>
        <Link
          to={app.loading || app.submitting ? pageLocation : `/${encodeURIComponent(selectedModel.alias)}/info`}
          className={app.loading || app.submitting ? "disabled breadcrumb" : "breadcrumb"}
          title={app.loading || app.submitting ? "Cannot navigate between pages while loading": "Links to the Model Page for " + selectedModel.name}
        >
          {selectedModel.name}
        </Link>
        <div className="breadcrumb">{" > "}</div>
        <div
          className="breadcrumb"
          title={"You are on the QA Checks Page for " + selectedModel.name}
        >QA Checks</div>
      </div>
    );
  }
  else if (pageLocation === `/${encodeURIComponent(selectedModel.alias)}/flow`) {
    header = (
      <div className="breadcrumbs">
        {homeText}
        <div className="breadcrumb">{" > "}</div>
        <Link
          to={app.loading || app.submitting ? pageLocation : `/${encodeURIComponent(selectedModel.alias)}/info`}
          className={app.loading || app.submitting ? "disabled breadcrumb" : "breadcrumb"}
          title={app.loading || app.submitting ? "Cannot navigate between pages while loading": "Links to the Model Page for " + selectedModel.name}
        >
          {selectedModel.name}
        </Link>
        <div className="breadcrumb">{" > "}</div>
        <div
          className="breadcrumb"
          title={"You are on the Model Flow Page for " + selectedModel.name}
        >Model Flow</div>
      </div>
    );
  }
  else if (pageLocation === `/${encodeURIComponent(selectedModel.alias)}/feedback`) {
    header = (
      <div className="breadcrumbs">
        {homeText}
        <div className="breadcrumb">{" > "}</div>
        <Link
          to={app.loading || app.submitting ? pageLocation : `/${encodeURIComponent(selectedModel.alias)}/info`}
          className={app.loading || app.submitting ? "disabled breadcrumb" : "breadcrumb"}
          title={app.loading || app.submitting ? "Cannot navigate between pages while loading": "Links to the Model Page for " + selectedModel.name}
        >
          {selectedModel.name}
        </Link>
        <div className="breadcrumb">{" > "}</div>
        <div
          className="breadcrumb"
          title={"You are on the Model Feedback Page for " + selectedModel.name}
        >Feedback</div>
      </div>
    );
  }
  else if (pageLocation === `/${encodeURIComponent(selectedModel.alias)}/run`) {
    header = (
      <div className="breadcrumbs">
        {homeText}
        <div className="breadcrumb">{" > "}</div>
        <Link
          to={app.loading || app.submitting ? pageLocation : `/${encodeURIComponent(selectedModel.alias)}/info`}
          className={app.loading || app.submitting ? "disabled breadcrumb" : "breadcrumb"}
          title={app.loading || app.submitting ? "Cannot navigate between pages while loading": "Links to the Model Page for " + selectedModel.name}
        >
          {selectedModel.name}
        </Link>
        <div className="breadcrumb">{" > "}</div>
        <div
          className="breadcrumb"
          title={"You are on the Run Page for " + selectedModel.name}
        >Run</div>
      </div>
    );
  }
  else if (pageLocation === `/${encodeURIComponent(selectedModel.alias)}/job/${selectedJob.jobID}`) {
    header = (
      <div className="breadcrumbs">
        {homeText}
        <div className="breadcrumb">{" > "}</div>
        <Link
          to={app.loading || app.submitting ? pageLocation : `/${encodeURIComponent(selectedModel.alias)}/info`}
          className={app.loading || app.submitting ? "disabled breadcrumb" : "breadcrumb"}
          title={"Links to the Model Page for " + selectedModel.name}
        >
          {selectedModel.name}
        </Link>
        <div className="breadcrumb">{" > "}</div>
        <div
          className="breadcrumb"
          title={"You are on the Job Page for " + selectedJob.jobName}
        >Job</div>
        {selectedJob.status !== "RUNNING" &&
          <>
            <div className="breadcrumb">{" > "}</div>
            <Link
              to={app.loading || app.submitting ? pageLocation : `/${encodeURIComponent(selectedModel.alias)}/results/${selectedJob.jobID}`}
              className={app.loading || app.submitting ? "disabled breadcrumb" : "breadcrumb"}
              title={"Links to the Result Page for " + selectedJob.resultName}
              onClick={() =>  dispatch(requestStart())}
            >
              Result
            </Link>
          </>
        }
      </div>
    );
  }
  else if (pageLocation === `/${encodeURIComponent(selectedModel.alias)}/results/${selectedJob.jobID}`) {
    header = (
      <div className="breadcrumbs">
        {homeText}
        <div className="breadcrumb">{" > "}</div>
        <Link
          to={app.loading || app.submitting ? pageLocation : `/${encodeURIComponent(selectedModel.alias)}/info`}
          className={app.loading || app.submitting ? "disabled breadcrumb" : "breadcrumb"}
          title={"Links to the Model Page for " + selectedModel.name}
        >
          {selectedModel.name}
        </Link>
        <div className="breadcrumb">{" > "}</div>
        <Link
          to={app.loading || app.submitting ? pageLocation : `/${encodeURIComponent(selectedModel.alias)}/job/${selectedJob.jobID}`}
          className={app.loading || app.submitting ? "disabled breadcrumb" : "breadcrumb"}
          title={"Links to the Job Page for " + selectedJob.jobName}
        >
          Job
        </Link>
        <div className="breadcrumb">{" > "}</div>
        <div
          className="breadcrumb"
          title={"You are on the Result Page for " + selectedJob.resultName}
        >Result</div>
      </div>
    );
  }

  const items: MenuProps['items'] = [
    {
      key: "1",
      label: (
        <LayoutWrapper>
          <div
            onClick={() => {
              dispatch(signOut());
              session.logout();
            }}
          >
            <div
              className="logout-icon"
              title="Sign out of VELOCITY"
            >
              <LogoutIcon />
              Log Out
            </div>
          </div>
        </LayoutWrapper>
      ),
    },
  ];

  return (
    <LayoutWrapper>
      <Layout className="layout-container fill-viewport">
        <Header className="header-bg-color">
          <div className="header">
            <div className="flex">
              <Link
                to={app.loading || app.submitting ? pageLocation : "/"}
                className={app.loading || app.submitting ? "disabled" : ""}
              >
                <div className={pageLocation !== '/' ? "clickable" : ""}><VELOCITY scale={1.0}/></div>
              </Link>

              <Paragraph className="color-header text">{header}</Paragraph>
            </div>

            <div className="user-profile clickable">
              <div
                className="dropdown"
                title="Manage Account"
              >
                {/* {showSearch && <InputSearch />} */}
                <Dropdown
                  trigger={["click"]}
                  menu={{ items }}
                  placement="bottomRight"
                >
                  <Typography.Link>
                    <Space>
                      <p className="color-header ">{session.userDisplayName}</p>

                      <UserOutlined className="icon"/>
                    </Space>
                  </Typography.Link>
                </Dropdown>
              </div>
            </div>
          </div>
        </Header>
        <Layout className="site-layout">
          <Content className="content-padding flex-column">{children}</Content>
        </Layout>
      </Layout>
    </LayoutWrapper>
  );
};

export default CustomLayout;
