import { Card, Col, Form, Row, Select } from "antd";
import SecondaryHeading from "components/assets/text/SecondaryHeading";
import { InputFilesWrapper } from "./styles";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { createRef, RefObject, useEffect } from "react";
import { modifyConfig, setJobPanelsComplete, updateJobPanelsComplete } from "redux/slices/user";
import ButtonVelocity from "components/assets/utilities/ButtonVelocity";
import { UploadInputAction } from "redux/actions/UploadInput";
import { useSession } from "components/wrappers/SessionProvider";
import { LoadFilesAction } from "redux/actions/LoadFiles";
import { requestFailure } from "redux/slices/app";
import { notification } from "antd";

const InputFiles = () => {

  const dispatch = useAppDispatch()
  const app = useAppSelector(state => state.app)
  const user = useAppSelector(state => state.user)
  const modelInfo = user.models[user.modelIndex];
  const inputs = modelInfo.inputs;
  const session = useSession();

  const [form] = Form.useForm();

  const fileInputRefs = [...Array(inputs.length)].map(() => createRef() as RefObject<HTMLInputElement>);

  useEffect(() => {
    if (user.config) {
      dispatch(modifyConfig({
        type: "inputs",
        value: {}
      }))
    }
  }, [user.config])

  useEffect(() => {
    if (app.submitting) {
      form.validateFields().then(() => {
        dispatch(updateJobPanelsComplete({ panel: 'inputs', complete: true }))
      }).catch(() => {
        dispatch(setJobPanelsComplete({ jobPanelsComplete: undefined }))
      })
    }
  }, [app.submitting])

  const handleButtonClick = (index: number) => {
    const fileInputRef = fileInputRefs[index]?.current;
    if (fileInputRef) {
      fileInputRef.click();
    }
  };

  const handleFileChange = (event: any, s3_subfolder: string, index: number) => {
    const file = event.target.files[0];
    if (file) {
      const uploadThenGetFiles = async () => {
        notification.info({
          message: 'Uploading file',
          description: `The file ${file.name} is being uploaded to the S3 bucket.`,
          placement: 'top',
          duration: 5
        });

        await dispatch(UploadInputAction(session, modelInfo.alias, file, s3_subfolder));
        await dispatch(LoadFilesAction(session, modelInfo.alias, modelInfo.inputs));

        notification.success({
          message: 'File uploaded',
          description: `The file ${file.name} has been uploaded to the S3 bucket.`,
          placement: 'top',
          duration: 5
        });
      }
      uploadThenGetFiles().catch(() => dispatch(requestFailure()))
    }
  };

  return (
    <InputFilesWrapper>
      <Card className="mt-24 card-custom">
        <SecondaryHeading title="Select the Input Files that should be used in your job">Input Files</SecondaryHeading>

        {inputs.length > 0 && (
          <Form
            form={form}
            colon={false}
            layout="vertical"
            autoComplete="off"
            requiredMark={false}
          >
            <Row gutter={[64, 36]}>
              {inputs.map((input: any, index: number) => {
                const path = "/" + modelInfo.alias + "/input/" + (input.s3_subfolder ? (input.s3_subfolder + "/") : "");

                return (
                  <Col span={8} key={input.label}>
                    <Form.Item
                      hasFeedback
                      name={input.label}
                      label={<span title={"Grabbed from S3 bucket path: " + path}>{input.label}</span>}
                      rules={[
                        {
                          required: true,
                          message: "Please select an input file"
                        }
                      ]}
                    >
                      <Select
                        key={input.label}
                        placeholder="Select one"
                        options={user.files[input.label + input.dataType] ?
                          user.files[input.label + input.dataType].map((option: any) => (
                            {
                              label: option.split(`${modelInfo.alias}/input/` + (input.s3_subfolder ? (input.s3_subfolder + "/") : ""))[1],
                              value: option
                            }
                          )).sort().reverse() :
                          []
                        }
                        onChange={(e) => {
                          let inputs = user.config.inputs
                          inputs[input.label] = e.split(`${modelInfo.alias}/input/`)[1]
                          dispatch(modifyConfig({
                            type: "inputs",
                            value: inputs
                          }))
                        }}
                        showSearch
                        disabled={app.submitting}
                      />
                    </Form.Item>

                    <ButtonVelocity
                      type="upload"
                      title={"Upload a file to S3 bucket path: " + path}
                      path={null}
                      onClick={() => handleButtonClick(index)}
                    >
                      Upload
                    </ButtonVelocity>
                    <input
                      type="file"
                      ref={fileInputRefs[index]}
                      style={{ display: 'none' }}
                      onChange={(e) => {handleFileChange(e, input.s3_subfolder ? input.s3_subfolder + "/" : "", index)}}
                      accept={input.dataType}
                    />
                  </Col>
                )
              })}
            </Row>
          </Form>
        )}
      </Card>
    </InputFilesWrapper>
  );
};

export default InputFiles;