import { useViewModel } from "mvvm/View";
import { Popover, Select } from "antd";
import { ParentWrapper } from "styles/global";
import { TagViewModel } from "../ViewModels/TagViewModel";
import { useEffect, useRef, useState } from "react";

export function TagView(props: { model: TagViewModel, tagList: string[] }) {

    const vm = useViewModel(props.model);

    const selectRef = useRef<any>(null);
    const [isPopoverVisible, setIsPopoverVisible] = useState(false);

    const setFocus = () => {
        setTimeout(() => {
            selectRef.current.focus();
        }, 200);
    }

    useEffect(() => {
        if (vm.isOpen) {
            setFocus();
        }
    }, [vm.isOpen]);

    return (
        <Popover
            trigger="click"
            placement="right"
            onOpenChange={open => vm.isOpen = open}
            open={vm.isOpen}
            overlayInnerStyle={{ width:"30em", padding: "0", marginRight: "-0.5em"}}
            content={
                <ParentWrapper>
                    <div className="flex-column text-content hugging">
                        <Select
                            mode="tags"
                            ref={selectRef}
                            autoFocus={true}
                            className={"flex-1"}
                            showAction={['focus', 'click']}
                            style={{ resize: "none", fontSize: "1rem", zIndex: 1000 }}
                            placeholder="Enter or select a tag..."
                            size="small"
                            allowClear={true}
                            maxTagCount='responsive'
                            value={vm.tags.filter(e => e !== "").map(e => e.trim())}
                            onChange={e => {
                                if (!vm.isSaving) {
                                    vm.tags = Array.from(new Set(e.map(tag => tag.trim().toUpperCase()).filter(e => e !== "")));
                                    vm.update();
                                }
                            }}
                            onInputKeyDown={e => {
                                if (e.key === 'Tab') {
                                    e.preventDefault();
                                }
                                if (e.key === 'Enter' && e.currentTarget.value === "") {
                                    const oldTags = vm.tags;
                                    setTimeout(() => {
                                        vm.tags = oldTags;
                                    }, 0);
                                }
                            }}
                        >
                            {props.tagList.sort().map(tag => (
                                <Select.Option
                                    key={tag}
                                    value={tag}
                                    style={vm.isSaving ? {color: "#ccc", cursor: "default"} : {}}
                                >
                                    {tag}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                </ParentWrapper>
            }
        >
            <button title={vm.tags.join(", ")} className="small-secondary-btn" style={{margin: "0 0.25em"}}>
                Tag
            </button>
        </Popover>
    );
}