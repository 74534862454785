import { JobViewModel } from "../ViewModels/JobViewModel";
import { useViewModel } from "mvvm/View";
import { MarkdownViewer } from "components/assets/utilities/MarkdownViewer";
import { Table } from "antd";
import { TagView } from "./TagView";
import { FeedbackView } from "./FeedbackView";
import { useEffect } from "react";


export function JobDetailView(props: { model: JobViewModel, tagList: string[], tagUpdater: (jobID: string, tags: string[]) => void }) {

    const job = useViewModel(props.model);
    const { status, statusMessage, outputState } = job;

    useEffect(() => {
        props.tagUpdater(job.jobID, job.tags)
    }, [job.tags])

    return (
        <div className="flex-1 flex-column">
            <div className="flex-row" style={{ margin: "0.5em 0", cursor: "default", userSelect: "none"}}>
                <div className="flex-1" style={{paddingLeft: "0.5em"}}>
                    <span style={{opacity: job.canShowSQL && job.showSQL ?  0.5 : 1, fontWeight: "bold", cursor: job.canShowSQL ? "pointer" : "default"}} onClick={()=>job.showSQL = false}>Response</span>
                    {job.canShowSQL && <>
                        <span style={{opacity: 0.25, margin: "0 1.5em"}}>|</span>
                        <span style={{opacity: job.showSQL ? 1 : 0.5, fontWeight: "bold", cursor: "pointer"}} onClick={()=>job.showSQL = true}>SQL</span>
                    </>}
                </div>
                {job.canDownloadOutput && <>
                    <TagView model={job.tagViewModel} tagList={props.tagList} />
                    <button className="small-secondary-btn download" onClick={() => job.downloadOutput()}>Download</button>
                    <FeedbackView model={job.feedbackUpViewModel} />
                    <FeedbackView model={job.feedbackDownViewModel} />
                </>}

            </div>
            <div className="flex-1 flex-column scroll" style={{ border: "1px solid #ccc", borderRadius: "0.5em", padding: "0.5em"}}>
                {status === "RUNNING" && <div style={{opacity: 0.5}}>{statusMessage||"Processing request..."}</div>}
                {outputState.status === "loading" && <div style={{opacity: 0.5}}>Loading response...</div>}
                {outputState.status === "load-failed" && <div style={{color: "red"}}>{outputState.error}</div>}
                {outputState.status === "loaded" && <>
                    {outputState.output.Response && job.canShowSQL && job.showSQL
                        ?  <MarkdownViewer markdown={outputState.output.Response.content} />
                        : outputState.output.Error
                            ? <div style={{whiteSpace: "pre-wrap"}}>{outputState.output.Error.content}</div>
                            : outputState.output.Data
                                ? <div className="flex-1 scrolling-table-container" style={{position: "relative"}}>{<Table size="small" pagination={false} columns={outputState.output.Data.content.columns} dataSource={outputState.output.Data.content.dataSource} />}</div>
                                : outputState.output.Response
                                    ? <MarkdownViewer markdown={outputState.output.Response.content} />
                                    : <div style={{color: "red"}}>The job completed but no response was generated.</div>
                    }
                </>}
            </div>
        </div>
    )
}