import Card from "antd/es/card/Card";
import { ChartDataWrapper, SheetWrapper } from "./styles";
import { Row, Select } from "antd";
import SubHeading from "components/assets/text/SubHeading";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setSheetIndex } from "redux/slices/user";
import { useEffect, useState } from "react";
import { read, utils } from 'xlsx';
import ButtonVelocity from "../ButtonVelocity";

const ExcelVisuals = ({
  visuals,
  description,
  download,
  extension,
}: any) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(state => state.user)
  const [sheetNames, setSheetNames] = useState<string[]>([]);
  const [content, setContent] = useState<string[]>([]);

  useEffect(() => {
    const fetchContent = async () => {
      const worksheets: string[] = [];

      try {
        const url = atob(visuals[0].result);
        const response = await fetch(url);
        if (!response.ok) throw new Error('Failed to fetch content');
        const data = await response.arrayBuffer();
        const workbook = read(data);
        const wsNames = workbook.SheetNames;
        setSheetNames(wsNames);

        for (let i = 0; i < wsNames.length; i++) {
          const worksheet = utils.sheet_to_html(workbook.Sheets[wsNames[i]]);
          worksheets.push(worksheet);
        }

        setContent(worksheets);

      } catch (error) {
        console.error('Error fetching content:', error);
        worksheets.push('Error fetching content');
      }
    };

    fetchContent();
  }, []); // Assuming visuals is a dependency of this effect


  return (
    <>
      {content.length > 0 &&
        <>
          <ChartDataWrapper>
            <Card>
              <Row className="centered">
                <SubHeading
                  className="right"
                  title={description}
                >Sheet:</SubHeading>

                {sheetNames.length > 1 ? (
                  <Select
                    className="option"
                    options={sheetNames.map((option: any, index: number) => ({
                      index: index,
                      title: "Click to select a different sheet",
                      label: option,
                      value: option
                    }))}
                    onChange={(e) => {
                      for (let i = 0; i < sheetNames.length; i++) {
                        if (sheetNames[i] === e) {
                          dispatch(setSheetIndex({ index: i }))
                        }
                      }
                    } }
                    defaultValue={sheetNames[0]}
                    variant="borderless"
                    size={"large"}
                  />
                ) : sheetNames.length === 1 ? (
                  <div className="option">
                    <SubHeading>{sheetNames[0]}</SubHeading>
                  </div>
                ) : <></>}

                <ButtonVelocity
                  path={null}
                  type="download"
                  title={"Download " + visuals[0].path}
                  onClick={() => {
                    download(atob(visuals[0].result));
                  }}
                >
                  Download (.{extension})
                </ButtonVelocity>
              </Row>
            </Card>
          </ChartDataWrapper>


          <SheetWrapper>
            <div className="sheet" dangerouslySetInnerHTML={{ __html: content[user.sheetIndex] }} />
          </SheetWrapper>
        </>
      }
    </>
  );
};

export default ExcelVisuals;