import { AppDispatch } from "redux/store";
import { CallLambda } from "./CallLambda";
import { setConfig } from "redux/slices/user";
import Session from "redux/classes/Session";

export function LoadJobAction(session: Session, jobID: string, modelAlias: string) {
    return async (dispatch: AppDispatch) => {
        const payload = JSON.stringify({
            "model": modelAlias,
            "jobID": jobID,
        })

        let response: {status: number, data: any} = await dispatch(CallLambda(session, "jobLoader", payload))

        if (response.status === 200) {
            const config = JSON.parse(response.data.config)

            dispatch(setConfig({
                meta: config.meta,
                parameters: config.parameters,
                inputs: config.inputs,
                outputs: config.outputs
            }))
        }
    }
}