import { useViewModel } from "mvvm/View";
import { JobOutputViewModel } from "../ViewModels/JobOutputViewModel";
import { Popover } from "antd";
import { JobOutputContentView } from "./JobOutputContentView";
import { ParentWrapper } from "styles/global";

export function JobOutputView(props: { model: JobOutputViewModel }) {
    const model = useViewModel(props.model);

    return <div>
        {
            Object.entries(model.outputPaths).map(([key, value]) => 
                <div key={key}>
                    <Popover trigger="click" placement="left" content={
                        <ParentWrapper>
                            <div className="flex-column scroll">
                                <JobOutputContentView model={model} label={key} />
                            </div>
                        </ParentWrapper>} >
                        <button className="link-btn">{key}</button>
                    </Popover>
                </div>
            )
        }
    </div>
}