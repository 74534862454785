import { SubtitleWrapper } from "./styles";

const Subtitle = ({ children, className, title }: any) => {
  return (
    <SubtitleWrapper>
      <div className={className} title={title}>{children}</div>
    </SubtitleWrapper>
  );
};

export default Subtitle;
