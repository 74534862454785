import Card from "antd/es/card/Card";
import { ChartDataWrapper } from "./styles";
import { Row, Select } from "antd";
import SubHeading from "components/assets/text/SubHeading";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setHtmlIndex } from "redux/slices/user";
import { useEffect, useState } from "react";
import InnerHTML from 'dangerously-set-html-content'

const Interactives = ({
  visuals,
  description,
}: any) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(state => state.user)
  const [content, setContent] = useState<string[]>([]);

  useEffect(() => {
    const fetchContent = async () => {
      const newContent: string[] = [];

      for (let i = 0; i < visuals.length; i++) {
        try {
          const url = atob(visuals[i].result);
          const response = await fetch(url);
          if (!response.ok) throw new Error('Failed to fetch content');
          const data = await response.text();
          newContent.push(data); // Use push instead of concat for adding to the array
        } catch (error) {
          console.error('Error fetching content:', error);
          newContent.push('Error fetching content'); // Use push here as well
        }
      }

      setContent(newContent);
    };

    fetchContent();
  }, []); // Assuming visuals is a dependency of this effect


  return (
    <>
      {content.length === visuals.length &&
        <>
          <ChartDataWrapper>
            <Card>
              <Row className="centered">
                {visuals.length > 1 ? (
                  <Select
                    className="option"
                    options={visuals.map((option: any, index: number) => ({
                      index: index,
                      title: "Click to select a different visualization",
                      label: option.label,
                      value: option.label
                    }))}
                    onChange={(e) => {
                      for (let i = 0; i < visuals.length; i++) {
                        if (visuals[i].label === e) {
                          dispatch(setHtmlIndex({ index: i }))
                        }
                      }
                    } }
                    defaultValue={visuals[0].label}
                    variant="borderless"
                    size={"large"}
                  />
                ) : visuals.length === 1 ? (
                  <div className="option">
                    <SubHeading>{visuals[0].label}</SubHeading>
                  </div>
                ) : <></>}
              </Row>
            </Card>
          </ChartDataWrapper>

          <InnerHTML html={content[user.htmlIndex]} allowRerender={true} />
        </>
      }
    </>
  );
};

export default Interactives;