export default class Session {

    constructor(
        public readonly apiBaseUrl: string,
        public readonly accessToken: string,
        public readonly userDisplayName: string,
        logout: () => void
    ) {
        this.logout = logout;
    }

    readonly logout: () => void;
}