export default class QA {
    qaID: string;
    type: string;
    category: string;
    name: string;
    description: string;
    fail_threshold: string;
    warn_threshold: string;
    actual: string;
    status: string;

    constructor(qaID: string, type: string, category: string, name: string, description: string, fail_threshold: string, warn_threshold: string, actual?: string, status?: string) {
        this.qaID = qaID;
        this.type = type;
        this.category = category;
        this.name = name;
        this.description = description;
        this.fail_threshold = fail_threshold;
        this.warn_threshold = warn_threshold;
        this.actual = actual ? actual : "";
        this.status = status ? status : "";
    }
}