import { Card, Checkbox, Col, DatePicker, Form, Input, Radio, Row, Select } from "antd";
import SecondaryHeading from "components/assets/text/SecondaryHeading";
import { ParametersWrapper } from "./styles";
import Field from "redux/classes/Field";
import { useAppSelector } from "redux/hooks";
import dayjs from "dayjs";

const { TextArea } = Input;
const { RangePicker } = DatePicker;

const ParametersDisabled = ({
  values
}: any) => {
  const user = useAppSelector(state => state.user)
  const modelInfo = user.models[user.modelIndex];
  const jobInfo = user.jobs[user.jobIndex];
  const fields = modelInfo.parameters;

  return (
    <ParametersWrapper>
      <Card>
        <SecondaryHeading title={"Input Parameters that were used in " + jobInfo.jobName}>Parameters</SecondaryHeading>

        <Form layout="vertical" autoComplete="off">
          <Row gutter={[64, 12]}>
            {fields.map((field: Field) => {
              switch (field.fieldType) {
                case "checkbox":
                  return (
                    <Col span={8} key={field.fieldID}>
                      <Form.Item
                        label={field.label}
                      >
                        <Checkbox
                          checked={values[field.label]}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                  )
                case "checkboxes":
                  return (
                    <Col span={8} key={field.fieldID}>
                      <Form.Item
                        label={field.label}
                      >
                        <Checkbox.Group
                          options={field.options}
                          value={Object.keys(values[field.label]).filter(key => values[field.label][key])}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                  )
                case "date":
                case "week":
                case "month":
                case "quarter":
                case "year":
                  const dateValue = user.config.parameters[field.label]
                  const dateObject = new Date(parseInt(dateValue.substring(0, 4)), parseInt(dateValue.substring(5, 7)), parseInt(dateValue.substring(8, 10)))

                  return (
                    <Col span={8} key={field.fieldID}>
                      <Form.Item
                        label={field.label}
                      >
                        <DatePicker
                          value={dayjs(dateObject)}
                          style={{ 'width': '70%' }}
                          picker={field.fieldType === "date" ? undefined : field.fieldType}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                  )
                case "date range":
                case "week range":
                case "month range":
                case "quarter range":
                case "year range":
                  return (
                    <Col span={8} key={field.fieldID}>
                      <Form.Item
                        label={field.label}
                      >
                        <RangePicker
                          allowClear={false}
                          value={[dayjs(new Date(values[field.label][0]) + ' -08:00'), dayjs(new Date(values[field.label][1]) + ' -08:00')]}
                          picker={field.fieldType === "week range" ? "week" : (
                            field.fieldType === "month range" ? "month" : (
                              field.fieldType === "quarter range" ? "quarter" : (
                                field.fieldType === "year range" ? "year" : undefined
                              )
                            )
                          )}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                  )
                case "radio":
                  return (
                    <Col span={8} key={field.fieldID}>
                      <Form.Item
                        label={field.label}
                      >
                        <Radio.Group
                          value={values[field.label]}
                          disabled
                        >
                          {field.options.map((option: any) => {
                            return (
                              <Radio.Button value={option} key={option}>{option}</Radio.Button>
                            )
                          })}
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  )
                case "dropdown":
                  return (
                    <Col span={8} key={field.fieldID}>
                      <Form.Item
                        label={field.label}
                      >
                        <Input
                          className="custom-input"
                          type="text"
                          value={values[field.label]}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                  )
                case "multi-dropdown":
                  return (
                    <Col span={8} key={field.fieldID}>
                      <Form.Item
                        label={field.label}
                      >
                        <TextArea
                          rows={3}
                          className="custom-input"
                          value={values[field.label].join(", ")}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                  )
                case "textbox small":
                  return (
                    <Col span={8} key={field.fieldID}>
                      <Form.Item
                        label={field.label}
                      >
                        <Input
                          className="custom-input"
                          type="text"
                          value={values[field.label]}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                  )
                case "textbox large":
                  return (
                    <Col span={24} key={field.fieldID}>
                      <Form.Item
                        label={field.label}
                      >
                        <TextArea
                          className="custom-input"
                          rows={8}
                          value={values[field.label]}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                  )
                default:
                  return <></>
              }
            })}
          </Row>
        </Form>
      </Card>
    </ParametersWrapper>
  );
};

export default ParametersDisabled;
