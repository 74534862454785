import { ViewModel } from "mvvm/ViewModel";
import { JobViewModel } from "./JobViewModel";
import { PageViewModel } from "./PageViewModel";

export class QuestionViewModel extends ViewModel {

    private _inputText: string = "";
    private _selectedJob: JobViewModel | undefined = undefined;
    private _isDirty: boolean = false;

    constructor(public readonly page: PageViewModel) {
        super();
    }

    get inputText() {
        return this._inputText;
    }

    set inputText(value: string) {
        if (this._inputText !== value) {
            this._inputText = value;
            this._isDirty = true;
            this.updateViews();
        }
    }

    get selectedJob() {
        return this._selectedJob;
    }

    set selectedJob(value: JobViewModel | undefined) {
        if (this._selectedJob !== value) {
            this._selectedJob = value;
            //if (!this._isDirty) {
                this.inputText = value?.question || "";
                this._isDirty = false;
            //}
            this.updateViews();
        }
    }

    get submitButtonCaption() {
        return this.isSubmitting ? "Submitting" : "Submit";
    }

    get canSubmit() {
        return !this.isSubmitting && this.hasInput; // && (!this.selectedJob || this.selectedJob.status !== "RUNNING" || this._isDirty);
    }

    get hasInput() {
        return !!this.inputText.trim();
    }

    async submit() {
        if (this.canSubmit) {
            this.isSubmitting = true;
            const jobInfo = await this.page.jobService.createJob(this._inputText);
            this.page.addJob(jobInfo);
            this._isDirty = false;
            this.isSubmitting = false;
        }
    }

    get isSubmitting() {
        return this.page.isSubmitting;
    }

    private set isSubmitting(value: boolean) {
        if (this.page.isSubmitting !== value) {
            this.page.isSubmitting = value;
            this.updateViews();
        }
    }

    get canClear() {
        return !this.isSubmitting && (this.hasInput || this.selectedJob !== undefined);
    }

    clearContent() {
        this.inputText = "";
        this._isDirty = false;
    }

    reset() {
        this.inputText = "";
        this._isDirty = false;
        this.page.selectedJob = undefined;
    }
}