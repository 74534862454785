import React, { useCallback } from 'react';
import { useViewModel } from "mvvm/View";
import { JobViewModel } from "../ViewModels/JobViewModel";

export function ContextMenuView(props: {
    model: JobViewModel,
    contextMenu: { visible: boolean, x: number, y: number },
    setContextMenu: React.Dispatch<React.SetStateAction<{ visible: boolean, x: number, y: number }>>,
    contextMenuRef: React.RefObject<HTMLDivElement>
}) {
    const model = useViewModel(props.model);
    const contextMenu = props.contextMenu;
    const setContextMenu = props.setContextMenu;
    const contextMenuRef = props.contextMenuRef;

    const handleContextMenuClickRun = useCallback(() => {
        model.runJob();
        setContextMenu({ visible: false, x: 0, y: 0 });
    }, [model, setContextMenu]);

    const handleContextMenuClickQuestion = useCallback(() => {
        navigator.clipboard.writeText(model.question)
        setContextMenu({ visible: false, x: 0, y: 0 });
    }, [model.question, setContextMenu]);

    const handleContextMenuClickJob = useCallback(() => {
        const currentUrl = window.location.href;
        const lastSlashIndex = currentUrl.lastIndexOf('/');
        const baseUrl = currentUrl.slice(0, lastSlashIndex + 1);

        navigator.clipboard.writeText(`${baseUrl}job/${model.jobID}`)
        setContextMenu({ visible: false, x: 0, y: 0 });
    }, [model.jobID, setContextMenu]);

    const handleContextMenuClickResult = useCallback(() => {
        const currentUrl = window.location.href;
        const lastSlashIndex = currentUrl.lastIndexOf('/');
        const baseUrl = currentUrl.slice(0, lastSlashIndex + 1);

        navigator.clipboard.writeText(`${baseUrl}results/${model.jobID}`)
        setContextMenu({ visible: false, x: 0, y: 0 });
    }, [model.jobID, setContextMenu]);

    const handleContextMenuClickJobPage = useCallback(() => {
        const currentUrl = window.location.href;
        const lastSlashIndex = currentUrl.lastIndexOf('/');
        const baseUrl = currentUrl.slice(0, lastSlashIndex + 1);

        window.open(`${baseUrl}job/${model.jobID}`, '_blank');
        setContextMenu({ visible: false, x: 0, y: 0 });
    }, [model.jobID, setContextMenu]);

    const handleContextMenuClickResultPage = useCallback(() => {
        const currentUrl = window.location.href;
        const lastSlashIndex = currentUrl.lastIndexOf('/');
        const baseUrl = currentUrl.slice(0, lastSlashIndex + 1);

        window.open(`${baseUrl}results/${model.jobID}`, '_blank');
        setContextMenu({ visible: false, x: 0, y: 0 });
    }, [model.jobID, setContextMenu]);

    const handleContextMenuClickDelete = useCallback(() => {
        model.deleteJob();
        setContextMenu({ visible: false, x: 0, y: 0 });
    }, [model, setContextMenu]);

    if (!contextMenu.visible) {
        return null;
    }

    return (
        <div
            ref={contextMenuRef}
            className='context-menu'
            tabIndex={-1}
            style={{ position: 'fixed', top: contextMenu.y, left: contextMenu.x }}
        >
            <button className='context-menu-item' onClick={handleContextMenuClickRun}>Rerun Job</button>
            <div className='context-menu-separator'></div>
            <button className='context-menu-item' onClick={handleContextMenuClickQuestion}>Copy Request</button>
            <button className='context-menu-item' onClick={handleContextMenuClickJob}>Copy Job URL</button>
            <button className='context-menu-item' disabled={model.status === 'RUNNING'} onClick={handleContextMenuClickResult}>Copy Result URL</button>
            <div className='context-menu-separator'></div>
            <button className='context-menu-item' disabled={model.status === 'RUNNING'} onClick={handleContextMenuClickJobPage}>Open Job Page</button>
            <button className='context-menu-item' disabled={model.status === 'RUNNING'} onClick={handleContextMenuClickResultPage}>Open Result Page</button>
            <div className='context-menu-separator'></div>
            <button className='context-menu-item' onClick={handleContextMenuClickDelete}>Delete Job</button>
        </div>
    );
}