import { useViewModel } from "mvvm/View";
import { FeedbackViewModel } from "../ViewModels/FeedbackViewModel";
import { Popover } from "antd";
import { ParentWrapper } from "styles/global";
import TextArea from "antd/es/input/TextArea";
import ThumbsUpSolidIcon from "components/icons/ThumbsUpSolidIcon";
import ThumbsDownSolidIcon from "components/icons/ThumbsDownSolidIcon";
import ThumbsUpIcon from "components/icons/ThumbsUpIcon";
import ThumbsDownIcon from "components/icons/ThumbsDownIcon";


export function FeedbackView(props: { model: FeedbackViewModel}) {

    const vm = useViewModel(props.model);

    return (
        <Popover onOpenChange={open => vm.isOpen = open} open={vm.isOpen} content={
            <ParentWrapper>
                <div className="flex-column text-content">
                    <div>Your feedback helps us improve the quality of VELOCITY's responses.</div>
                    <TextArea rows={3}
                        className="text-content"
                        placeholder={vm.commentPlaceholder}
                        value={vm.comment}
                        disabled={vm.isSaving}
                        onChange={e => vm.comment = e.target.value}
                        style={{resize: "none", fontSize: "1rem"}} />
                    <div className="flex-row" style={{ margin: "0.5em 0"}}>
                        <div className="flex-1"></div>
                        <button className="small-secondary-btn" style={{margin: "0 0.5em"}} disabled={vm.isSaving} onClick={()=>vm.isOpen = false}>Cancel</button>
                        {vm.canDelete && <button className="small-secondary-btn" style={{margin: "0 0.5em"}} disabled={vm.isSaving} onClick={()=>vm.delete()}>Delete Feedback</button>}
                        <button className="small-primary-btn" style={{margin: "0 0.5em"}} disabled={!vm.canSubmit} onClick={()=>vm.submit()}>
                            {vm.isPositive ? <ThumbsUpSolidIcon color="white" /> : <ThumbsDownSolidIcon color="white" />}
                            <span style={{marginLeft: "0.5em"}}>{vm.submitCaption}</span>
                        </button>
                    </div>
                </div>
            </ParentWrapper>
        } trigger="click" placement="top" overlayInnerStyle={{width:"50em"}} >
            <button title={vm.buttonTooltip} className="small-secondary-btn" style={{margin: "0 0.25em"}}>
                {vm.isActive
                    ? vm.isPositive ? <ThumbsUpSolidIcon /> : <ThumbsDownSolidIcon />
                    : vm.isPositive ? <ThumbsUpIcon /> : <ThumbsDownIcon />
                }
            </button>
        </Popover>
    );
}