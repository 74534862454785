import { Col, Form, Input, Row, Typography } from "antd";
import Card from "antd/es/card/Card";
import { InfoPanelWrapper } from "./styles";
import { useAppSelector } from "redux/hooks";
import SubHeading from "components/assets/text/SubHeading";
import Job from "redux/classes/Job";
import JobStatus from "components/icons/JobStatus";

const { Paragraph } = Typography;

const InfoPanelDisabled = ({
  values
}: any) => {
  const user = useAppSelector(state => state.user)
  const modelInfo = user.models[user.modelIndex];
  const jobInfo = user.jobs[user.jobIndex]

  const { TextArea } = Input;

  const timeToInsights = (job: Job) => {
    const start = new Date(job.jobName.substring(0, 10) + ' ' + job.jobName.substring(11, 16))
    const end = job.status === "RUNNING" ?
      new Date(Date.now()):
      new Date(job.resultName.substring(0, 10) + ' ' + job.resultName.substring(11, 16))

    if (job.status === "RUNNING") {
      end.setHours(end.getHours() + 4)
    }

    const minsPerInsight = Math.floor((end.getTime() - start.getTime()) / 60000) % 60
    const hoursPerInsight = Math.floor((end.getTime() - start.getTime()) / 60000 / 60) % 24
    const daysPerInsight = Math.floor((end.getTime() - start.getTime()) / 60000 / 60 / 24)

    return minsPerInsight === 0 ? "<1 min" : (
      (daysPerInsight > 0 ? daysPerInsight + " day" +
        (daysPerInsight === 1 ? " " : "s ") : ""
      ) +
      (hoursPerInsight > 0 ? hoursPerInsight + " hour" +
        (hoursPerInsight === 1 ? " " : "s ") : ""
      ) +
      (minsPerInsight + " min" +
        (minsPerInsight === 1 ? "" : "s")
      )
    )
  }

  return (
    <InfoPanelWrapper>
      <Card>
        <SubHeading
          className="down"
          title="Model Name"
        >{modelInfo.name}</SubHeading>

        <Row gutter={[64, 12]}>
          <Col span={16}>
            <Form layout="vertical" autoComplete="off">
              <Form.Item
                label="Job Description"
                className="shifted-up"
              >
                <TextArea
                  name="info"
                  rows={5}
                  value={values['description']}
                  placeholder="Full Description"
                  className="custom-input"
                  disabled
                />
              </Form.Item>
            </Form>
          </Col>
          <Col className="up" span={8}>
            <Row gutter={[64, 24]} className="spaced">
              <Col md={10}>
                <Paragraph className="card-sub-heading">Job Alias</Paragraph>
              </Col>
              <Col md={14}>
                <Paragraph className="card-sub-text">{jobInfo.alias}</Paragraph>
              </Col>
              <Col md={10}>
                <Paragraph className="card-sub-heading">Run By</Paragraph>
              </Col>
              <Col md={14}>
                <Paragraph className="card-sub-text">{jobInfo.getUserName(user.users)}</Paragraph>
              </Col>
              <Col md={10}>
                <Paragraph className="card-sub-heading">Submitted</Paragraph>
              </Col>
              <Col md={14}>
                <Paragraph className="card-sub-text">{jobInfo.jobName.substring(0, 10) + ' ' + jobInfo.jobName.substring(11, 16)}</Paragraph>
              </Col>
              <Col md={10}>
                <Paragraph className="card-sub-heading">Run Time</Paragraph>
              </Col>
              <Col md={14}>
                <Paragraph className="card-sub-text">{timeToInsights(jobInfo)}</Paragraph>
              </Col>
              <Col md={10}>
                <Paragraph className="card-sub-heading">Status</Paragraph>
              </Col>
              <Col md={14}>
                <JobStatus className="status" content={jobInfo.status}/>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </InfoPanelWrapper>
  );
};

export default InfoPanelDisabled;
