import { AppDispatch } from "redux/store";
import { CallLambda } from "./CallLambda";
import { setFlow } from "redux/slices/user";
import Session from "redux/classes/Session";

export function LoadFlowAction(session: Session, modelAlias: string) {
    return async (dispatch: AppDispatch) => {
        dispatch(setFlow({ flow: "" }));

        const payload = JSON.stringify({
            "model": modelAlias,
            "path": "Flow.jpeg"
        });

        let response: {status: number, data: any} = await dispatch(CallLambda(session, "objectLoader", payload))

        if (response.status === 200) {
            dispatch(setFlow({ flow: response.data.url }));
        }
    }
}