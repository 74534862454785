export default function RunIcon() {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 9 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 9.03125C2.27625 9.03125 0.46875 7.22375 0.46875 5C0.46875 4.115 0.75 3.275 1.2825 2.57C1.37625 2.44625 1.5525 2.42375 1.67625 2.5175C1.8 2.61125 1.82625 2.7875 1.7325 2.91125C1.275 3.515 1.03125 4.23875 1.03125 5C1.03125 6.9125 2.5875 8.46875 4.5 8.46875C6.4125 8.46875 7.96875 6.9125 7.96875 5C7.96875 3.0875 6.4125 1.53125 4.5 1.53125C4.34625 1.53125 4.21875 1.40375 4.21875 1.25C4.21875 1.09625 4.34625 0.96875 4.5 0.96875C6.72375 0.96875 8.53125 2.77625 8.53125 5C8.53125 7.22375 6.72375 9.03125 4.5 9.03125Z"
        fill="#12CB67"
      />
      <path
        d="M4.5 7.90625C2.89875 7.90625 1.59375 6.60125 1.59375 5C1.59375 4.84625 1.72125 4.71875 1.875 4.71875C2.02875 4.71875 2.15625 4.84625 2.15625 5C2.15625 6.29375 3.20625 7.34375 4.5 7.34375C5.79375 7.34375 6.84375 6.29375 6.84375 5C6.84375 3.70625 5.79375 2.65625 4.5 2.65625C4.34625 2.65625 4.21875 2.52875 4.21875 2.375C4.21875 2.22125 4.34625 2.09375 4.5 2.09375C6.10125 2.09375 7.40625 3.39875 7.40625 5C7.40625 6.60125 6.10125 7.90625 4.5 7.90625Z"
        fill="#12CB67"
      />
      <path
        d="M4.5 6.78125C4.34625 6.78125 4.21875 6.65375 4.21875 6.5C4.21875 6.34625 4.34625 6.21875 4.5 6.21875C5.17125 6.21875 5.71875 5.67125 5.71875 5C5.71875 4.32875 5.17125 3.78125 4.5 3.78125C4.34625 3.78125 4.21875 3.65375 4.21875 3.5C4.21875 3.34625 4.34625 3.21875 4.5 3.21875C5.4825 3.21875 6.28125 4.0175 6.28125 5C6.28125 5.9825 5.4825 6.78125 4.5 6.78125Z"
        fill="#12CB67"
      />
    </svg>
  );
}
