import { useViewModel } from "mvvm/View";
import { JobOutputViewModel } from "../ViewModels/JobOutputViewModel";
import { ParentWrapper } from "styles/global";
import { useEffect } from "react";
import { MarkdownViewer } from "components/assets/utilities/MarkdownViewer";
import { Table } from "antd";

export function JobOutputContentView(props: { model: JobOutputViewModel, label: string }) {
    const model = useViewModel(props.model);
    useEffect(()=>{
        model.loadOutput();
    },[]);
    const label = props.label;
    const outputState = model.outputState;
    if (outputState.status === "not-loaded" || outputState.status === "loading") {
        return <div>Loading...</div>
    }
    if (outputState.status === "load-failed") {
        return <div>Load failed: {outputState.error}</div>
    }
    if (outputState.status === "loaded") {
        switch (label) {
            case "Response":
                if (outputState.output.Response) {
                    return <div className="scroll" style={{maxWidth: "80vw", maxHeight: "80vh"}}><MarkdownViewer markdown={outputState.output.Response.content} /></div>
                }
                break;
            case "Data":
                if (outputState.output.Data) {
                    return <div className="scrolling-table-container" style={{maxWidth: "80vw", maxHeight: "80vh"}}>
                        <Table size="small" pagination={false} columns={outputState.output.Data.content.columns} dataSource={outputState.output.Data.content.dataSource} />
                    </div>
                }
                break;
            case "Error":
                if (outputState.output.Error) {
                    return <div style={{whiteSpace: "pre-wrap"}}>{outputState.output.Error.content}</div>
                }
                break;
        }
        return <div>{label} file not found.</div>
    }
    return <div>Unknown state: {outputState.status}</div>
}