import { AppDispatch } from "redux/store";
import { CallLambda } from "./CallLambda";
import Session from "redux/classes/Session";
import { parse } from 'papaparse';

export function SaveObjectAction(session: Session, modelAlias: string, jobID: string, name: string, extension: string) {
    return async (dispatch: AppDispatch) => {

        if (extension === "csv") {

            const fileName = `${name}.csv`;
            const payload = JSON.stringify({
                "model": modelAlias,
                "jobID": jobID,
                "outputs": [fileName],
                "status": "REVIEW"
              });

              const {status, data} = await dispatch(CallLambda(session, "resultLoader", payload));

              if (status !== 200) {
                throw new Error("Failed loading job results");
              }

              if (!data.results[fileName]) {
                throw new Error("Failed loading job results");
              }

              const resultURL = atob(data.results[fileName]);
              const response = await fetch(resultURL);
              if (!response.ok) throw new Error('Failed to fetch content');
              const dataBlob = await response.blob();
              const dataText = await dataBlob.text();
              const resultRows = parse(dataText, {
                header: false,
                skipEmptyLines: true
              }).data;

              const csvFile = resultRows.map((rowData: any, rowIndex: number) => {
                const row = rowData.map((cell: any) => {
                  if (rowIndex === 0) {
                    switch (cell.substring(0,1)) {
                        case "$":
                        case "#":
                        case "%":
                        case "&":
                            cell = cell.substring(1);
                            break;
                    }
                  }
                  const csvText = cell.indexOf(",") >= 0 || cell.indexOf("\"") >= 0 ? `"${cell.replace(/"/g, '""')}"` : cell;
                  return csvText;
                }).join(",");
                return row;
              }).join("\n");

              const blob = new Blob([csvFile], {type: 'text/csv'});
              const url = URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.setAttribute('download', modelAlias + '_' + name + '_' + jobID + '.csv');
              a.setAttribute('href', url);
              a.click();
              URL.revokeObjectURL(url);
              return;
        }

        const resultPath = 'output/' + jobID + '/' + name + '.' + extension;
        const payload = JSON.stringify({
            "model": modelAlias,
            "path": resultPath
        });

        let response: {status: number, data: any} = await dispatch(CallLambda(session, "objectLoader", payload))

        if (response.status === 200) {
            const a = document.createElement('a')
            a.setAttribute('download', resultPath);
            a.setAttribute('href', atob(response.data.url))
            a.click()
        }
    }
}