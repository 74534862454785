import styled from "styled-components";

export const RunNLIPageWrapper = styled.div`
.context-menu {
    background-color: #5e5f5f;
    border: 1px solid #d3d3d3;
    border-radius: 6px;
    box-shadow: 0px 2px 10px rgba(0,0,0,0.2);
    padding: 5px;
    z-index: 100;
    font-family: Arial, sans-serif;
    font-size: 12px;
    color: black;
    line-height: 1.5;
    width: 250px;
}

.context-menu-item {
    padding: 3px 10px;
    background-color: #5e5f5f;
    color: #eaeae9;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    width: 100%;
    text-align: left;
}

.context-menu-item:hover {
    background-color: #007bff;
    color: white;
}

.context-menu-item:disabled {
    color: #a6a7a8;
    cursor: pointer;
    background-color: #5e5f5f;
}

.context-menu-separator {
    border-top: 1px solid #8a8b8c;
    margin: 5px 10px;
}

.question-input {
    resize: none;
    font-size: 1rem;
    border: 1px #ccc solid;
    border-radius: 8px;
    padding-right: 1.5em;
}

.menu-icon {
    position: absolute;
    top: 3.6em;
    right: 0.55em;
    border: none;
    background: none;
    padding: 0;
    z-index: 100;
}

.copy-icon,
.paste-icon,
.clear-icon {
    position: absolute;
    right: 0.4em;
    top: 3em;
    opacity: 0;
    visibility: hidden;
    transition: top 0.3s ease, opacity 0.3s ease, visibility 0.3s;
}

.copy-icon.shown,
.paste-icon.shown,
.clear-icon.shown {
    opacity: 1;
    visibility: visible;
}

.copy-icon.shown {
    top: 4.25em;
}

.paste-icon.shown {
    top: 5.75em;
}

.clear-icon.shown {
    top: 7.15em;
}

.menu-icon.scrolling {
    right: 1.5em;
}

.copy-icon.scrolling {
    right: 1.15em;
}

.paste-icon.scrolling {
    right: 1.15em;
}

.clear-icon.scrolling {
    right: 1.15em;
}

.requests-blocker {
    position: absolute;
    top: 3.5em;
    left: 0;
    right: 0;
    bottom: 1em;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 100;
    display: none;
}

.requests-blocker.loading {
    display: block;
}
`;