export default class Output {
    label: string;
    path: string;
    type: string;
    result: any;

    constructor(label: string, path: string, result: any) {
        this.label = label;
        this.path = path;
        this.type = path.split(".").pop() as string;
        this.result = result;
    }
}