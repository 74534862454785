import { ViewModel } from "mvvm/ViewModel";
import { PageViewModel } from "./PageViewModel";
import { JobOutput, JobOutputPaths } from "services/NLIJobService";

export type JobOutputState = Readonly<
    { status: "not-loaded"|"loading" } |
    { status: "loaded", output: JobOutput } | 
    { status: "load-failed", error: string }>;

export class JobOutputViewModel extends ViewModel {

    constructor(public readonly page: PageViewModel, public readonly jobID: string, public readonly outputPaths: JobOutputPaths) {
        super();
    }

    private _outputState: JobOutputState = { status: "not-loaded" };
    
    get outputState() {
        return this._outputState;
    }

    private set outputState(value: JobOutputState) {
        if (value !== this._outputState) {
            this._outputState = value;
            this.updateViews();
        }
    }

    async loadOutput() {
        if (this._outputState.status === "not-loaded") {
            this.outputState = { status: "loading" };
            try {
                const output = await this.page.jobService.loadJobOutput(this.jobID, this.outputPaths);
                this.outputState = { status: "loaded", output };
            } catch (e) {
                this.outputState = { status: "load-failed", error: this.formatError(e) };
            }
        }
    }
}