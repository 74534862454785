import { ViewModel } from "mvvm/ViewModel";
import { ChatRequest, ChatService } from "../Services/ChatService";

export class PageViewModel extends ViewModel {
    constructor(public readonly chatService: ChatService) {
        super();
    }

    private _isReceiving: boolean = false;
    private _isEmitting: boolean = false;
    private _receivedResponse: string = "";
    private _systemPrompt: string = "";
    private _userPrompt: string = "";

    private _turns: Array<{
        user: string;
        assistant: string;
    }> = [];

    get isBusy() {
        return this._isReceiving || this._isEmitting;
    }

    get userPrompt() {
        return this._userPrompt;
    }

    set userPrompt(value) {
        if (this._userPrompt !== value) {
            this._userPrompt = value;
            this.updateViews();
        }
    }

    get systemPrompt() {
        return this._systemPrompt;        
    }

    set systemPrompt(value) {
        if (this._systemPrompt !== value) {
            this._systemPrompt = value;
            this.updateViews();
        }
    }

    get turns() {
        return this._turns;
    }

    newChat() {
        this._turns = [];
        this.userPrompt = "";
        this.updateViews();
    }

    get canSubmit() {
        return !this.isBusy && !!this._userPrompt;
    }

    async submit(onCompleted: () => void) {
        if (!this.canSubmit) {
            return;
        }
        const request: ChatRequest = {
            user: this._userPrompt
        };
        if (!!this._systemPrompt) {
            request.system = this._systemPrompt;
        };
        if (this._turns.length > 0) {
            request.previousTurns = this._turns;
        }
        this._isReceiving = true;
        this._isEmitting = true;
        this._receivedResponse = "";
        this._turns = [...this._turns, {
            user: this._userPrompt,
            assistant: ""
        }];
        this._userPrompt = "";
        this.updateViews();
        const donePromise = this.chatService.invoke(request, (chunk) => {
            this._receivedResponse += chunk;
            this.updateViews();
        });
        requestAnimationFrame(() => this.emitResponseChars(onCompleted));
        await donePromise;
        this._isReceiving = false;
        this.updateViews();
    }

    private emitResponseChars(onCompleted: () => void) {
        const receivedLength = this._receivedResponse.length;
        const currentTurn = this._turns[this._turns.length - 1];
        let emittedLength = currentTurn.assistant.length;
        const notEmitted = receivedLength - emittedLength;
        if (notEmitted > 0) {
            const toEmit = Math.ceil(notEmitted / 10);
            currentTurn.assistant += this._receivedResponse.substring(emittedLength, emittedLength + toEmit + 1);
            emittedLength = currentTurn.assistant.length;
            this.updateViews();
        }
        if (this._isReceiving || emittedLength < receivedLength) {
            requestAnimationFrame(() => this.emitResponseChars(onCompleted));
        } else {
            this._isEmitting = false;
            this._receivedResponse = "";
            this.updateViews();
            onCompleted();
        }
    }
}
