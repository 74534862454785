import styled from "styled-components";

export const ParentWrapper = styled.div`
  .ant-model-content,
  .ant-model-header {
    border-radius: 10px !important;
  }
  .ant-layout-sider,
  .ant-layout-sider-has-trigger {
    height: 100vh !important;
    border-right: 1px solid #f0f0f0;
  }
  .ant-layout-header,
  .nav-fixed {
    border-bottom: 0.5px solid #e5e5e5;
  }
  .ant-model-header {
    border-bottom: none;
  }
  .ant-menu-item-selected a,
  .ant-menu-item-selected a:hover {
    color: rgba(0, 0, 0, 0.85);
  }
  .signIn-loading {
    padding-right: 10px;
  }

  .color-primary {
    color: #3d9cf5;
  }
  .color-secondary {
    color: #949596;
  }
  .color-heading {
    color: #1c1c1c;
  }
  .color-header {
    color: #545c51;
  }
  .mt-12 {
    margin-top: 12px;
  }
  .mt-24 {
    margin-top: 24px;
  }
  .custom-input {
    padding: 16px;
    border: 1px solid #eaeaea;
    background: #fafafa;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
  .h-56 {
    height: 56px;
  }
  .text-end {
    text-align: right;
  }
  .ant-select-item {
    padding: 16px !important;
  }
  .mb-20 {
    margin-bottom: 20px;
  }
  .mb-6 {
    margin-bottom: 6px !important;
  }
  .text-center {
    text-align: center;
  }

  .loader {
    position: relative;
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    top: 50%;
    left: 50%;
  }
  @keyframes spin {
    0% { transform: translate(-50%, -50%) rotate(0deg); }
    100% { transform: translate(-50%, -50%) rotate(360deg); }
  }

  .loader-job {
    position: relative;
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin-job 2s linear infinite;
    top: 50%;
    left: 50%;
    margin-top: 75px;
  }
  @keyframes spin-job {
    0% { transform: translate(-50%, -50%) rotate(0deg); }
    100% { transform: translate(-50%, -50%) rotate(360deg); }
  }


  .loader-centered {
    display: inline-block;
    position: absolute;
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    top: 50%;
    left: 50%;
    animation: spin-centered 2s linear infinite;
  }
  @keyframes spin-centered {
    0% { transform: translate(-50%, -50%) rotate(0deg); }
    100% { transform: translate(-50%, -50%) rotate(360deg); }
  }

  .fill-viewport {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
  }

  .flex-row {
    display: flex;
    flex-direction: row;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }

  .flex-0 {
    flex: none;
  }

  .flex-1 {
    flex: 1 0 0px;
  }

  .flex-2 {
    flex: 2 0 0px;
  }

  .flex-3 {
    flex: 3 0 0px;
  }

  .flex-4 {
    flex: 4 0 0px;
  }

  .flex-5 {
    flex: 5 0 0px;
  }

  .flex-6 {
    flex: 6 0 0px;
  }

  .selected-item {
    background: #cceeff;
  }

  .scroll {
    overflow: auto;
  }

  .small-primary-btn {
    border: 1px solid #0c77dc;
    color: white;
    background: #0c77dc;
    border-radius: 5px;
    padding: 0.25em 1em;
}

.small-primary-btn:disabled, .small-primary-btn:disabled:hover {
    border: 1px solid #CCCCCC;
    background: #CCCCCC;
    color: #f7f7f7;
}

.small-primary-btn:hover {
    background: #0f6ac0;
}

.small-primary-btn:active {
    background: #0a5a9c;
}

.small-secondary-btn {
    border: 1px solid #0c77dc;
    color: #0c77dc;
    background: white;
    border-radius: 5px;
    padding: 0.25em 1em;
}

.small-secondary-btn:disabled, .small-secondary-btn:disabled:hover {
    border: 1px solid #CCCCCC;
    background: white;
    color: #CCCCCC;
}

.small-secondary-btn:hover {
    background: #f0f7ff;
}

.small-secondary-btn:active {
    background: #e7f0ff;
}

.link-btn {
  border: none;
  background: transparent;
  color: #0c77dc;
}

.link-btn:hover {
  text-decoration: underline;
}

.link-btn:active {
  color: #0a5a9c;
}

.text-content {
    font-size: 1rem !important;
    font-family: "PoppinsRegular", sans-serif !important;
}

.align-top {
    vertical-align: top;
}

textarea::placeholder {
    color: #999;
}

.scrolling-table-container {
  overflow: auto;
}

.scrolling-table-container table {
  font-family: "Roboto", "Arial", sans-serif !important;
  display: inline-block;
}

.scrolling-table-container td {
  vertical-align: top !important;
}

.scrolling-table-container th {
  position: sticky !important;
  top: 0px;
  z-index: 999;
  margin: 0;
  padding: 0;
  text-align: center !important;
}

.float-bottom-right {
  position: fixed;
  bottom: 20px;
  right: 20px;
}
`;
