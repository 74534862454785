import { useAppDispatch, useAppSelector } from "redux/hooks";
import Output from "redux/classes/Output";
import { useEffect, useState } from "react";
import TableVelocity from "../TableVelocity";
import { setOutputIndex } from "redux/slices/user";
import { SaveObjectAction } from "redux/actions/SaveObject";
import Papa from 'papaparse';

const MIN_WIDTH = 150;

const OtherVisuals = ({
  visuals,
  description,
  session,
  alias,
  jobID,
}: any) => {
  const dispatch = useAppDispatch();
  const app = useAppSelector(state => state.app);
  const user = useAppSelector(state => state.user)
  const [content, setContent] = useState<string[]>([]);

  useEffect(() => {
    const fetchContent = async () => {
      const newContent: string[] = [];

      for (let i = 0; i < visuals.length; i++) {
        try {
          const url = atob(visuals[i].result);
          const response = await fetch(url);
          if (!response.ok) throw new Error('Failed to fetch content');
          const data = await response.text();
          newContent.push(data.trim()); // Use push instead of concat for adding to the array
        } catch (error) {
          console.error('Error fetching content:', error);
          newContent.push('Error fetching content'); // Use push here as well
        }
      }

      setContent(newContent);
    };

    fetchContent();
  }, []); // Assuming visuals is a dependency of this effect

  const prepareColumns = (output: Output) => {
    if (output) {
      switch (output.type) {
        case "sql":
        case "txt":
        case "md":
        case "json":
          return [{
            key: "column0",
            title: output.path,
            dataIndex: output.path,
            render: (text: string) => <>
              {text.split('\n').map((paragraph: string, index: number) => {
                return <p key={'p' + index}>{paragraph.replace("  ", "\u00A0\u00A0")}</p>
              })}
            </>
          }];
        case "csv":
          const parsedData = Papa.parse(content[user.outputIndex], { header: true });
          const columns = parsedData.meta.fields!.map((column: string, index: number) => {
            switch (column.substring(0, 1)) {
              case "$":
              case "#":
              case "%":
              case "&":
                column = column.substring(1);
                break;
            }

            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d')!;
            context.font = '14px "PoppinsRegular", sans-serif';
            const width = context.measureText(column).width + 32;

            return {
              key: "column" + index,
              title: column,
              dataIndex: column,
              width: width > MIN_WIDTH ? width : MIN_WIDTH,
            };
          });
          return columns;
      }
    }
  };

  const prepareResult = (output: Output) => {
    if (output) {
      switch (output.type) {
        case "sql":
        case "txt":
        case "md":
        case "json":
          let result: { [key: string]: string } = {
            key: "column0"
          };
          result[output.path] = content[user.outputIndex];
          return [result];
        case "csv":
          const parsedData = Papa.parse(content[user.outputIndex], { header: true });
          return parsedData.data.map((row: any, rowIndex: number) => {
            let result: { [key: string]: string } = {
              key: "row" + rowIndex
            };
            Object.keys(row).forEach((colName: string) => {
              result[colName] = row[colName];
            });
            return result;
          });
      }
    }
  };

  return (
    <>
      {content.length === visuals.length &&
        <TableVelocity
          description="Result files generated by the model"
          data={prepareResult(visuals[user.outputIndex])}
          columns={prepareColumns(visuals[user.outputIndex])}
          vh={"40%"}
          scroll={{ x: "max-content" }}
          loading={app.loading}
          // publishHandler={app.loading || jobInfo.status === 'FAILED' ? undefined : async () => {
          //   dispatch(startSubmitting())
          //   await dispatch(PublishJobAction(session, jobInfo.jobID, modelInfo.alias, user.config.outputs))
          //   dispatch(endSubmitting())
          // }}
          // published={user.jobs && user.jobs[user.jobIndex].status === "PUBLISHED"}
          options={visuals}
          onSwitch={(label: string) => {
            for (let i = 0; i < visuals.length; i++) {
              if (visuals[i].label === label) {
                dispatch(setOutputIndex({ index: i }))
              }
            }
          }}
          showHeader={!["txt", "sql", "md", "json"].includes(visuals[user.outputIndex].type)}
          download={() => dispatch(SaveObjectAction(session, alias, jobID, visuals[user.outputIndex].path.split('.')[0], visuals[user.outputIndex].type))}
          extension={visuals[user.outputIndex].type}
        />
      }
    </>
  );
};

export default OtherVisuals;