import { ViewModel } from "mvvm/ViewModel";
import { JobViewModel } from "./JobViewModel";
import { JobFeedback } from "../../../../services/NLIJobService";

export class FeedbackViewModel extends ViewModel {

    constructor(
        public readonly job: JobViewModel,
        public readonly rating: "up"|"down") {
        super();
    }

    private _comment = "";

    get comment() {
        return this._comment;
    }

    set comment(value: string) {
        this._comment = value;
        this.updateViews();
    }

    get isPositive() {
        return this.rating === "up";
    }

    private _isOpen = false;

    get isOpen() {
        return this._isOpen;
    }

    set isOpen(value: boolean) {
        if (value !== this._isOpen) {
            this._isOpen = value;
            if (value) {
                this.saveError = undefined;
                if (this.job.feedback && this.job.feedback.rating === this.rating) {
                    this.comment = this.job.feedback.comment || "";
                } else {
                    this.comment = "";
                }
            }
            this.updateViews();
        }
    }

    get isUpdate() {
        return !!this.job.feedback;
    }

    get commentPlaceholder() {
        return this.isPositive ? "(Optional) Add a comment" : "Add a comment";
    }

    get canSubmit() {
        return !this.isSaving && (this.isPositive || this.comment.trim().length > 0);
    }

    async submit() {
        this.saveError = undefined;
        this.isSaving = true;
        try {
            const feedback: JobFeedback = {
                rating: this.rating,
                comment: this.comment.trim()
            };
            await this.job.page.jobService.saveJobFeedback(this.job.jobID, feedback, this.job.outputState.status === "loaded" ? this.job.outputState.output : {});
            this.job.feedback = feedback;
            this.isSaving = false;
            this.isOpen = false;
        } catch (error: any) {
            this.saveError = {
                message: "Save failed",
                detail: this.formatError(error)
            };
            this.isSaving = false;
        }
    }

    get submitCaption() {
        return this.isUpdate ? "Update Feedback" : "Submit Feedback";
    }    

    get canDelete() {
        return this.isUpdate && this.isActive;
    }

    async delete() {
        this.saveError = undefined;
        this.isSaving = true;
        try {
            await this.job.page.jobService.deleteJobFeedback(this.job.jobID);
            this.job.feedback = undefined;
            this.isSaving = false;
            this.isOpen = false;
        } catch (error: any) {
            this.saveError = {
                message: "Delete failed",
                detail: this.formatError(error)
            };
            this.isSaving = false;
        }
    }

    get isActive() {
        return !!this.job.feedback && this.job.feedback.rating === this.rating;
    }

    private _isSaving = false;
    get isSaving() {
        return this._isSaving;
    }

    set isSaving(value: boolean) {
        this._isSaving = value;
        this.updateViews();
    }

    private _saveError?: { message: string, detail: string};
    get saveError() {
        return this._saveError;
    }

    set saveError(value: { message: string, detail: string} | undefined) {
        this._saveError = value;
        this.updateViews();
    }

    get buttonTooltip() {
        let tip = '';
        let feedback = this.job.feedback;
        let comment = feedback ? feedback.comment : "";
        if (this.isActive) {
            if (feedback && feedback.comment) {
                tip += `Edit feedback:\n${feedback.comment}`;
            } else {
                tip += "Edit feedback";
            }
        } else {
            if (feedback) {
                tip += "Change feedback";
            } else {
                tip += "Provide feedback";
            }
        }

        return tip;
    }
}