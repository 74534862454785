export default function PassedIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1035_5882)">
        <path
          d="M0 6.7605L1.0605 5.7L3.753 8.39175L10.9447 1.2L12.0052 2.2605L3.75225 10.5127L0 6.7605Z"
          fill="#12CB67"
        />
      </g>
      <defs>
        <clipPath id="clip0_1035_5882">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
