import styled from "styled-components";

export const StatsCardWrapper = styled.div`
  .stats-card-inner {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    height: 80px;
  }
  @media (max-width: 1024px) {
  }
`;
