import styled from "styled-components";

export const LayoutWrapper = styled.div`
  a {
    color: rgb(61, 156, 245);
    :hover {
      color: rgb(61, 156, 245);
    }
  }
  height: 100%;
  background: #f3f3f3;
  .ant-layout-content {
    background: #f3f3f3;
  }
  .ant-layout-sider-light .ant-layout-sider-trigger {
    left: 0;
    border-right: 1px solid #f0f0f0;
  }
  .ant-layout {
    background: #f3f3f3;
  }
  .content-padding {
    padding: 30px;
    padding-top: 10px;
    overflow: auto;
  }
  .negate-content-padding {
    margin: -30px;
    margin-top: -10px;
  }
  .col-adjust {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .header-bg-color {
    background-color: #ffffff;
  }
  .user-profile {
    text-align: right;
  }
  .dropdown {
    display: flex;
    gap: 27px;
    align-items: center;
  }

  .ant-layout-header {
    padding-inline: 24px;
    height: auto;
  }
  .ant-layout-sider-trigger {
    display: none;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
  }
  .text {
    margin-top: 8px !important;
    margin-left: 30px !important;
  }

  .ant-layout-header {
    line-height: 0px;
  }
  .menuIcon {
    display: none;
  }
  .logout-icon {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  @media (max-width: 1024px) {
    .sidebar {
      display: none;
    }
    .ant-layout-header {
      padding-inline: 10px;
    }
    .dropdown {
      display: none;
    }
    .menuIcon {
      display: block;
    }
    .ant-menu-light.ant-menu-root.ant-menu-inline,
    .ant-menu-light > .ant-menu.ant-menu-root.ant-menu-inline,
    .ant-menu-light.ant-menu-root.ant-menu-vertical,
    .ant-menu-light > .ant-menu.ant-menu-root.ant-menu-vertical {
      border-inline-end: none;
    }
    .content-padding {
      padding: 10px;
    }
    .negate-content-padding {
      margin: -10px;
    }
    .ant-menu-light .ant-menu-item-selected,
    .ant-menu-light > .ant-menu .ant-menu-item-selected {
      background-color: transparent !important;
    }
    .ant-menu-light .ant-menu-item-selected,
    .ant-menu-light > .ant-menu .ant-menu-item-selected {
      color: #828282;
    }
  }

  .breadcrumb {
    font-size: 16px;
    font-weight: medium;
    font-family: PoppinsRegular, sans-serif !important;
    margin-right: 5px;
  }
  .breadcrumbs {
    display: flex;
  }
  .disabled {
    cursor: default;
  }

  .flex {
    display: flex;
    gap: 15px;
    align-items: center;
  }

  .icon{
    font-size: 300%;
    color: #1e6692;
  }

  .clickable:hover {
    transform: scale(1.05);
  }

  .download {
    margin-left: 1em;
    margin-right: 1em;
  }
`;
