import Layout from "components/wrappers/Layout";
import Theme from "styles/theme";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import ScrollToTop from "components/assets/utilities/ScrollToTop";
import Home from "./components/pages/Home";
import ModelPage from "./components/pages/Model";
import QACheckPage from "./components/pages/QA";
import ResultPage from "./components/pages/Result";
import JobPage from "./components/pages/Job";
import ModelFlowPage from "components/pages/Flow";
import SessionProvider from "components/wrappers/SessionProvider";
import RunPageSelector from "components/wrappers/RunPageSelector";
import FeedbackPage from "components/pages/Feedback";
import ChatPage from "components/pages/Chat";

function App() {
    return (
        <ThemeProvider theme={Theme}>
            <SessionProvider>
                <BrowserRouter>
                    <ScrollToTop />
                    <Layout>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/chat" element={<ChatPage />} />
                            <Route path="/:modelName/info" element={<ModelPage />} />
                            <Route path="/:modelName/qa"  element={<QACheckPage />} />
                            <Route path="/:modelName/flow" element={<ModelFlowPage />} />
                            <Route path="/:modelName/feedback" element={<FeedbackPage />} />
                            <Route path="/:modelName/run"  element={<RunPageSelector />} />
                            <Route path="/:modelName/job/:jobName" element={<JobPage />} />
                            <Route path="/:modelName/results/:resultName" element={<ResultPage />} />
                        </Routes>
                    </Layout>
                </BrowserRouter>
            </SessionProvider>
        </ThemeProvider>
    );
}

export default App;
