export default function LogoutIcon(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.55333 7.91921L2.16666 6.53254L3.55333 5.14587"
        stroke="#828282"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.71333 6.53247H2.20458"
        stroke="#828282"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.63 10.8333C9.02417 10.8333 10.9633 9.20829 10.9633 6.49996C10.9633 3.79163 9.02417 2.16663 6.63 2.16663"
        stroke="#828282"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
