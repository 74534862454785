import { Button, Row, Col } from "antd";
import { LoginCard, LoginWrapper } from "./styles";
import { useAppSelector } from "redux/hooks";
import VELOCITY from "components/icons/VELOCITY";
import { useAuth } from "react-oidc-context";

const Login = () => {
  const app = useAppSelector(state => state.app);
  const auth = useAuth();

  return (
    <LoginWrapper>
      <LoginCard>
        <Row>
          <Col span={24}>
            <div style={{ textAlign: "center" }}>
              <VELOCITY scale={1.8}/>
            </div>
          </Col>
        </Row>
        <div style={{ textAlign: "center" }}>
          <Button
            type="primary"
            title="Login to VELOCITY (may redirect to the Login Page)"
            htmlType="submit"
            loading={app.loading}
            onClick={() => {
              sessionStorage.setItem("velocity:signinReturnUrl", window.location.href)
              auth.signinRedirect();
            }}>
            Login
          </Button>
        </div>
      </LoginCard>
    </LoginWrapper>
  );
};

export default Login;
