import JobStatus from "components/icons/JobStatus";

export const QAAlertsColumns = [
  {
    title: <span title="Type of the QA Check">Type</span>,
    dataIndex: "type",
    key: "type",
    width: 100,
  },
  {
    title: <span title="Name of the QA Check">Name</span>,
    dataIndex: "name",
    key: "name",
    width: 200,
  },
  {
    title: <span title="Description of the QA Check">Description</span>,
    dataIndex: "description",
    key: "description",
    width: 360,
  },
  {
    title: <span title="Fail Threshold of the QA Check">Fail Threshold</span>,
    dataIndex: "threshold",
    key: "threshold",
    width: 140,
    align: "center"
  },
  {
    //title: "Actual",
    title: <span title="Actual value of the QA Check">Actual</span>,
    dataIndex: "actual",
    key: "actual",
    width: 100,
    align: "center"
  },
  {
    title: <span title="Status of the QA Check [Passed, Warning, Failed]">Status</span>,
    key: "status",
    dataIndex: "status",
    width: 140,
    align: "center",
    render: (status: any) => (
      <>
        <JobStatus content={status} />
      </>
    ),
    filters: [
      {
        text: "Passed",
        value: "PASSED",
      },
      {
        text: "Warning",
        value: "WARNING",
      },
      {
        text: "Failed",
        value: "FAILED",
      },
    ],
    onFilter: (value: string, record: any) => record.status.indexOf(value) === 0
  },
];