import { CallLambda } from "redux/actions/CallLambda";
import { SaveObjectAction } from "redux/actions/SaveObject";
import Config from "redux/classes/Config";
import Model from "redux/classes/Model";
import Session from "redux/classes/Session";
import { AppDispatch } from "redux/store";

export type JobFeedback = Readonly<{
    rating: "up" | "down";
    comment: string;
}>;

export type JobInfo = Readonly<{
    jobID: string;
    question: string;
    startTime: number;
    status: string;
    statusMessage?: string;
    feedback?: JobFeedback;
    tags: string[];
}>;

export type JobState = Readonly<{
    jobID: string;
    status: string;
    statusMessage?: string;
}>;

export type JobOutputItem<TContent> = Readonly<{
  name: string;
  extension: string;
  content: TContent;
}>

export type JobOutput = Readonly<{
    Response?: JobOutputItem<string>;
    Data?: JobOutputItem<JobOutputData>;
    Error?: JobOutputItem<string>;
}>;

export type JobOutputData = Readonly<{
    columns: { title: string, dataIndex: string, key: string, align: "left" | "right" | "center" }[];
    dataSource: { key: string, [dataIndex: string]: string }[];
}>;

export type JobOutputPaths = Readonly<{
  Response?: string;
  Data?: string;
  Error?: string;
}>;

export type LoadJobsResult = Readonly<{
    serverTime: number;
    jobs: readonly JobInfo[];
}>;

export type LoadJobStatesResult = Readonly<{
    serverTime: number;
    jobStates: readonly JobState[];
}>;

export type JobFeedbackItem = Readonly<{
  jobID: string;
  jobStartTime: string;
  feedbackTime: string;
  userID: string;
  question: string;
  rating: "up"|"down";
  comment: string;
  outputs: JobOutputPaths;
}>;

export type LoadJobFeedbackResult = Readonly<{
    cursor?: any;
    items: ReadonlyArray<JobFeedbackItem>;
}>;

export class NLIJobService {

    constructor(
      public dispatch: AppDispatch,
      public session: Session,
      public model: Model) {

    }

    async loadJobs(): Promise<LoadJobsResult> {
      const {status, data} = await this.dispatch(CallLambda(this.session, "nliService", { action: "loadJobs", modelID: this.model.modelID }));
      if (status !== 200) {
          throw new Error("Failed loading previous questions");
      }
      return {
          serverTime: Date.parse(data.serverTime),
          jobs: data.jobs.map((job: any) => ({
              jobID: job.job_id,
              question: job.description,
              startTime: Date.parse(job.start_time),
              status: job.job_status,
              statusMessage: job.running_message || "",
              feedback: job.feedback,
              tags: job.tags ? Object.values(job.tags) : []
          })).sort((a: JobInfo, b: JobInfo) => b.startTime - a.startTime)
      };
    }

    async loadJobStates(jobIDs: readonly string[]): Promise<LoadJobStatesResult> {
      const {status, data} = await this.dispatch(CallLambda(this.session, "nliService", { action: "loadJobStates", jobIDs: jobIDs }));
      if (status !== 200) {
          throw new Error("Failed refreshing job information");
      };
      return {
        serverTime: Date.parse(data.serverTime),
        jobStates: data.jobStates.map((jobState: any) => ({
          jobID: jobState.job_id,
          status: jobState.job_status,
          statusMessage: jobState.running_message
        }))
      }
    }

    async loadJobOutput(jobID: string, cachedOutputPaths?: JobOutputPaths): Promise<JobOutput> {

      let outputPaths: {[key: string]: string};
      if (cachedOutputPaths) {
        outputPaths = cachedOutputPaths;
      } else {
        const payload = JSON.stringify({
          "model": this.model.alias,
          "jobID": jobID,
        })

        const {status, data} = await this.dispatch(CallLambda(this.session, "jobLoader", payload))

        if (status !== 200) {
          throw new Error("Failed loading job details");
        }

        const outputs = JSON.parse(data.config).outputs;
        const errorPath = {
          'Error': 'error.err'
        }
        outputPaths = { ...outputs, ...errorPath };
      }

      const payload = JSON.stringify({
        "model": this.model.alias,
        "jobID": jobID,
        "outputs": Object.values(outputPaths),
        "status": "REVIEW"
      });

      const {status, data} = await this.dispatch(CallLambda(this.session, "resultLoader", payload));

      if (status !== 200) {
        throw new Error("Failed loading job results");
      }

      const results = data.results;

      const jobOutput: { Data?: JobOutputItem<JobOutputData>, Response?: JobOutputItem<string>, Error?: JobOutputItem<string>} = {};
      for (let path in results) {
          let parts = path.split(".");
          let name = parts.slice(0, parts.length - 1).join(".");
          let ext = parts[parts.length - 1];
          const label = Object.keys(outputPaths).find(k => outputPaths[k] === path) as string;

          let data: any;

          try {
            if (label !== "Error") {
              const url = atob(results[path]);
              const response = await fetch(url);
              if (!response.ok) throw new Error('Failed to fetch content');
              data = (await response.text()).trim();
              if (label === "Data") {
                data = data.split('\n').map((row: string) => row.split(','));
              }
            }
            else {
              data = atob(results[path]).trim();
            }
          } catch (error) {
            console.error('Error fetching content:', error);
            continue;
          }

          if (label === "SQL" || label === "Response") {
            jobOutput.Response = {
              name: name,
              extension: ext,
              content: data
            }
          }
          if (label === "Error") {
            jobOutput.Error = {
              name: name,
              extension: ext,
              content: data
            }
          }
          if (label === "Data") {
            const dataRows = data.slice(1).map((row: string[]) => row.map((cell: string) => cell));

            const columnMetadata = data[0].map(() => ({
              type: "text",
              maxDecimals: 0
            })) as Array<{type: "text"|"number", maxDecimals: number}>;

            const columns = data[0].map((header: string, colIndex: number) => {
              let colName = header || "";
              const prefix = colName.substring(0,1);
              let align = "left";
              let maxDecimals = 0;
              switch (prefix) {
                case "#":
                  columnMetadata[colIndex].type = "number";
                  align = "right";
                  colName = colName.substring(1);
                  dataRows.forEach((row: string[]) => {
                    const s = row[colIndex]?.trim();
                    if (s) {
                      let decimals = 0;
                      const dotIndex = s.indexOf(".");
                      if (dotIndex >= 0) {
                        decimals = s.length - dotIndex - 1;
                        if (decimals > maxDecimals) {
                          maxDecimals = decimals;
                        }
                      }
                    }
                  });
                  columnMetadata[colIndex].maxDecimals = maxDecimals;
                  break;
                case "$":
                case "&":
                case "%":
                  colName = colName.substring(1);
                  break;
              }
              return {
                title: colName,
                dataIndex: colIndex.toString(),
                key: colIndex.toString(),
                align: align
              };
            });

            jobOutput.Data = {
              name: name,
              extension: ext,
              content: {
                columns: columns,
                dataSource: dataRows.map((row: string[], rowIndex: number) => {
                  const dataItem: {[key: string]: string} = {
                    key: "row" + rowIndex
                  };
                  data[0].forEach((column: string, colIndex: number) => {
                    let textValue = row[colIndex];
                    const meta = columnMetadata[colIndex];
                    if (meta.type === "number") {
                      const n = parseFloat(textValue);
                      if (!isNaN(n)) {
                        const maximumFractionDigits = 6;
                        const minimumFractionDigits = Math.min(meta.maxDecimals, maximumFractionDigits);
                        textValue = n.toLocaleString(undefined, {minimumFractionDigits, maximumFractionDigits: minimumFractionDigits});
                      }
                    }
                    dataItem[colIndex.toString()] = textValue;
                  });
                  return dataItem;
                })
              }
            };
          }
      }
      return jobOutput;
    }


    async createJob(question: string): Promise<JobInfo> {

      let date = new Date()
      let jobID = date.toISOString()

      let config = new Config(
        {
          alias: `${this.model.alias}-${jobID}`,
          description: question
        },
        {
          Question: question
        },
        {
          Prompt: "prompt.txt"
        },
        {
          Data: "table.csv",
          SQL: "generated_query.md",
        }
      );

      const payload = JSON.stringify({
        "model": this.model.alias,
        "config": config.makeJSON(),
        "jobID": jobID,
      })

      const {status, data} = await this.dispatch(CallLambda(this.session, "jobCreator", payload))

      if (status !== 200) {
        throw new Error("Failed creating job");
      }
      const payload2 = JSON.stringify({
        "model": this.model.alias,
        "jobID": jobID,
        "jobAlias": config.meta.alias,
        "jobDesc": config.meta.description,
        "modelID": this.model.modelID,
        "launchType": this.model.launchType
      })

      const response = await this.dispatch(CallLambda(this.session, "modelInitializer", payload2))

      if (response.status !== 200) {
        throw new Error("Failed initializing job");
      };

      const {status: loadStatus, data: loadedData} = await this.dispatch(CallLambda(this.session, "nliService", { action: "loadJob", jobID: jobID }));
      if (loadStatus !== 200) {
        throw new Error("Failed loading new job");
      }

      const job = loadedData.job;
      return {
          jobID: job.job_id,
          question: job.description,
          startTime: Date.parse(job.start_time),
          status: job.job_status,
          statusMessage: job.running_message || "",
          tags: job.tags ? Object.values(job.tags) : []
      };
    }

    async deleteJob(jobID: string): Promise<void> {
      const {status, data} = await this.dispatch(CallLambda(this.session, "jobDeleter", { jobID: jobID }));
      if (status !== 200) {
          throw new Error("Failed deleting job");
      }
    }

    download(jobID: string, name: string, extension: string) {
      this.dispatch(SaveObjectAction(this.session, this.model.alias, jobID, name, extension));
    }

    async addJobTags(jobID: string, tags: string[]) {
      const {status, data} = await this.dispatch(CallLambda(this.session, "nliService", { action: "addJobTags", jobID: jobID, tags: tags }));
      if (status !== 200) {
          throw new Error("Failed saving tags");
      }
    }

    async removeJobTags(jobID: string, tags: string[]) {
      const {status, data} = await this.dispatch(CallLambda(this.session, "nliService", { action: "deleteJobTags", jobID: jobID, tags: tags }));
      if (status !== 200) {
          throw new Error("Failed saving tags");
      }
    }

    async saveJobFeedback(jobID: string, feedback: JobFeedback, outputs: JobOutput) {
      const outputPaths: {[key: string]: string} = {};
      if (outputs.Response) {
        outputPaths["Response"] = outputs.Response.name + "." + outputs.Response.extension;
      }
      if (outputs.Data) {
        outputPaths["Data"] = outputs.Data.name + "." + outputs.Data.extension;
      }
      if (outputs.Error) {
        outputPaths["Error"] = outputs.Error.name + "." + outputs.Error.extension;
      }
      const {status, data} = await this.dispatch(CallLambda(this.session, "nliService", { action: "saveJobFeedback", jobID: jobID, rating: feedback.rating, comment: feedback.comment, outputs: outputPaths }));
      if (status !== 200) {
          throw new Error("Failed saving feedback");
      }
    }

    async deleteJobFeedback(jobID: string) {
      const {status, data} = await this.dispatch(CallLambda(this.session, "nliService", { action: "deleteJobFeedback", jobID: jobID }));
      if (status !== 200) {
          throw new Error(`Failed deleting feedback`);
      }
    }

    async loadJobFeedback(cursor: any, minResults: number): Promise<LoadJobFeedbackResult> {
      const {status, data} = await this.dispatch(CallLambda(this.session, "nliService", { action: "loadJobFeedback", modelID: this.model.modelID, cursor: cursor, minResults: minResults }));
      if (status !== 200) {
          throw new Error("Failed loading feedback");
      }
      return {
        cursor: data.cursor,
        items: data.items.map((item: any) => ({
          jobID: item.job_id,
          jobStartTime: item.job_start_time,
          feedbackTime: item.feedback_time,
          userID: item.user_id,
          question: item.question,
          rating: item.rating,
          comment: item.comment,
          outputs: item.outputs
        }))
      };
    }
}
