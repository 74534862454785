import { Card, Col, Form, Row, Select } from "antd";
import SecondaryHeading from "components/assets/text/SecondaryHeading";
import { InputFilesWrapper } from "./styles";
import { useAppSelector } from "redux/hooks";

const InputFilesDisabled = ({
  values
}: any) => {
  const user = useAppSelector(state => state.user)
  const modelInfo = user.models[user.modelIndex];
  const jobInfo = user.jobs[user.jobIndex];
  const inputs = modelInfo.inputs;

  return (
    <InputFilesWrapper>
      <Card className="mt-24 card-custom">
        <SecondaryHeading title={"Input Files were used in " + jobInfo.jobName}>Input Files</SecondaryHeading>

        {inputs.length > 0 && (
          <Form layout="vertical" autoComplete="off">
            <Row gutter={[64, 12]}>
              {inputs.map((input: any) => {
                return (
                  <Col span={8} key={input.label}>
                    <Form.Item
                      label={input.label}
                    >
                      <Select
                        placeholder="Select one"
                        className="disabledSelect"
                        value={values[input.label]}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                )
              })}
            </Row>
          </Form>
        )}
      </Card>
    </InputFilesWrapper>
  );
};

export default InputFilesDisabled;
