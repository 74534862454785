import { ModelInfoWrapper } from "./styles";
import { Card, Col, Row, Typography } from "antd";
import Heading from "components/assets/text/Heading";

const { Paragraph } = Typography;

const ModelInfoCard = ({
  title,
  subtitle,
  modelAlias,
  owner,
  created,
  description,
}: any) => {
  return (
    <ModelInfoWrapper>
      <Card>
        <Heading
          className="color-primary"
          title="Model Name"
        >{title}</Heading>

        <Paragraph
          className="card-text"
          title="Summarized Model Description"
        >{subtitle}</Paragraph>

        <Row>
          <Col md={4}>
            <Paragraph className="card-sub-heading">Model Alias</Paragraph>
          </Col>
          <Col md={16}>
            <Paragraph className="card-sub-text">{modelAlias}</Paragraph>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <Paragraph className="card-sub-heading">Owner</Paragraph>
          </Col>
          <Col md={16}>
            <Paragraph className="card-sub-text">{owner}</Paragraph>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <Paragraph className="card-sub-heading">Date Created</Paragraph>
          </Col>
          <Col md={16}>
            <Paragraph className="card-sub-text">{created}</Paragraph>
          </Col>
        </Row>

        <Row>
          <Col md={24}>
            <Paragraph className="card-sub-heading description">Full Description</Paragraph>
          </Col>
          <Col md={24}>
            <Paragraph className="card-sub-text description-text">{description}</Paragraph>
          </Col>
        </Row>
      </Card>
    </ModelInfoWrapper>
  );
};

export default ModelInfoCard;
