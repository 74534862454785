import Field from "./Field";
import NLIConfig from "./NLIConfig";
import QA from "./QA";

export default class Model {
    modelID: string;
    owner: string;
    name: string;
    alias: string;
    shortDesc: string;
    longDesc: string;
    created: string;
    qa: QA[];
    parameters: Field[];
    inputs: Field[];
    outputs: Field[];
    launchType: string;
    nliConfig?: NLIConfig

    constructor(modelID: string, owner: string, name: string, alias: string, shortDesc: string, longDesc: string, created: string, qa: QA[], parameters: Field[], inputs: Field[], outputs: Field[], launchType: string, nliConfig?: NLIConfig) {
        this.modelID = modelID;
        this.owner = owner;
        this.name = name;
        this.alias = alias;
        this.shortDesc = shortDesc;
        this.longDesc = longDesc;
        this.created = created;
        this.qa = qa;
        this.parameters = parameters;
        this.inputs = inputs;
        this.outputs = outputs;
        this.launchType = launchType;
        this.nliConfig = nliConfig;
    }
}