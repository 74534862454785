import { createGlobalStyle } from "styled-components";

import PoppinsWoff from "./Poppins-Regular.woff";
import PoppinsBoldWoff from "./Poppins-SemiBold.woff";

const Fonts = createGlobalStyle`
@font-face {
  font-family: 'PoppinsRegular';
  src: url(${PoppinsWoff}) format('woff');
}
@font-face {
  font-family: 'PoppinsBold';
  src: url(${PoppinsBoldWoff}) format('woff');
}

p {
    font-family: 'PoppinsRegular', sans-serif !important;
    margin: 0 !important;
  }

h1,h2,h3,h4,h5,h6 {
  font-family: 'PoppinsBold', sans-serif !important;
  margin: 0;
}
  a,table,input,label {
    font-family: 'PoppinsRegular', sans-serif !important;
  }

  body {
    margin: 0px !important;
    background-color: #F3F3F3;
    font-family: 'PoppinsRegular', sans-serif !important;

  }
 html {
    font-family: 'PoppinsRegular', sans-serif !important;

  }

`;
export default Fonts;
