import { PageViewModel } from "./ViewModels/PageViewModel";
import { NLIJobService } from "services/NLIJobService";
import { useEffect, useMemo } from "react";
import { PageView } from "./Views/PageView";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useLocation } from "react-router-dom";
import { useSession } from "components/wrappers/SessionProvider";
import { endSubmitting, requestCompleted } from "redux/slices/app";

export default function FeedbackPage() {

    const dispatch = useAppDispatch();
    const app = useAppSelector(state => state.app);
    const user = useAppSelector(state => state.user);
    const modelInfo = user.models[user.modelIndex];
    const pageLocation = useLocation().pathname;
    const session = useSession();
    const users = user.users;


    const nliJobService = useMemo(() => new NLIJobService(dispatch, session, modelInfo), []);
    const pageViewModel = useMemo(() => new PageViewModel(nliJobService, user.users), []);

    useEffect(() => {
        nliJobService.dispatch = dispatch;
        nliJobService.session = session;
        nliJobService.model = modelInfo;
    }, [dispatch, session, modelInfo]);

    useEffect(() => {
        dispatch(requestCompleted());
        dispatch(endSubmitting());
    }, []);

    return (
        <div className="negate-content-padding flex-1 flex-column">
            <PageView model={pageViewModel} />
        </div>
    );
}