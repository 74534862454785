import { useEffect, useRef } from "react";
import { PageViewModel } from "../ViewModels/PageViewModel";
import { useViewModel } from "mvvm/View";
import { MarkdownViewer } from "components/assets/utilities/MarkdownViewer";
import TextArea from "antd/es/input/TextArea";
import { Popover } from "antd";
import { ParentWrapper } from "styles/global";

export function PageView(props: { model: PageViewModel }) {
    const scrollContainer = useRef<HTMLDivElement>(null);
    const userPromptInput = useRef<HTMLTextAreaElement>(null);
    const model = useViewModel(props.model);

    useEffect(() => {
        if (scrollContainer.current && model.isBusy) {
            scrollContainer.current.scrollTop = scrollContainer.current.scrollHeight;
        }
    });

    const focusOnUserInput = () => {
        setTimeout(() => {
            userPromptInput.current?.focus();
        },50);
    }

    const handleSubmit = async () => {
        await model.submit(focusOnUserInput);
    }

    const handleUserPromptKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            handleSubmit();
        }
    }

    const handleNewChat = () => {
        model.newChat();
        focusOnUserInput();
    }


    return (
        <div className="flex-1 flex-column" style={{background: "white", padding: "1em"}}>
            <div className="flex-row">
                <div className="flex-1"><h2>Chat</h2></div>
                <div>
                    <Popover trigger="click" placement="bottomLeft" content={
                        <ParentWrapper>
                            <div style={{ width: "75vw", height: "75vh", padding: "1em"}} className="flex-column">
                                <div className="flex-row">
                                    <div className="flex-1"><b>Edit System Prompt</b></div>
                                    <div><button className="link-btn">X</button></div>
                                </div>
                                <TextArea 
                                    className="flex-1"
                                    style={{resize: "none", fontSize: "1rem"}}
                                    value={model.systemPrompt}
                                    onChange={e => model.systemPrompt = e.target.value}
                                    />
                            </div>
                        </ParentWrapper>} >
                        <button className="small-secondary-btn">Edit System Prompt</button>
                    </Popover>
                </div>
                <div style={{marginLeft: "0.5em"}}><button className="small-secondary-btn" onClick={handleNewChat}>New Conversation</button></div>
            </div>
            <div className="flex-1 scroll" ref={scrollContainer}>
                {
                    model.turns.map((turn, index) => (
                        <div key={index} className="text-content" style={{maxWidth: "90vw"}}>
                            <div style={{marginTop: "1em"}}><b>Request</b></div>
                            <div style={{marginLeft: "1em", marginTop: "0.5em"}}>{turn.user}</div>
                            <div style={{marginTop: "1em"}}><b>Response</b></div>
                            <div style={{marginLeft: "1em", marginTop: "0.5em"}}><MarkdownViewer markdown={turn.assistant} /></div>
                        </div>
                    ))
                }
            </div>
            <div style={{marginTop: "1em"}}>
                <div>
                    <TextArea rows={3} 
                        ref={userPromptInput}
                        className="text-content"
                        value={model.userPrompt} 
                        style={{resize: "none", fontSize: "1rem"}}
                        placeholder="Ask me anything..."
                        onKeyDown={handleUserPromptKeyDown}
                        onChange={e => model.userPrompt = e.target.value}
                        disabled={model.isBusy} />
                </div>
                <div className="flex-row" style={{marginTop: "0.5em"}}>
                    <div className="flex-1" style={{ color: "#999"}}>Enter to submit, Shift+Enter for new line</div>
                    <div><button className="small-primary-btn" disabled={!model.canSubmit} onClick={handleSubmit}>Submit</button></div>
                </div>
            </div>
        </div>
    )
}
