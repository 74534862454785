import { TableProps } from "antd";
import { ViewModel } from "mvvm/ViewModel";
import { JobFeedbackItem, NLIJobService } from "services/NLIJobService";
import { JobOutputViewModel } from "./JobOutputViewModel";

export type FeedbackItemData = Readonly<{
    key: string;
    rating: "up"|"down";
    userID: string;
    userName: string;
    dateTime: string;
    question: string;
    comment: string;
    output: JobOutputViewModel;
}>;

export class PageViewModel extends ViewModel {
    constructor(public readonly jobService: NLIJobService, public readonly users: ReadonlyArray<{ userID: string, name: string }> ) {
        super();
        for (const user of users) {
            this._userMap.set(user.userID, user.name);
        }
        this.loadPageItems();
    }

    private readonly _userMap = new Map<string, string>();

    private _loadCursor: any = undefined;
    private _loadedItems: FeedbackItemData[] = [];
    private _filteredItems: FeedbackItemData[] = [];
    private _pageItems: FeedbackItemData[] = [];
    private _loadCompleted = false;
    private _loadError: string|undefined = undefined;
    private _pageSize = 50;
    private _pageNumber = 1;
    private _ratingFilter: "up"|"down"|undefined = undefined;
    private _userIDFilter: string|undefined = undefined;
    private _loading = false;

    getUserName(userID: string) {
        return this._userMap.get(userID) || userID;
    }

    async loadPageItems() {
        this._loading = true;
        this._loadError = undefined;
        this.updateViews();
        try {
            const maxFilteredItems = this._pageSize * this._pageNumber + 1;
            while (!this._loadCompleted && this._filteredItems.length < maxFilteredItems) {
                const loadResult = await this.jobService.loadJobFeedback(this._loadCursor, this._pageSize);
                this._loadCursor = loadResult.cursor;
                if (!this._loadCursor) {
                    this._loadCompleted = true;
                }
                if (loadResult.items.length > 0) {
                    const newFeedbackItems = this.createFeedbackItemData(loadResult.items);
                    this._loadedItems = [...this._loadedItems, ...newFeedbackItems];
                    this.filterLoadedItems();
                }
            }
        } catch (e) {
            this._loadError = this.formatError(e);
        }
        const pageStartIndex = this._pageSize * (this._pageNumber - 1);
        const pageEndIndex = pageStartIndex + this._pageSize;
        this._pageItems = this._filteredItems.slice(pageStartIndex, pageEndIndex);
        this._loading = false;
        this.updateViews();
    }

    private async loadAllItems() {
        this._loading = true;
        this._loadError = undefined;
        this.updateViews();
        try {
            while (!this._loadCompleted) {
                const loadResult = await this.jobService.loadJobFeedback(this._loadCursor, this._pageSize);
                this._loadCursor = loadResult.cursor;
                if (!this._loadCursor) {
                    this._loadCompleted = true;
                }
                const newFeedbackItems = this.createFeedbackItemData(loadResult.items);
                this._loadedItems = [...this._loadedItems, ...newFeedbackItems];
            }
        } catch (e) {
            this._loadError = this.formatError(e);
        }
        this.filterLoadedItems();
        this._loading = false;
        this.updateViews();
    }

    async downloadAllFilteredItems() {
        await this.loadAllItems();
        const nameTokens = ["NLI_Feedback", this.jobService.model.alias];
        if (this._ratingFilter) {
            nameTokens.push(this._ratingFilter);
        }
        if (this._userIDFilter) {
            nameTokens.push(this.getUserName(this._userIDFilter).replaceAll(" ", "_"));
        }
        const now = new Date();
        nameTokens.push(`${now.getFullYear()}${(now.getMonth()+1).toString().padStart(2, "0")}${now.getDate().toString().padStart(2, "0")}`);
        const csv = this.createCsv(this._filteredItems);
        const blob = new Blob([csv], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        try {
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = `${nameTokens.join("_")}.csv`;
            anchor.click();
        } finally {
            URL.revokeObjectURL(url);
        }

    }

    private createCsv(items: ReadonlyArray<FeedbackItemData>) {
        const lines = items.map(item => {
            return [
                item.key,
                item.rating,
                item.userName,
                item.dateTime,
                item.question,
                item.comment,
                item.output.outputPaths.Response ? "TRUE" : "FALSE",
                item.output.outputPaths.Data ? "TRUE" : "FALSE",
                item.output.outputPaths.Error ? "TRUE" : "FALSE"
            ].map(cell => {
                cell = cell.replaceAll("\n", " ");
                if (cell.includes(",")) {
                    return `"${cell.replaceAll("\"", "\"\"")}"`;
                } else {
                    return cell;
                }
            }).join(",");
        });
        const csv = [
            "Job ID,Rating,User,Date/Time,Request,User Feedback,Has Response,Has Data,Has Error",
            ...lines
        ].join("\n");
        return csv;
    }

    private createFeedbackItemData(items: ReadonlyArray<JobFeedbackItem>): FeedbackItemData[] {
        return items.map(item =>({
            key: item.jobID,
            rating: item.rating,
            userID: item.userID,
            userName: this.getUserName(item.userID),
            dateTime: new Date(item.jobStartTime).toLocaleString(),
            question: item.question,
            comment: item.comment,
            output: new JobOutputViewModel(this, item.jobID, item.outputs)
        }));
    }

    private filterLoadedItems() {
        this._filteredItems = this._loadedItems.filter(item => {
            return (!this._ratingFilter || item.rating === this._ratingFilter) &&
                (!this._userIDFilter || this._userIDFilter === item.userID);
        });
    }

    get isLastPage() {
        return this._filteredItems.length <= this._pageSize * this._pageNumber;
    }

    get isFirstPage() {
        return this._pageNumber === 1;
    }

    get pageNumber() {
        return this._pageNumber;
    }

    get loading() {
        return this._loading;
    }

    get pageItems() {
        return this._pageItems;
    }

    get loadError() {
        return this._loadError;
    }

    get hasFilteredItems() {
        return this._filteredItems.length > 0;
    }

    nextPage() {
        if (!this.isLastPage) {
            this._pageNumber++;
            this.loadPageItems();
        }
    }

    previousPage() {
        if (!this.isFirstPage) {
            this._pageNumber--;
            this.loadPageItems();
        }
    }

    firstPage() {
        if (!this.isFirstPage) {
            this._pageNumber = 1;
            this.loadPageItems();
        }
    }

    get ratingFilter() {
        return this._ratingFilter;
    }

    set ratingFilter(value: "up"|"down"|undefined) {
        this._ratingFilter = value;
        this._pageNumber = 1;
        this.filterLoadedItems();
        this.loadPageItems();
    }

    get ratingFilterDisplay() {
        switch (this._ratingFilter) {
            case "up":
                return "Up";
            case "down":
                return "Down";
            default:
                return "All";
        }
    }

    get userIDFilter() {
        return this._userIDFilter;
    }

    set userIDFilter(value: string|undefined) {
        this._userIDFilter = value;
        this._pageNumber = 1;
        this.filterLoadedItems();
        this.loadPageItems();
    }

    get userFilterDisplay() {
        return this._userIDFilter ? this.getUserName(this._userIDFilter) : "All";
    }
}