import { Col, Row } from "antd";
import { ModelJobsColumns } from "components/pages/Model/columns";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import ModelInfoCard from "components/panels/Model/ModelInfo";
import ButtonVelocity from "components/assets/utilities/ButtonVelocity";
import TableVelocity from "components/assets/utilities/TableVelocity";
import { ModelPageWrapper } from "./styles";
import Job from "redux/classes/Job";
import Model from "redux/classes/Model";
import { setConfig, setFiles, setForms, setJobIndex, setOutput, setQA } from "redux/slices/user";
import { RefreshAction } from "redux/actions/Refresh";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { LoadFlowAction } from "redux/actions/LoadFlow";
import { requestCompleted, requestStart } from "redux/slices/app";
import { useSession } from "components/wrappers/SessionProvider";

const ModelPage = () => {
  const dispatch = useAppDispatch();
  const app = useAppSelector((state) => state.app);
  const user = useAppSelector(state => state.user);
  const session = useSession();

  const modelInfo = user.models[user.modelIndex];

  useEffect(() => {
    dispatch(setConfig({ reset: true }));
    dispatch(setQA({ qa: [] }));
    dispatch(setOutput({ output: [] }));
    dispatch(setForms({ forms: [] }));
    dispatch(setFiles({ files: [] }));
    dispatch(requestCompleted())
  }, [])

  const prepareJobs = (jobs: Job[], models: Model[]) => {
    return jobs.filter(job => {
      return job.getModelName(models) === modelInfo.name
    }).map(job => {
      return {
        key: job.jobID,
        alias: modelInfo.alias,
        jobAlias: job.alias,
        runBy: job.getUserName(user.users),
        job: job.jobName,
        jobID: job.jobID,
        jobTime: job.jobName.substring(0, 10) + '_' + job.jobName.substring(11, 16),
        result: job.status !== 'RUNNING' ? job.resultName : "...",
        resultTime: job.resultName ?
          job.resultName.substring(0, 10) + '_' + job.resultName.substring(11, 16) :
          "",
        status: job.status
      }
    }).sort((a, b) => {
      return a.jobTime.toLowerCase() < b.jobTime.toLowerCase() ? 1 : -1
    })
  }

  const selectJob = (jobID: string) => {
    dispatch(requestStart())
    dispatch(setJobIndex({ jobID: jobID }))
  }

  return (
    <ModelPageWrapper>
      <Row justify={"center"}>
        <Col md={16}>
          <ModelInfoCard
            title={modelInfo.name}
            subtitle={modelInfo.shortDesc}
            modelAlias={modelInfo.alias}
            owner={modelInfo.owner}
            created={modelInfo.created}
            description={modelInfo.longDesc}
          />
        </Col>
      </Row>
      <Row justify={"center"}>
        <Col md={24}>
          <div className="model-button">
            <ButtonVelocity
              type="secondary"
              title={"View Model Flow for " + modelInfo.name}
              path={`/${modelInfo.alias}/flow`}
              onClick={async () => {
                dispatch(requestStart())
                await dispatch(LoadFlowAction(session, modelInfo.alias));
                dispatch(requestCompleted())
              }}
            >
              Model Flow
            </ButtonVelocity>
            <ButtonVelocity
              type="secondary"
              title={"View QA Checks that get applied to every job for " + modelInfo.name}
              path={`/${modelInfo.alias}/qa`}
            >
              QA Checks
            </ButtonVelocity>
            { !!modelInfo.nliConfig &&
              <ButtonVelocity
                type="secondary"
                title={"View model feedback for " + modelInfo.name}
                path={`/${modelInfo.alias}/feedback`}
              >
                Feedback
              </ButtonVelocity>
            }
            <ButtonVelocity
              type="secondary"
              title={"Create a new job for " + modelInfo.name}
              path={`/${modelInfo.alias}/run`}
              onClick={() => dispatch(requestStart())}
            >
              Run Model
            </ButtonVelocity>
          </div>
        </Col>
      </Row>
      <Row justify={"center"}>
        <Col md={20}>
          <TableVelocity
            title="Jobs"
            description="List of all jobs that have been run for this service"
            data={prepareJobs(user.jobs, user.models)}
            columns={ModelJobsColumns(selectJob, user.jobs, user.users, modelInfo.modelID)}
            vh="400px"
            refreshHandler={async () => {
              dispatch(requestStart())
              await dispatch(RefreshAction(session))
              dispatch(requestCompleted())
            }}
            path={useLocation().pathname}
            loading={app.loading}
          />
        </Col>
      </Row>
    </ModelPageWrapper>
  );
};

export default ModelPage;
