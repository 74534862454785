import JobStatus from "components/icons/JobStatus";
import { Link } from "react-router-dom";
import Job from "redux/classes/Job";
import User from "redux/classes/User";

let modelNames: string[] = []

export const ModelJobsColumns = (
  jobSelector: (jobID: string) => void,
  jobs: Job[],
  users: User[],
  modelID: string
) => [
  {
    title: <span title='Job Alias (32 characters max)'>Alias</span>,
    dataIndex: "jobAlias",
    key: "jobAlias",
    width: 160,
  },
  {
    title: <span title='User who ran the job'>Run By</span>,
    dataIndex: "runBy",
    key: "runBy",
    width: 120,
    filters: jobs.filter(job => {
      modelNames = []
      return job.modelID === modelID
    }).filter(job => {
      const newModel = !modelNames.includes(job.getUserName(users)!)
      modelNames.push(job.getUserName(users)!)

      return newModel
    }).map(job => {
      return {
        text: job.getUserName(users)!,
        value: job.getUserName(users)!
      }
    }),
    onFilter: (value: string, record: any) => record.runBy === value
  },
  {
    title: <span title='Job ID (links to Job Page)'>Job</span>,
    dataIndex: "job",
    key: "job",
    render: (text: any, record: any) => {
      return (
        <Link
          to={`/${record.alias}/job/${record.jobID}`}
          onClick={() => { jobSelector(record.jobID) }}
        >
          <span title={"Links to Job Page for " + record.job}>{text}</span>
        </Link>
      )
    },
    width: 200,
  },
  {
    title: <span title='Result ID (links to Results Page)'>Results</span>,
    dataIndex: "result",
    key: "result",
    render: (text: any, record: any) => {
      if (text === "...") {
        return text
      } else {
        return (
          <Link
            to={`/${record.alias}/results/${record.jobID}`}
            onClick={() => { jobSelector(record.jobID) }}
          >
            <span title={"Links to Results Page for " + record.result}>{text}</span>
          </Link>
        )
      }
    },
    width: 200,
  },
  {
    title: <span title='Job Status [RUNNING, REVIEW, PUBLISHED, FAILED]'>Status</span>,
    key: "status",
    dataIndex: "status",
    render: (status: any) => <JobStatus content={status} type="job"/>,
    width: 130,
    align: "center",
    filters: [
      {
        text: "Running",
        value: "RUNNING",
      },
      {
        text: "Review",
        value: "REVIEW",
      },
      {
        text: "Published",
        value: "PUBLISHED",
      },
      {
        text: "Failed",
        value: "FAILED",
      },
    ],
    onFilter: (value: string, record: any) => record.status.indexOf(value) === 0
  },
];