import styled from "styled-components";

export const SubHeadinghWrapper = styled.div`
  h3 {
    font-size: 32px;
    font-weight: 600;
    line-height: 35.2px;
    white-space: pre;
  }
`;
