import { AppDispatch } from "redux/store";
import { CallLambda } from "./CallLambda";
import Session from "redux/classes/Session";

export function UploadInputAction(session: Session, modelAlias: string, file: File, s3Subfolder: string) {
    return async (dispatch: AppDispatch) => {
        const payload = JSON.stringify({
            "model": modelAlias,
            "file": s3Subfolder + file.name,
            "size": file.size.toString(),
            "type": file.type
        });

        let response: {status: number, data: any} = await dispatch(CallLambda(session, "fileUploader", payload));

        if (response.status !== 200) {
          throw new Error("Failed to upload file");
        }

        const presignedUrl = response.data.presignedURL;
        const uploadResponse = await fetch(presignedUrl, {
            method: 'PUT',
            body: file,
            headers: {
                'Content-Type': file.type
            }
        });

        if (!uploadResponse.ok) {
            throw new Error("Failed to upload file");
        }
    }
}