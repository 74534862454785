import Session from "redux/classes/Session";
import { AppDispatch } from "redux/store";

export type ChatRequest = {
    system?: string;
    previousTurns?: Array<{
        user: string;
        assistant: string;
    }>,
    user?: string;
}

export class ChatService {

    constructor(
      public dispatch: AppDispatch,
      public session: Session,
      public chatUrl: string) {

    }

    async invoke(request: ChatRequest, callback: (chunk: string) => void) {
        try {
            const response = await fetch(this.chatUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.session.accessToken}`
                },
                body: JSON.stringify(request)
            });
            if (response.body) {
                const reader = response.body.getReader();
                const decoder = new TextDecoder();
                while (true) {
                    const { value, done } = await reader.read();
                    if (value) {
                        const chunk = decoder.decode(value);
                        if (chunk) {
                            callback(chunk);
                        }
                    }
                    if (done) {
                        return;
                    }
                }
            }
        } catch (err) {
            console.error(err);
            const message = err instanceof Error ? err.message : String(err);
            callback(`\nERROR: ${message}`);
        }
    }

}
