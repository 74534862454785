import styled from "styled-components";

export const StartupWrapper = styled.div`
  height: 100vh;
  background: #f3f3f3;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-spin-dot-item {
    background-color: #d91e49 !important;
  }
`;
export const StartupCard = styled.div`
  flex-basis: 538px;
  background: #ffffff;
  border-radius: 8px;
  padding: 24px;
  @media (max-width: 768px) {
    padding: 100px 20px;
  }
`;