import RunPage from "components/pages/Run";
import RunNLIPage from "components/pages/RunNLI";
import { useAppSelector } from "redux/hooks";

export default function RunPageSelector() {
    const user = useAppSelector(state => state.user);
    const modelInfo = user.models[user.modelIndex];

    return modelInfo.nliConfig ? <RunNLIPage /> : <RunPage />;
  
}