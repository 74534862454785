export abstract class ViewModel {

    private readonly _viewCallbacks = new Set<() => void>();

    addView(callback: () => void) {
        this._viewCallbacks.add(callback);
    }

    removeView(callback: () => void) {
        this._viewCallbacks.delete(callback);
    }

    updateViews() {
        const callbacks = this._viewCallbacks;
        if (callbacks && callbacks.size) {
            callbacks.forEach((callback: ()=>void) => callback());
        }
    }

    formatError(error: any) {
        return error.message || String(error) || "An unknown error occurred";
    }

}
