import styled from "styled-components";

export const HomeWrapper = styled.div`
  .ant-card {
    margin-top: 8px;
    border-radius: 8px;
  }
  .stats-container,
  .table-container {
    height: 425px;
  }

  @media (max-width: 1024px) {
  }

  .tall {
    height: 600px;
  }

  .ant-table-filter-trigger {
    title: 3;
  }
`;
