import { PageViewModel } from "./ViewModels/PageViewModel";
import { useEffect, useMemo } from "react";
import { PageView } from "./Views/PageView";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useSession } from "components/wrappers/SessionProvider";
import { endSubmitting, requestCompleted } from "redux/slices/app";
import { ChatService } from "./Services/ChatService";

export default function ChatPage() {

    const dispatch = useAppDispatch();
    const user = useAppSelector(state => state.user);
    const chatUrl = user.chatUrl;
    const session = useSession();

    const chatService = useMemo(() => new ChatService(dispatch, session, chatUrl), []);
    const pageViewModel = useMemo(() => new PageViewModel(chatService), []);

    useEffect(() => {
        chatService.dispatch = dispatch;
        chatService.session = session;
        chatService.chatUrl = chatUrl;
    }, [dispatch, session, chatUrl]);

    useEffect(() => {
        dispatch(requestCompleted());
        dispatch(endSubmitting());
    }, []);

    return (
        <div className="negate-content-padding flex-1 flex-column">
            <PageView model={pageViewModel} />
        </div>
    );
}