import { Col, Form, Input, Row, Select } from "antd";
import Card from "antd/es/card/Card";
import { InfoPanelWrapper } from "./styles";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { modifyConfig, setJobPanelsComplete, updateJobPanelsComplete } from "redux/slices/user";
import { useEffect, useState } from "react";
import SubHeading from "components/assets/text/SubHeading";
import Subtitle from "components/assets/text/Subtitle";

const InfoPanel = () => {
  const dispatch = useAppDispatch();
  const app = useAppSelector(state => state.app)
  const user = useAppSelector(state => state.user)
  const modelInfo = user.models[user.modelIndex];

  const { TextArea } = Input;

  const [aliasForm] = Form.useForm();
  const [descriptionForm] = Form.useForm();
  const forms = [aliasForm, descriptionForm];

  useEffect(() => {
    if (user.config) {
      dispatch(modifyConfig({
        type: "meta",
        value: {
          'alias': modelInfo.alias + '-' + new Date().toISOString(),
          'description': ''
        }
      }))
    }
  }, [user.config])

  useEffect(() => {
    if (app.submitting) {
      let promises: any[] = []

      for (let form of forms) {
        promises.push(form.validateFields().catch(() => {
          dispatch(setJobPanelsComplete({ jobsPanelsComplete: undefined }))
        }))
      }

      Promise.all(promises).then(() => {
        dispatch(updateJobPanelsComplete({ panel: 'meta', complete: true }))
      });
    }
  }, [app.submitting])

  return (
    <InfoPanelWrapper>
      <Card>
        <SubHeading
          className="down"
          title="Model Name"
        >{modelInfo.name}</SubHeading>

        <Row gutter={20} className="mt-24">
          <Col md={10} className="input-container">
            <Form
              form={aliasForm}
              layout="vertical"
              autoComplete="off"
              requiredMark={false}
            >
              <Form.Item
                name="alias"
                label="Job Alias"
                className="centered"
                rules={[
                  {
                    min: 0,
                    max: 64,
                    message: 'Job Alias must be 64 characters or less',
                  },
                  {
                    pattern: /^[a-zA-Z0-9 ._-]*$/,
                    message: 'Job Alias can only include a-Z, 0-9, periods, underscores, and dashes',
                  }
                ]}
              >
                <Input
                  name="info"
                  placeholder="Short Description"
                  className="custom-input h-56"
                  type={"text"}
                  onChange={(e) => {
                    let meta = user.config.meta
                    if (e.target.value === '') {
                      meta['alias'] = modelInfo.alias + '-' + new Date().toISOString()
                    }
                    else {
                      meta['alias'] = e.target.value
                    }
                    dispatch(modifyConfig({
                      type: "meta",
                      value: meta
                    }))
                  }}
                  disabled={app.submitting}
                />
              </Form.Item>
            </Form>
          </Col>

          <Col md={1} className="input-container">
          </Col>

          <Col md={13} className="input-container">
            <Form
              form={descriptionForm}
              layout="vertical"
              autoComplete="off"
              requiredMark={false}
            >
              <Form.Item
                name="description"
                label="Job Description"
                className="shifted-up"
                rules={[
                  {
                    min: 0,
                    max: 1000,
                    message: 'Job Description must be between 1000 characters or less',
                  },
                  {
                    pattern: /^[a-zA-Z0-9 .,\t\n\-_]*$/,
                    message: 'Job Description can only include letters, numbers, periods, commas, spaces, tabs, line breaks, dashes, and underscores',
                  }
                ]}
              >
                <TextArea
                  name="info"
                  rows={4}
                  placeholder="Full Description"
                  className="custom-input"
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    let meta = user.config.meta
                    meta['description'] = e.target.value
                    dispatch(modifyConfig({
                      type: "meta",
                      value: meta
                    }))
                  }}
                  disabled={app.submitting}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Card>
    </InfoPanelWrapper>
  );
};

export default InfoPanel;
