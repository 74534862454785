import { Button, Row, Col } from "antd";
import { StartupCard, StartupWrapper } from "./styles";
import { useAppSelector } from "redux/hooks";
import VELOCITY from "components/icons/VELOCITY";
import { useAuth } from "react-oidc-context";

const Startup = ({ message, showReload }: { message: string, showReload: boolean }) => {
  const app = useAppSelector(state => state.app);

  const reload = () => {
    sessionStorage.removeItem("velocity:customerConfig");
    window.location.reload();
  }

  return (
    <StartupWrapper>
      <StartupCard>
        <Row>
          <Col span={24}>
            <div style={{ textAlign: "center" }}>
              <VELOCITY scale={1.8}/>
            </div>
          </Col>
        </Row>
        <div style={{ textAlign: "center", color: "#999" }}>{message}</div>
        {showReload &&
          <div style={{ textAlign: "center", marginTop: "0.5em" }}>
            <Button type="primary" title="Reload the current page" htmlType="submit" onClick={reload}>Reload</Button>
          </div>
        }
      </StartupCard>
    </StartupWrapper>
  );
};

export default Startup;
