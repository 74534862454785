export default class Field {
    fieldID: string;
    label: string;
    dataType: string;
    fieldType: string;
    options: any[];
    s3_subfolder: string;
    defaultValue: any;

    constructor(fieldID: string, label: string, dataType: string, fieldType: string, options: any[], s3_subfolder: string, defaultValue: any) {
        this.fieldID = fieldID;
        this.label = label;
        this.dataType = dataType;
        this.fieldType = fieldType;
        this.options = options;
        this.s3_subfolder = s3_subfolder;
        this.defaultValue = defaultValue;
    }
}