import { Col, Row } from "antd";
import TableVelocity from "components/assets/utilities/TableVelocity";
import { QAAlertsColumns } from "components/pages/Result/columns";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useEffect, useRef } from "react";
import { LoadJobAction } from "redux/actions/LoadJob";
import { LoadResultAction } from "redux/actions/LoadResult";
import { SaveObjectAction } from "redux/actions/SaveObject";
import QA from "redux/classes/QA";
import Output from "redux/classes/Output";
import Visualizations from "components/assets/utilities/Visualizations";
import Interactives from "components/assets/utilities/Interactives";
import ExcelVisuals from "components/assets/utilities/ExcelVisuals";
import { ResultWrapper } from "./styles";
import { setOutputIndex, setVisualIndex, setHtmlIndex } from "redux/slices/user";
import { requestCompleted, requestFailure } from "redux/slices/app";
import { useSession } from "components/wrappers/SessionProvider";
import OtherVisuals from "components/assets/utilities/OtherVisuals";

const ResultPage = () => {
  const dispatch = useAppDispatch();
  const app = useAppSelector(state => state.app);
  const user = useAppSelector(state => state.user);
  const loadJob = useRef(false);
  const session = useSession();

  const modelInfo = user.models[user.modelIndex];
  const jobInfo = user.jobs[user.jobIndex]

  const visuals = user.output.filter(output => output.type === 'png')
  const visual = visuals[user.visualIndex]
  const htmlFiles = user.output.filter(output => output.type === 'html')
  const excelFiles = user.output.filter(output => output.type === 'xlsx')
  const outputs = user.output.filter(output => output.type !== 'png' && output.type !== 'html' && output.type !== 'xlsx' && output.type !== 'err')
  const output = outputs[user.outputIndex]
  const error = user.output.filter(output => output.type === 'err')

  useEffect(() => {
    dispatch(setOutputIndex({ index: 0 }))
    dispatch(setVisualIndex({ index: 0 }))
    dispatch(setHtmlIndex({ index: 0 }))
    dispatch(LoadJobAction(session, jobInfo.jobID, modelInfo.alias))
    loadJob.current = true;
  }, [])

  useEffect(() => {
    if (loadJob.current) {
      const getOutput = async () => {
        await dispatch(LoadResultAction(session, jobInfo.jobID, modelInfo.alias, user.config.outputs, user.jobs[user.jobIndex].status))

        dispatch(requestCompleted());
      }
      getOutput().catch(() => dispatch(requestFailure()));
    }
  }, [user.config])

  const prepareQA = (checks: QA[]) => {
    return checks.map((check, index) => {
      return {
        key: "qa" + index,
        type: check.type,
        name: check.name,
        description: check.description,
        threshold: check.fail_threshold,
        actual: check.actual,
        status: check.status
      }
    })
  }

  const prepareColumns = (output: Output) => {
    if (output) {
      switch (output.type) {
        case "err":
          return [{
            key: "column0",
            title: output.path,
            dataIndex: output.path,
            render: (text: string) => <>
              {text.split('\n').map((paragraph: string, index: number) => {
                return <p key={'p' + index}>{paragraph.replace("  ", "\u00A0\u00A0")}</p>
              })}
            </>
          }]
      }
    }
  }

  const prepareResult = (output: Output) => {
    if (output) {
      switch (output.type) {
        case "err":
          let result: {[key: string]: string} = {
            key: "column0"
          }
          result[output.path] = atob(output.result).trim()
          return [result]
      }
    }
  }

  const downloadXLSX = (url: string) => {
    const a = document.createElement('a');
    a.href = url;
    a.download = url.split('/').pop()!;
    a.click();
  }

  return (
    <ResultWrapper>
      {app.loading ? <div className="loader-centered"/> :
        <>
          {user.output.filter(output => output.type === 'png').length > 0 &&
            <Row gutter={20} className="mt-24">
              <Col md={24}>
                <Visualizations
                  visuals={visuals}
                  description="Visualizations generated by the model"
                  result
                  download={() => dispatch(SaveObjectAction(session, modelInfo.alias, jobInfo.jobID, visual.path.split('.')[0], visual.type))}
                  extension={visual.type}
                />
              </Col>
            </Row>
          }

          {user.output.filter(output => output.type === 'html').length > 0 &&
            <Row gutter={20} className="mt-24">
              <Col md={24}>
                <Interactives
                  visuals={htmlFiles}
                  description="Interactive visualizations generated by the model"
                />
              </Col>
            </Row>
          }

          {user.output.filter(output => output.type === 'xlsx').length > 0 &&
            <Row gutter={20} className="mt-24">
              <Col md={24}>
                <ExcelVisuals
                  visuals={excelFiles}
                  description="Excel sheets generated by the model"
                  download={downloadXLSX}
                  extension={excelFiles[0].type}
                />
              </Col>
            </Row>
          }

          {user.output.filter(output => output.type !== 'png' && output.type !== 'html' && output.type !== 'xlsx' && output.type !== 'err').length > 0 &&
            <Row gutter={20} className="mt-24">
              <Col md={24}>
                <OtherVisuals
                  visuals={outputs}
                  description="Results generated by the model"
                  session={session}
                  alias={modelInfo.alias}
                  jobID={jobInfo.jobID}
                />
              </Col>
            </Row>
          }

          {user.output.filter(output => output.type === 'err').length > 0 &&
            <Row gutter={20} className="mt-24">
              <Col md={24}>
                <TableVelocity
                  type="Error"
                  description="Result files generated by the model"
                  data={prepareResult(error[0])}
                  columns={prepareColumns(error[0])}
                  vh={"600px"}
                  scroll={{ x: "max-content" }}
                  loading={app.loading}
                  options={error}
                  showHeader={false}
                />
              </Col>
            </Row>
          }

          {user.qa.length > 0 &&
            <Row gutter={20} className="mt-24">
              <Col md={24}>
                <TableVelocity
                  title="QA Alerts"
                  description="QA report generated by the model"
                  data={prepareQA(user.qa)}
                  columns={QAAlertsColumns}
                  vh={"400px"}
                  loading={app.loading}
                />
              </Col>
            </Row>
          }
        </>
      }
    </ResultWrapper>
  );
};

export default ResultPage;
