import Login from "components/pages/Login";
import Startup from "components/pages/Startup";
import { mode } from "d3";
import React from "react";
import { useEffect, useRef, useState } from "react";
import { AuthProvider, useAuth } from "react-oidc-context";
import { InitializeSession } from "redux/actions/Authorize";
import Session from "redux/classes/Session";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setJobIndex, setModelIndex } from "redux/slices/user";
import store from "redux/store";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || "/api";

interface CustomerConfig {
    cust_id: string;
    name: string;
    idp_uri: string;
    oidc_metadata_uri: string;
    auth_client_id: string;
    auth_request_scopes?: string[];
    auth_request_params?: { [key: string]: string; };
}
  
type CustomerConfigState = { status: "initializing" }
| { status: "loading", cust_id?: string}
| { status: "loaded", config: CustomerConfig }
| { status: "error", cust_id?: string, error: string };

export default function SessionProvider({ children }: { children: JSX.Element }) {
    const initializedRef = useRef(false);

    const [customerConfigState, setCustomerConfigState] = useState<CustomerConfigState>({ status: "initializing" });

    useEffect(() => {
      if (initializedRef.current) {
        return;
      }
      initializedRef.current = true;
      initialize();
    });
  
    const loadCustomerConfig = async (custId?: string) => {
  
      const url = `${apiBaseUrl}/customerLoader${custId?`?cust_id=${custId}`: ""}`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Error loading customer configuration (HTTP status: ${response.status})`);
      }
      const config = await response.json();
      sessionStorage.setItem("velocity:customerConfig", JSON.stringify(config));
      setCustomerConfigState({ status: "loaded", config });
      return config;
    };
  
    const initialize = async () => {
      const defaultCustId = localStorage.getItem("velocity:default_cust_id") || undefined;
      setCustomerConfigState({ status: "loading", cust_id: defaultCustId});
      try {
        const storedCustomerConfig = sessionStorage.getItem("velocity:customerConfig");
        if (storedCustomerConfig) {
          const customerConfig = JSON.parse(storedCustomerConfig) as CustomerConfig;
          setCustomerConfigState({ status: "loaded", config: customerConfig });
          return;
        }
        await loadCustomerConfig(defaultCustId);
      } catch (error) {
        let errorMessage: string|undefined = undefined;;
        if (error instanceof Error) {
          errorMessage = error.message;
        } else if (typeof error === "string") {
          errorMessage = error;
          errorMessage = errorMessage || "An unexpected error occurred";
          setCustomerConfigState({ status: "error", cust_id: defaultCustId, error: errorMessage });
        }
      }
    };


    if (customerConfigState.status !== "loaded") {
        let message: string;
        let showReload  = false;
        switch (customerConfigState.status) {
            case "initializing":
            case "loading":
                message = "Loading...";
                break;
            case "error":
                message = `Error loading sign-in configuration: ${customerConfigState.error}`;
                showReload = true;
                break;
            default:
                message = "Unknown error loading sign-in configuration";
                showReload = true;
                break;
        }
        return <Startup message={message} showReload={showReload} />
    }

    const signinReturnUrl = sessionStorage.getItem("velocity:signinReturnUrl") || window.location.pathname;
  
    return (
        <AuthProvider
            authority={customerConfigState.config.idp_uri}
            metadataUrl={customerConfigState.config.oidc_metadata_uri}
            client_id={customerConfigState.config.auth_client_id}
            scope={`${customerConfigState.config.auth_request_scopes?.join(' ')} openid profile email`.trim()}
            extraQueryParams={customerConfigState.config.auth_request_params}
            refreshTokenAllowedScope={customerConfigState.config.auth_request_scopes?.join(' ').trim()}
            redirect_uri={window.location.origin}
            automaticSilentRenew={true}
            onSigninCallback={() => window.history.replaceState({}, "", signinReturnUrl)}>

            <AuthView>
                {children}
            </AuthView>

        </AuthProvider>
    );  
}

const SessionContext = React.createContext<Session|null>(null);

function AuthView({children}: { children: JSX.Element}) {
    const auth = useAuth();

    if (auth.isLoading || auth.error) {
        let message = auth.isLoading ? "Loading..." : `Sign-in Error: ${auth.error}`;
        return <Startup message={message} showReload={!auth.isLoading} />;
    }

    if (!auth.isAuthenticated) {
        return <Login />;
    }

    const user = auth.user!;

    const session: Session =new Session(apiBaseUrl, user.access_token, user.profile.name!, ()=>auth.removeUser());

    return <SessionContext.Provider value={session}>
      <SessionView>
        {children}
      </SessionView>
    </SessionContext.Provider>;
};

type SessionState = { status: "initializing" }
  | { status: "loading" }
  | { status: "loaded" }
  | { status: "error", message: string };


function SessionView({children}: { children: JSX.Element}) {
  
  const initializedRef = useRef(false);
  const session = useSession();
  const dispatch = useAppDispatch()

  const [sessionState, setSessionState] = useState<SessionState>({ status: "initializing"});

  const initialize = async () => {
    setSessionState({ status: "loading" });
    try {
      await InitializeSession(dispatch, session);
      let path = window.location.pathname;
      if (path.endsWith('/')) {
        path = path.substring(0, path.length - 1);
      }
      if (path.startsWith('/')) {
        path = path.substring(1);
      }
      const segments = path.split("/");
      if (segments.length > 0) {
        const modelAlias = segments[0];
        let user = store.getState().user;        
        user.models.filter(model => model.alias === modelAlias).forEach(model => {
          dispatch(setModelIndex({ modelName: model.name }))
        });
        if (segments.length > 2 && (segments[1] === "job" || segments[1] === "results")) {
          dispatch(setJobIndex({ jobID: segments[2] }));
        }
      }
      setSessionState({ status: "loaded" });
    } catch (error: any) {
      setSessionState({ status: "error", message: error.message || String(error) || "An unknown error occurred" });
    }
  };

  useEffect(() => {
    if (initializedRef.current) {
      return;
    }
    initializedRef.current = true;
    initialize();
  }, []);

  if (sessionState.status !== "loaded") {
    if (sessionState.status === "error") {
      return <Startup message={`Initialization failed: ${sessionState.message}`} showReload={true} />;
    }
    return <Startup message="Initializing..." showReload={false} />;
  }

  return children;
}

export const useSession = () => {
    const session = React.useContext(SessionContext);
    if (!session) {
        throw new Error('useSession must be used within a SessionProvider');
    }
    return session;
}