export default function InsightIcon(props) {
  return (
    <svg
      width="69"
      height="69"
      viewBox="0 0 69 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="69" height="69" rx="34.5" fill="#DDEFFD" />
      <path
        d="M35 45.75C29.07 45.75 24.25 40.93 24.25 35C24.25 29.07 29.07 24.25 35 24.25C40.93 24.25 45.75 29.07 45.75 35C45.75 40.93 40.93 45.75 35 45.75ZM35 25.75C29.9 25.75 25.75 29.9 25.75 35C25.75 40.1 29.9 44.25 35 44.25C40.1 44.25 44.25 40.1 44.25 35C44.25 29.9 40.1 25.75 35 25.75Z"
        fill="#3D9CF5"
      />
      <path
        d="M38.71 38.93C38.58 38.93 38.45 38.9 38.33 38.82L35.23 36.97C34.46 36.51 33.89 35.5 33.89 34.61V30.51C33.89 30.1 34.23 29.76 34.64 29.76C35.05 29.76 35.39 30.1 35.39 30.51V34.61C35.39 34.97 35.69 35.5 36 35.68L39.1 37.53C39.46 37.74 39.57 38.2 39.36 38.56C39.21 38.8 38.96 38.93 38.71 38.93Z"
        fill="#3D9CF5"
      />
    </svg>
  );
}
