import styled from "styled-components";

export const ParametersWrapper = styled.div`
  form {
    margin-top: 38px;
  }
  .ant-form-item-label label {
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    color: #1c1c1c;
  }
  .ant-select-selector {
    border-radius: 8px;
    border: 1px solid #eaeaea !important;
    background: #fafafa !important;
    height: 56px !important;
  }
  .ant-select-arrow {
    height: 100%
  }
  .multi {
    .ant-select-arrow {
      height: auto
    }
  }

  .brand-select .ant-select-selector .ant-select-selection-item,
  .ant-select-selector .ant-select-selection-placeholder {
    line-height: 53px !important;
  }
  .ant-select-selector .ant-select-selection-item,
  .ant-select-selector .ant-select-selection-placeholder {
    font-family: "PoppinsRegular", sans-serif !important;
  }
  .ant-picker {
    height: 48px;
    border: 1px solid #eaeaea;
    background: #fafafa;
  }

  .ant-form-item-explain {
    margin-top: 20px;
    margin-bottom: -20px;
  }

  .ant-form-item-has-feedback.ant-form-item-has-error .ant-select-selector {
    border-color: red !important;
  }

  .ant-form-item-has-feedback.ant-form-item-has-success .ant-select-selector .ant-select-selection-item-icon,
  .ant-form-item-has-feedback.ant-form-item-has-error .ant-select-selector .ant-select-selection-item-icon {
    display: none !important;
  }

  .ant-form-item-feedback-icon.ant-form-item-feedback-icon-error,
  .ant-form-item-feedback-icon.ant-form-item-feedback-icon-success {
    display: none !important;
  }

  .large {
    font-size: 18px;
  }

  .range {
    width: 100%;
    height: 54px;
  }

  .radio {
    margin-top: 7px;
  }

  .ant-picker-range {
    margin-bottom: -20px;
  }

  .ant-input {
    margin-bottom: -20px;
  }
`;
