import { AppDispatch } from "redux/store";
import { CallLambda } from "./CallLambda";
import { setFiles } from "redux/slices/user";
import Field from "redux/classes/Field";
import Session from "redux/classes/Session";

export function LoadFilesAction(session: Session, modelAlias: string, inputs: Field[]) {
    return async (dispatch: AppDispatch) => {
        const inputTuples = inputs.map(input => {
            if (input.dataType === "folder") {
                return [input.label + '/', input.s3_subfolder ? input.s3_subfolder : '']
            }
            return [input.label + input.dataType, input.s3_subfolder ? input.s3_subfolder : '']
        })

        const payload = JSON.stringify({
            "model": modelAlias,
            "inputs": inputTuples,
        });

        let response: {status: number, data: any} = await dispatch(CallLambda(session, "optionsLoader", payload))

        if (response.status === 200) {
            let options: {[key: string]: string[]} = {}

            let optionsJSON = response.data.options
            for (let input of inputTuples) {
                if (input[0].indexOf('.') !== input[0].lastIndexOf('.')) {
                    options[input[0].split(".")[0] + '.' + input[0].split(".")[1]] = optionsJSON[input[0]]
                }
                else {
                    options[input[0].split("/")[0]] = optionsJSON[input[0]]
                }
            }

            dispatch(setFiles({ files: options }))
        }
    }
}