import { Button } from "antd";
import { Link } from "react-router-dom";
import { ButtonWrapper } from "./styles";

interface ButtonVelocityProps {
  children: React.ReactNode;
  path: any;
  type: "primary" | "secondary" | "header" | "result" | "refresh" | "publish" | "published" | "delete" | "download" | "constraint" | "upload"; // Define the possible types
  disable?: boolean;
  onClick?: () => void;
  submitting?: boolean;
  title?: string;
}

const ButtonVelocity = ({
  children,
  type,
  path,
  disable,
  onClick,
  submitting,
  title,
}: ButtonVelocityProps) => {
  let buttonClass = "default";

  switch (type) {
    case "primary":
      buttonClass = "primary-btn";
      break;
    case "secondary":
      buttonClass = "secondary-btn";
      break;
    case "result":
      buttonClass = "result-btn";
      break;
    case "refresh":
      buttonClass = "refresh-btn";
      break;
    case "publish":
      buttonClass = "publish-btn";
      break;
    case "published":
      buttonClass = "published-btn";
      break;
    case "delete":
      buttonClass = "delete-btn";
      break;
    case "download":
      buttonClass = "download-btn";
      break;
    case "constraint":
      buttonClass = "constraint-btn";
      break;
    case "upload":
      buttonClass = "upload-btn";
      break;
  }

  return (
    <ButtonWrapper>
      <Link to={path}>
        <Button
          className={`btn ${buttonClass}`}
          disabled={disable || submitting || type === "published"}
          onClick={onClick}
          title={title}
        >
          {submitting ?
            <div className={"loader-button" + (type === "publish" ? "-small" : "")}/> :
            children
          }
        </Button>
      </Link>
    </ButtonWrapper>
  );
};

export default ButtonVelocity;
