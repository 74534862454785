import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "redux/store";
import { ParentWrapper } from "styles/global";
import App from "App";
import { ConfigProvider } from "antd";
import Fonts from "styles/fonts/fonts";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <ParentWrapper>
      <Fonts/>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#0C77DC",
          },
        }}
      >
        <App />
      </ConfigProvider>
    </ParentWrapper>
  </Provider>
);