import { AppDispatch } from "redux/store";
import { CallLambda } from "./CallLambda";
import Model from "redux/classes/Model";
import Config from "redux/classes/Config";
import Job from "redux/classes/Job";
import { setJobs } from "redux/slices/user";
import Session from "redux/classes/Session";

export function RunModelAction(session: Session, config: Config, model: Model) {
    return async (dispatch: AppDispatch) => {
        const jobID = await CreateJobAction(dispatch, session, config, model.alias)

        const payload = JSON.stringify({
            "model": model.alias,
            "jobID": jobID,
            "jobAlias": config.meta.alias,
            "jobDesc": config.meta.description,
            "modelID": model.modelID,
            "launchType": model.launchType
        })

        const response = await dispatch(CallLambda(session, "modelInitializer", payload))

        if (response.status === 200) {
            const jobs = response.data.jobs.map((job: any) => {
                return new Job(
                    job.job_id,
                    job.model_id,
                    job.cust_id,
                    job.user_id,
                    job.alias,
                    job.description,
                    job.job_name,
                    job.result_name,
                    job.task_id,
                    job.job_status,
                    new Date(job.start_time),
                    new Date(job.end_time)
                )
            })

            dispatch(setJobs({ jobs: jobs }));
        }
    }
}

const CreateJobAction = async (dispatch: AppDispatch, session: Session, config: Config, modelAlias: string) => {
    let date = new Date()
    let jobID = date.toISOString()

    const payload = JSON.stringify({
        "model": modelAlias,
        "config": config.makeJSON(),
        "jobID": jobID,
    })

    let response: {status: number, data: any} = await dispatch(CallLambda(session, "jobCreator", payload))

    if (response.status === 200) {
        return jobID
    }
}