import { createSlice } from "@reduxjs/toolkit";
import User from "redux/classes/User";
import Model from "redux/classes/Model";
import Job from "redux/classes/Job";
import Config from "redux/classes/Config";
import QA from "redux/classes/QA";
import Output from "redux/classes/Output";
import { FormInstance } from "antd";

interface initialStateTypes {
  userID: string;
  users: User[];
  models: Model[];
  modelIndex: number;
  jobs: Job[];
  chatUrl: string;
  jobIndex: number;
  config: Config;
  qa: QA[];
  output: Output[];
  outputIndex: number;
  visualIndex: number;
  htmlIndex: number;
  sheetIndex: number;
  files: {[key: string]: string[]};
  flow: string;
  forms: FormInstance[];
  jobPanelsComplete?: {[key: string]: boolean};
}

const initialState: initialStateTypes = {
  userID: "",
  users: [],
  models: [],
  modelIndex: -1,
  jobs: [],
  chatUrl: "",
  jobIndex: -1,
  config: new Config({}, {}, {}, {}),
  qa: [],
  output: [],
  outputIndex: 0,
  visualIndex: 0,
  htmlIndex: 0,
  sheetIndex: 0,
  files: {},
  flow: "",
  forms: [],
  jobPanelsComplete: undefined,
};

export const userReducer = createSlice({
  name: "user",
  initialState,
  reducers: {
    signOut: (state) => {
      state.userID = "";
      state.users = [];
      state.models = [];
      state.modelIndex = -1;
      state.jobs = [];
      state.chatUrl = "";
      state.jobIndex = -1;
      state.config = new Config({}, {}, {}, {});
      state.qa = [];
      state.output = [];
      state.outputIndex = 0;
      state.visualIndex = 0;
      state.htmlIndex = 0;
      state.sheetIndex = 0;
      state.files = {};
      state.flow = "";
      state.forms = [];
      state.jobPanelsComplete = undefined;
    },

    setUserID: (state, { payload }) => {
      state.userID = payload.userID;
    },

    setUsers: (state, { payload }) => {
      state.users = payload.users;
    },

    setModels: (state, { payload }) => {
      state.models = payload.models;
    },

    setJobs: (state, { payload }) => {
      state.jobs = payload.jobs;
    },

    setChatUrl: (state, { payload }) => {
      state.chatUrl = payload.chatUrl;
    },

    setQA: (state, { payload }) => {
      state.qa = payload.qa;
    },

    setConfig: (state, { payload }) => {
      if (payload.reset) {
        state.config = new Config({}, {}, {}, {});
      }
      else {
        state.config = new Config(
          payload.meta,
          payload.parameters,
          payload.inputs,
          payload.outputs
        );
      }
    },

    modifyConfig: (state, { payload }) => {
      switch (payload.type) {
        case "meta":
          state.config.meta = payload.value;
          break;
        case "parameters":
          state.config.parameters = payload.value;
          break;
        case "inputs":
          state.config.inputs = payload.value;
          break;
        case "outputs":
          state.config.outputs = payload.value;
          break;
      }
    },

    setOutput: (state, { payload }) => {
      state.output = payload.output.filter((output: Output) => output.result !== '');
    },

    setOutputIndex: (state, { payload }) => {
      state.outputIndex = payload.index;
    },

    setVisualIndex: (state, { payload }) => {
      state.visualIndex = payload.index;
    },

    setHtmlIndex: (state, { payload }) => {
      state.htmlIndex = payload.index;
    },

    setSheetIndex: (state, { payload }) => {
      state.sheetIndex = payload.index;
    },

    setFiles: (state, { payload }) => {
      state.files = payload.files;
    },

    setModelIndex: (state, { payload }) => {
      for (const model of state.models) {
        if (model.name === payload.modelName) {
          state.modelIndex = state.models.indexOf(model)
        }
      }
    },

    setJobIndex: (state, { payload }) => {
      if (state.jobs.length === 0) {
        state.jobIndex = -1
      }
      else if (payload.index === -1) {
        state.jobIndex = -1
      }
      else {
        for (const job of state.jobs) {
          if (job.jobID === payload.jobID) {
            state.jobIndex = state.jobs.indexOf(job)
          }
        }
      }
    },

    setFlow: (state, { payload }) => {
      state.flow = payload.flow
    },

    setForms: (state, { payload }) => {
      state.forms = payload.forms
    },

    addForm: (state, { payload }) => {
      state.forms.push(payload.form)
    },

    setJobPanelsComplete: (state, { payload }) => {
      state.jobPanelsComplete = payload.jobPanelsComplete
    },

    updateJobPanelsComplete: (state, { payload }) => {
      if (state.jobPanelsComplete) {
        state.jobPanelsComplete![payload.panel] = payload.complete
      }
    }
  }
});

export const {
  signOut,
  setUserID,
  setUsers,
  setModels,
  setJobs,
  setChatUrl,
  setQA,
  setConfig,
  modifyConfig,
  setOutput,
  setOutputIndex,
  setVisualIndex,
  setHtmlIndex,
  setSheetIndex,
  setFiles,
  setModelIndex,
  setJobIndex,
  setFlow,
  setForms,
  addForm,
  setJobPanelsComplete,
  updateJobPanelsComplete,
} = userReducer.actions;

export default userReducer.reducer;
