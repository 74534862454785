import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useViewModel } from "mvvm/View";
import { JobViewModel } from "../ViewModels/JobViewModel";
import ReviewIcon from "components/icons/ReviewIcon";
import { JobStatusWrapper } from "components/icons/JobStatus/styles";
import RunIcon from "components/icons/RunIcon";
import FailedIcon from "components/icons/FailedIcon";
import PublishedIcon from "components/icons/PublishedIcon";
import ThumbsUpSolidIcon from "components/icons/ThumbsUpSolidIcon";
import ThumbsDownSolidIcon from "components/icons/ThumbsDownSolidIcon";
import TagIcon from "components/icons/TagIcon";
import { ContextMenuView } from './ContextMenuView';


export function JobItemView(props: { model: JobViewModel }) {

    const model = useViewModel(props.model);

    let className = "flex-row";
    if (model.isSelected) {
        className += " selected-item";
    }

    const [contextMenu, setContextMenu] = useState({ visible: false, x: 0, y: 0 });
    const contextMenuRef = useRef<HTMLDivElement>(null);

    const handleRightClick = useCallback((event: React.MouseEvent) => {
        event.preventDefault();
        setContextMenu({ visible: true, x: event.clientX, y: event.clientY });
    }, []);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (contextMenuRef.current && !contextMenuRef.current.contains(event.target as Node)) {
                setContextMenu({ visible: false, x: 0, y: 0 });
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div onContextMenu={handleRightClick}>
          {
            <div className={className}>
                <div onContextMenu={handleRightClick} onClick={()=>model.isSelected = true} className="flex-1" style={{
                    fontSize: "0.9em",
                    display: "-webkit-box",
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    cursor: "pointer"
                }}>
                    <JobStatusWrapper style={{display: "inline-flex", marginRight: "0.5em", padding: "0px", transform: "translate(2px,2px)"}}>
                        <span style={{ display: "inline-flex", background: "white", borderRadius: "5px", border: "1px solid rgb(0,0,0,0.25)" }}>
                            <span className={`status ${model.status.toLowerCase()}-status`} style={{padding: "2px", width: "2em", borderRadius: "0px"}}>
                                {model.status === "RUNNING" && <RunIcon/>}
                                {model.status === "REVIEW" && <ReviewIcon />}
                                {model.status === "FAILED" && <FailedIcon />}
                                {model.status === "PUBLISHED" && <PublishedIcon />}
                            </span>
                        </span>
                    </JobStatusWrapper>
                    <span style={{display: "inline-block", opacity: 0.5, minWidth: "3em"}}>[{model.age}]</span>
                    <span title={model.question}>{model.question}</span>
                </div>
                <span style={{display: "flex", justifyContent: "space-between", width: "2.5em", alignItems: "center"}}>
                    {model.tags && Object.keys(model.tags).length > 0 ?
                        <span title={Object.values(model.tags).join(", ")} style={{marginLeft: "0.1em"}}><TagIcon/></span> :
                        <span></span>
                    }
                    {model.feedback ?
                        <span title={model.feedback.comment || "(no comment)"} style={{marginRight: "0.1em"}}>{model.feedback.rating === "up" ? <ThumbsUpSolidIcon /> : <ThumbsDownSolidIcon />}</span> :
                        null
                    }
                </span>
            </div>
          }
          {contextMenu.visible && <ContextMenuView model={model} contextMenu={contextMenu} setContextMenu={setContextMenu} contextMenuRef={contextMenuRef} />}
        </div>

    )
}