import { ParagraphWrapper } from "./styles";

const Paragraph = ({ children, className, title }: any) => {
  return (
    <ParagraphWrapper>
      <div className={className} title={title}>{children}</div>
    </ParagraphWrapper>
  );
};

export default Paragraph;
