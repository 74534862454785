export default function ReviewIcon() {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 9 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.49997 6.62375C3.60372 6.62375 2.87622 5.89625 2.87622 5C2.87622 4.10375 3.60372 3.37625 4.49997 3.37625C5.39622 3.37625 6.12372 4.10375 6.12372 5C6.12372 5.89625 5.39622 6.62375 4.49997 6.62375ZM4.49997 3.93875C3.91497 3.93875 3.43872 4.415 3.43872 5C3.43872 5.585 3.91497 6.06125 4.49997 6.06125C5.08497 6.06125 5.56122 5.585 5.56122 5C5.56122 4.415 5.08497 3.93875 4.49997 3.93875Z"
        fill="#F2AB01"
      />
      <path
        d="M4.50003 8.38249C3.09003 8.38249 1.75878 7.55749 0.843779 6.12499C0.446279 5.50624 0.446279 4.49749 0.843779 3.87499C1.76253 2.44249 3.09378 1.61749 4.50003 1.61749C5.90628 1.61749 7.23753 2.44249 8.15253 3.87499C8.55003 4.49374 8.55003 5.50249 8.15253 6.12499C7.23753 7.55749 5.90628 8.38249 4.50003 8.38249ZM4.50003 2.17999C3.28878 2.17999 2.13003 2.90749 1.32003 4.17874C1.03878 4.61749 1.03878 5.38249 1.32003 5.82124C2.13003 7.09249 3.28878 7.81999 4.50003 7.81999C5.71128 7.81999 6.87003 7.09249 7.68003 5.82124C7.96128 5.38249 7.96128 4.61749 7.68003 4.17874C6.87003 2.90749 5.71128 2.17999 4.50003 2.17999Z"
        fill="#F2AB01"
      />
    </svg>
  );
}
