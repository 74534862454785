import Model from "redux/classes/Model";
import User from "redux/classes/User";

export default class Job {
    jobID: string;
    modelID: string;
    custID: string;
    userID: string;
    alias: string;
    desc: string;
    jobName: string;
    resultName: string;
    taskID: string;
    status: string;
    startTime: Date;
    endTime: Date;

    constructor(jobID: string, modelID: string, custID: string, userID: string, alias: string, desc: string, jobName: string, resultName: string, taskID: string, status: string, startTime: Date, endTime: Date) {
        this.jobID = jobID;
        this.modelID = modelID;
        this.custID = custID;
        this.userID = userID;
        this.alias = alias;
        this.desc = desc;
        this.jobName = jobName;
        this.resultName = resultName;
        this.taskID = taskID;
        this.status = status;
        this.startTime = startTime;
        this.endTime = endTime;
    }

    getModelName(models: Model[]) {
        for (var model of models) {
            if (model.modelID === this.modelID) {
                return model.name;
            }
        }
    }

    getModelAlias(models: Model[]) {
        for (var model of models) {
            if (model.modelID === this.modelID) {
                return model.alias;
            }
        }
    }

    getModelDate(models: Model[]) {
        for (var model of models) {
            if (model.modelID === this.modelID) {
                return model.created;
            }
        }
    }

    getUserName(users: User[]) {
        for (var user of users) {
            if (user.userID === this.userID) {
                return user.name;
            }
        }
    }
}