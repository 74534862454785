import { TableVhWrapper } from "./styles";
import { Card, Select, Table } from "antd";
import SubHeading from "components/assets/text/SubHeading";
import ButtonVelocity from "components/assets/utilities/ButtonVelocity";
import { useAppSelector } from "redux/hooks";

const TableVelocity = ({
  title,
  subtitle,
  data,
  columns,
  vh,
  loading,
  refreshHandler,
  publishHandler,
  published,
  path,
  options,
  onSwitch,
  showHeader,
  type,
  download,
  extension,
  description,
}: any) => {
  const app = useAppSelector(state => state.app)
  const user = useAppSelector(state => state.user)

  return (
    <TableVhWrapper>
      <Card className="card-section">
        <div className="card-container">
          <div className="card-heading">
            {type &&
              <SubHeading
                className="flex"
                title={description}
              >{type}</SubHeading>
            }
            {subtitle &&
              <div className="subtitle">{subtitle}</div>
            }
            {options && options.length > 1 ? (
              <Select
                className="option"
                options={options.map((option: any, index: number) => ({
                  index: index,
                  title: "Click to select a different result file",
                  label: option.label,
                  value: option.label
                }))}
                onChange={(e) => onSwitch(e)}
                defaultValue={options[0].label}
                variant="borderless"
                size={"large"}
              />
            ) : (
              <SubHeading title={description}>{options && options.length === 1 ? options[0].label : title}</SubHeading>
            )}
          </div>

          <div className="card-heading">
            {refreshHandler &&
              <ButtonVelocity
                path={path}
                title={"Refresh the " + title + " table"}
                type="refresh"
                onClick={refreshHandler}
              >
                Refresh
              </ButtonVelocity>
            }

            {publishHandler && !published &&
              <ButtonVelocity
                path={path}
                title={"Promote this job to the PUBLISHED status"}
                type="publish"
                onClick={publishHandler}
                disable={published}
                submitting={app.submitting}
              >
                Publish
              </ButtonVelocity>
            }

            {publishHandler && published &&
              <ButtonVelocity
                path={path}
                title={"This job has been published"}
                type="published"
              >
                Published
              </ButtonVelocity>
            }

            {download && data.length > 0 &&
              <ButtonVelocity
                path={path}
                type="download"
                title={"Download " + user.output[user.outputIndex].path}
                onClick={download}
              >
                Download (.{extension})
              </ButtonVelocity>
            }
          </div>
        </div>
        <div className="custom-table-scrollbar">
          {loading ?
            <div className="loader"/> :
            <Table
              className={
                "card-table " +
                (title === 'My Jobs' ? "job" : " ") +
                (showHeader === false ? "formatted " : " ")
              }
              dataSource={data}
              columns={columns}
              pagination={false}
              scroll={{ y: vh }}
              showHeader={showHeader}
            />
          }
        </div>
      </Card>
    </TableVhWrapper>
  );
};

export default TableVelocity;
