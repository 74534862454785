import { Col, Row } from "antd";
import { QAChecksColumns } from "components/pages/QA/columns";
import { useAppSelector } from "redux/hooks";
import TableVelocity from "components/assets/utilities/TableVelocity";
import QA from "redux/classes/QA";

const QACheckPage = () => {
  const user = useAppSelector((state) => state.user);
  const modelInfo = user.models[user.modelIndex];

  const prepareQA = (checks: QA[]) => {
    return checks.map(check => {
      return {
        key: check.qaID,
        type: check.type,
        category: check.category,
        name: check.name,
        description: check.description,
        fail_threshold: check.fail_threshold,
        warn_threshold: check.warn_threshold
      }
    })
  }

  return (
    <Row>
      <Col md={24}>
        <TableVelocity
          title={"QA Checks"}
          description={"QA Checks for " + modelInfo.name}
          data={prepareQA(modelInfo.qa)}
          columns={QAChecksColumns}
          vh="600px"
        />
      </Col>
    </Row>
  );
};

export default QACheckPage;
