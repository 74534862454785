import { AppDispatch } from "redux/store";
import { CallLambda } from "./CallLambda";
import { setOutput, setQA } from "redux/slices/user";
import QA from "redux/classes/QA";
import Output from "redux/classes/Output";
import Session from "redux/classes/Session";

export function LoadResultAction(session: Session, jobID: string, modelAlias: string, outputs: {[key: string]: string}, status: string) {
    const updateQA = (dispatch: AppDispatch, qa: any) => {
        let checks: QA[] = []
        for (var value of qa.slice(1)) {
            checks.push(new QA(
                `${value[0]}_${value[1]}_${value[2]}`,
                value[0],
                value[1],
                value[2],
                value[3],
                value[4],
                value[5],
                value[6],
                value[7]
            ))
        }

        dispatch(setQA({ qa: checks }));
    }

    const updateResults = (dispatch: AppDispatch, results: any) => {
        let outputObjects: Output[] = []
        for (let key in results) {
            outputObjects.push(new Output(
                Object.keys(outputs).find(k => outputs[k] === key) as string,
                key,
                results[key]
            ))
        }

        dispatch(setOutput({ output: outputObjects }));
    }

    return async (dispatch: AppDispatch) => {
        const payload = JSON.stringify({
            "model": modelAlias,
            "jobID": jobID,
            "outputs": Object.values(outputs),
            "status": status
        })

        let response: {status: number, data: any} = await dispatch(CallLambda(session, "resultLoader", payload))

        if (response.status === 200) {
            updateQA(dispatch, response.data.qa)
            updateResults(dispatch, response.data.results)
        }
    }
}