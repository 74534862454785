export const QAChecksColumns = [
  {
    title: <span title="Type of the QA Check">Type</span>,
    dataIndex: "type",
    key: "type",
    width: 100,
  },
  {
    title: <span title="Category of the QA Check">Category</span>,
    dataIndex: "category",
    key: "category",
    width: 180,
  },
  {
    title: <span title="Name of the QA Check">Name</span>,
    dataIndex: "name",
    key: "name",
    width: 200,
  },
  {
    title: <span title="Description of the QA Check">Description</span>,
    dataIndex: "description",
    key: "description",
    width: 360,
  },
  {
    title: <span title="Fail Threshold of the QA Check">Fail Threshold</span>,
    dataIndex: "fail_threshold",
    key: "fail_threshold",
    width: 120,
    align: "center"
  },
  {
    title: <span title="Warning Threshold of the QA Check (optional)">Warning Threshold</span>,
    dataIndex: "warn_threshold",
    key: "warn_threshold",
    width: 120,
    align: "center"
  },
];