import { AppDispatch } from "redux/store";
import { CallLambda } from "./CallLambda";
import Job from "redux/classes/Job";
import { setJobIndex, setJobs } from "redux/slices/user";
import Session from "redux/classes/Session";

export function DeleteJobAction(session: Session, jobID: string, taskID: string|undefined) {
    return async (dispatch: AppDispatch) => {
        const req: { [key: string]: any } = { jobID: jobID }
        if (taskID !== undefined) {
            req.taskID = taskID
        }
        const payload = JSON.stringify(req)

        let response: {status: number, data: any} = await dispatch(CallLambda(session, "jobDeleter", payload))

        if (response.status === 200) {
            const jobs = response.data.jobs.map((job: any) => {
                return new Job(
                    job.job_id,
                    job.model_id,
                    job.cust_id,
                    job.user_id,
                    job.alias,
                    job.description,
                    job.job_name,
                    job.result_name,
                    job.task_id,
                    job.job_status,
                    new Date(job.start_time),
                    new Date(job.end_time)
                )
            })

            dispatch(setJobs({ jobs: jobs }));
            dispatch(setJobIndex({ index: -1 }))
        }
    }
}