import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";

interface initialStateTypes {
  isAuthorized: boolean;
  loading: boolean;
  submitting: boolean;
  hasErrors: boolean;
}

const initialState: initialStateTypes = {
  isAuthorized: false,
  loading: false,
  submitting: false,
  hasErrors: false,
};

export const appReducer = createSlice({
  name: "app",
  initialState,
  reducers: {
    requestStart: (state) => {
      state.loading = true;
    },

    requestCompleted: (state) => {
      state.loading = false;
    },

    startSubmitting: (state) => {
      state.submitting = true;
    },

    endSubmitting: (state) => {
      state.submitting = false;
    },

    requestSuccess: (state) => {
      state.loading = false;
      state.hasErrors = false;
    },

    requestFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },

    sendError: (_, { payload }) => {
      message.error(payload.message)
    }
  },
});

export const {
  requestStart,
  requestSuccess,
  startSubmitting,
  endSubmitting,
  requestFailure,
  sendError,
  requestCompleted,
} = appReducer.actions;

export default appReducer.reducer;
