import styled from "styled-components";

export const SegmentsWrapper = styled.div`
  form {
    margin-top: 38px;
  }
  .ant-form-item-label label {
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    color: #1c1c1c;
  }
  .ant-select-selector {
    border-radius: 8px;
    border: 1px solid #eaeaea !important;
    background: #fafafa !important;
    height: 56px !important;
  }
  .ant-select-arrow {
    height: 100%
  }
  .multi {
    .ant-select-arrow {
      height: auto
    }
  }

  .brand-select .ant-select-selector .ant-select-selection-item,
  .ant-select-selector .ant-select-selection-placeholder {
    line-height: 53px !important;
  }
  .ant-select-selector .ant-select-selection-item,
  .ant-select-selector .ant-select-selection-placeholder {
    font-family: "PoppinsRegular", sans-serif !important;
  }
  .ant-picker {
    height: 48px;
    border: 1px solid #eaeaea;
    background: #fafafa;
  }

  .large {
    font-size: 18px;
  }

  .range {
    width: 100%;
    height: 54px;
  }

  .radio {
    margin-top: 7px;
  }

  .segment-form-item {
    display: flex;
    align-items: center;
  }

  .segment-form-item-label {
    margin-right: 0.5em;
    margin-left: 0.5em;
    font-size: 1.5em;
  }
`;
