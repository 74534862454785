//import "highlight.js/styles/base16/green-screen.css";
import { createElement, Fragment, useMemo, useState } from "react";
import rehypeReact from "rehype-react";
import remarkGfm from "remark-gfm";
import remarkParse from "remark-parse";
import remarkRehype from "remark-rehype";
import { unified } from "unified";
import { MarkdownViewerWrapper } from "./styles";
import { notification } from "antd";
import { CopyIcon } from "components/icons/CopyIcon";

export function MarkdownViewer(props: { markdown: string }) {
    const markdown = props.markdown;
    const content = useMemo(() => {
      try {
        return (
          unified()
            // Parse the raw string
            .use(remarkParse)
            // Add support for GitHub-flavored Markdown
            .use(remarkGfm)
            // Convert the remark tree (Markdown) into a rehype tree (HTML)
            .use(remarkRehype)
            // Add support for syntax highlighting (and avoid throwing when it's an unknown language)
            //.use(rehypeHighlight, {})
            // Convert the rehype tree (HTML) into a React component tree,
            // with custom components for each element...
            .use(rehypeReact, {
                passNode: true,
                Fragment,
                createElement,
              components: {
                a: ({ href, children }: JSX.IntrinsicElements["a"]) => (
                  <a href={href} target="_blank" rel="noreferrer" className="...">
                    {children}
                  </a>
                ),
                h1: ({ children, id }: JSX.IntrinsicElements["h1"]) => (
                  <h1 className="..." id={id}>
                    {children}
                  </h1>
                ),
                h2: ({ children, id }: JSX.IntrinsicElements["h2"]) => (
                  <h2 className="..." id={id}>
                    {children}
                  </h2>
                ),
                h3: ({ children, id }: JSX.IntrinsicElements["h3"]) => (
                  <h3 className="..." id={id}>
                    {children}
                  </h3>
                ),
                h4: ({ children, id }: JSX.IntrinsicElements["h4"]) => (
                  <h4 className="..." id={id}>
                    {children}
                  </h4>
                ),
                h5: ({ children, id }: JSX.IntrinsicElements["h5"]) => (
                  <h5 className="..." id={id}>
                    {children}
                  </h5>
                ),
                h6: ({ children, id }: JSX.IntrinsicElements["h6"]) => (
                  <h6 className="..." id={id}>
                    {children}
                  </h6>
                ),
                p: ({ children }: JSX.IntrinsicElements["p"]) => {
                  return <p className="...">{children}</p>;
                },
                strong: ({ children }: JSX.IntrinsicElements["strong"]) => (
                  <strong className="...">{children}</strong>
                ),
                em: ({ children }: JSX.IntrinsicElements["em"]) => (
                  <em>{children}</em>
                ),
                code: CodeBlock,
                pre: ({ children }: JSX.IntrinsicElements["pre"]) => {
                  return (
                    <div className="...">
                      <pre className="...">{children}</pre>
                    </div>
                  );
                },
                ul: ({ children }: JSX.IntrinsicElements["ul"]) => (
                  <ul className="...">{children}</ul>
                ),
                ol: ({ children }: JSX.IntrinsicElements["ol"]) => (
                  <ol className="...">{children}</ol>
                ),
                li: ({ children }: JSX.IntrinsicElements["li"]) => (
                  <li className="...">{children}</li>
                ),
                table: ({ children }: JSX.IntrinsicElements["table"]) => (
                  <div className="...">
                    <table className="...">{children}</table>
                  </div>
                ),
                thead: ({ children }: JSX.IntrinsicElements["thead"]) => (
                  <thead className="...">{children}</thead>
                ),
                th: ({ children }: JSX.IntrinsicElements["th"]) => (
                  <th className="...">{children}</th>
                ),
                td: ({ children }: JSX.IntrinsicElements["td"]) => (
                  <td className="...">{children}</td>
                ),
                blockquote: ({ children }: JSX.IntrinsicElements["blockquote"]) => (
                  <blockquote className="...">{children}</blockquote>
                ),
              },
            })
            .processSync(markdown).result
          );
        } catch (error) {
          return <pre>{markdown}</pre>;
        }
    }, [markdown]);
    return <MarkdownViewerWrapper>
    <div style={{maxWidth: "100%"}}>{content}</div>
    </MarkdownViewerWrapper>;
}

let nextCodeBlockId = 0;

// A more complex custom component for the `code` element.
const CodeBlock = ({ children, className }: JSX.IntrinsicElements["code"]) => {

    const [codeBlockId, setCodeBlockId] = useState<number>(() => nextCodeBlockId++);
    const [isMouseOnIcon, setIsMouseOnIcon] = useState(false);

    // Highlight.js adds a `className` so this is a hack to detect if the code block
    // is a language block wrapped in a `pre` tag versus an inline `code` tag.
    if (className) {
      const classNames = className.split(" ");
      const languageClassNames = classNames.filter((c) => c.startsWith("language-"));
      //const language = languageClassNames.length > 0 ? languageClassNames[0].replace("language-", "").toUpperCase() : "CODE";

      const [notify, contextHolder] = notification.useNotification();

      const copyContent = () => {
          const el = document.getElementById(`md-code-block-${codeBlockId}`);
          if (el) {
              try {
                  navigator.clipboard.writeText(el.innerText);
                  //alert('Code copied to clipboard');
                  notify.info({
                    message: "Code copied to clipboard",
                    //description: "You can paste it in your SQL editor",
                    placement: "top"
                  })
              } catch (err) {
              }
          }
      }
      return (
        <>
          {contextHolder}
          <div className="..." style={{ margin: "0 2em", borderRadius: "1em", border: "1px solid #cccccc", background: "#f7f7f7", overflow: "clip"}}>
            <div className="..." style={{ paddingTop: "0.5em", fontSize: "1.15em", display: "flex", flexDirection: "row"}}>
              <div style={{ flex: "1 1 auto", marginLeft: "0.5em"}}></div>
              <div style={{ flex: "none", opacity: 0.8, marginRight: "0.5em", position: "relative"}}>
                <div style={{position: "absolute", top: "0", right: "0"}}>
                  <a
                    onMouseEnter={() => setIsMouseOnIcon(true)}
                    onMouseLeave={() => setIsMouseOnIcon(false)}
                    onClick={()=>copyContent()}
                    style={{ color: "#999", fontSize: "0.8em"}}
                  >
                    <CopyIcon size={24} hovering={isMouseOnIcon}/>
                  </a>
                </div>
              </div>
            </div>
            <div style={{ paddingLeft: "1em", paddingBottom: "1em", overflow: "auto" }}><code className={className} id={`md-code-block-${codeBlockId}`}>{children}</code></div>
          </div>
        </>
      );
    }

    // Handle an inline `code` tag.
    return <code className="...">{children}</code>;
  };