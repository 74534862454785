export default function WarningIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.99979 0.857143L11.1426 10H0.856934L5.99979 0.857143Z"
        stroke="#F2AB01"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 6.57143V3.71429"
        stroke="#F2AB01"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.99965 8.85714C6.31524 8.85714 6.57108 8.60131 6.57108 8.28572C6.57108 7.97012 6.31524 7.71429 5.99965 7.71429C5.68406 7.71429 5.42822 7.97012 5.42822 8.28572C5.42822 8.60131 5.68406 8.85714 5.99965 8.85714Z"
        fill="#F2AB01"
      />
    </svg>
  );
}
