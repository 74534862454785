import { AppDispatch } from "redux/store";
import { CallLambda } from "./CallLambda";
import { setJobIndex, setJobs } from "redux/slices/user";
import Job from "redux/classes/Job";
import Session from "redux/classes/Session";

export function RefreshAction(session: Session) {
    return async (dispatch: AppDispatch) => {
        dispatch(setJobs({ jobs: [] }));
        dispatch(setJobIndex({}));

        const payload = JSON.stringify({"table": "jobs"});

        let response: {status: number, data: any} = await dispatch(CallLambda(session, "tableGrabber", payload))

        if (response.status === 200) {
            const jobs = response.data.values.map((job: any) => {
                return new Job(
                    job.job_id,
                    job.model_id,
                    job.cust_id,
                    job.user_id,
                    job.alias,
                    job.description,
                    job.job_name,
                    job.result_name,
                    job.task_id,
                    job.job_status,
                    new Date(job.start_time),
                    new Date(job.end_time)
                )
            })

            dispatch(setJobs({ jobs: jobs }));
        }
    }
}