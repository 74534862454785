import { Col, Row } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import InputFiles from "components/panels/Run/InputFiles";
import Parameters from "components/panels/Run/Parameters";
import JobInfoPanel from "components/panels/Run/InfoPanel";
import ButtonVelocity from "components/assets/utilities/ButtonVelocity";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useEffect } from "react";
import { modifyConfig, setConfig, setJobPanelsComplete } from "redux/slices/user";
import { LoadFilesAction } from "redux/actions/LoadFiles";
import { RunModelAction } from "redux/actions/RunModel";
import Field from "redux/classes/Field";
import { endSubmitting, requestCompleted, requestFailure, startSubmitting } from "redux/slices/app";
import { useSession } from "components/wrappers/SessionProvider";
import Constraints from "components/panels/Run/Constraints";
import Segments from "components/panels/Run/Segments";
import Tactics from "components/panels/Run/Tactics";
import { RunPageWrapper } from "./styles";

const RunPage = () => {
  const dispatch = useAppDispatch();
  const app = useAppSelector(state => state.app);
  const user = useAppSelector(state => state.user);
  const modelInfo = user.models[user.modelIndex];
  const pageLocation = useLocation().pathname;
  const navigate = useNavigate();
  const session = useSession();

  useEffect(() => {
    dispatch(setConfig({ reset: true }))

    const getFiles = async () => {
      await dispatch(LoadFilesAction(session, modelInfo.alias, modelInfo.inputs))
      dispatch(requestCompleted());
    }
    getFiles().catch(() => dispatch(requestFailure()))

    const outputs: {[key: string]: string} = {}
    modelInfo.outputs.map(output => {
      outputs[output.fieldType] = output.label + output.dataType
      return true
    })

    dispatch(modifyConfig({
      type: "outputs",
      value: outputs
    }))
  }, [])

  useEffect(() => {
    if (user.jobPanelsComplete) {
      if (Object.values(user.jobPanelsComplete!).every(value => value)) {
        const runModel = async () => {
          await dispatch(RunModelAction(session, user.config, modelInfo)).catch(console.error);
          dispatch(setJobPanelsComplete({ jobPanelsComplete: false }))
          navigate(`/${modelInfo.alias}/info`)
        }
        runModel().catch(() => dispatch(endSubmitting()))
      }
    }
    else {
      dispatch(endSubmitting());
    }

  }, [user.jobPanelsComplete])

  return (
    <RunPageWrapper>
      {app.loading ? <div className="loader-centered"/> :
        <>
          <Row gutter={20} className="mt-24">
            <Col md={24} className="input-container">
              <JobInfoPanel/>
            </Col>
          </Row>

          {modelInfo.inputs.length > 0 &&
            <Row>
              <Col md={24}>
                <InputFiles/>
              </Col>
            </Row>
          }

          {modelInfo.parameters.length > 0 &&
            <Row gutter={20} className="mt-24">
              <Col md={24} className="input-container">
                <Parameters/>
              </Col>
            </Row>
          }

          {modelInfo.parameters.filter((field: Field) => field.fieldType === "sliders").length > 0 &&
            <Row gutter={20} className="mt-24">
              <Col md={24} className="input-container">
                <Constraints/>
              </Col>
            </Row>
          }

          {modelInfo.parameters.filter((field: Field) => field.fieldType === "segment").length > 0 &&
            <Row gutter={20} className="mt-24">
              <Col md={24} className="input-container">
                <Segments/>
              </Col>
            </Row>
          }

          {modelInfo.parameters.filter((field: Field) => field.fieldType === "tactics").length > 0 &&
            <Row gutter={20} className="mt-24">
              <Col md={24} className="input-container">
                <Tactics/>
              </Col>
            </Row>
          }

          <Row>
            <Col md={24} className="mt-24 mb-20">
             <div className="spacer" />
            </Col>
          </Row>

          <div className="float-bottom-right">
            <ButtonVelocity
              type="result"
              title={"Run a " + modelInfo.name + " job with the specified configuration"}
              path={pageLocation}
              onClick={() => {
                let jobPanelsComplete: {[key: string]: boolean} = {
                  'meta': false
                }
                if (modelInfo.inputs.length > 0) {
                  jobPanelsComplete['inputs'] = false
                }
                if (modelInfo.parameters.length > 0) {
                  jobPanelsComplete['parameters'] = false
                }
                dispatch(setJobPanelsComplete({ jobPanelsComplete: jobPanelsComplete }))

                dispatch(startSubmitting())
              }}
              submitting={app.submitting}
            >
              Run Model
            </ButtonVelocity>
          </div>
        </>
      }
    </RunPageWrapper>
  );
};

export default RunPage;