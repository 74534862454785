export default function PublishedIcon() {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 9 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.625 9.03125H3.375C1.33875 9.03125 0.46875 8.16125 0.46875 6.125V3.875C0.46875 1.83875 1.33875 0.96875 3.375 0.96875H5.625C7.66125 0.96875 8.53125 1.83875 8.53125 3.875V6.125C8.53125 8.16125 7.66125 9.03125 5.625 9.03125ZM3.375 1.53125C1.64625 1.53125 1.03125 2.14625 1.03125 3.875V6.125C1.03125 7.85375 1.64625 8.46875 3.375 8.46875H5.625C7.35375 8.46875 7.96875 7.85375 7.96875 6.125V3.875C7.96875 2.14625 7.35375 1.53125 5.625 1.53125H3.375Z"
        fill="#3D9CF5"
      />
      <path
        d="M3.96748 6.34251C3.89248 6.34251 3.82123 6.31251 3.76873 6.26001L2.70748 5.19876C2.59873 5.09001 2.59873 4.91001 2.70748 4.80126C2.81623 4.69251 2.99623 4.69251 3.10498 4.80126L3.96748 5.66376L5.89498 3.73626C6.00373 3.62751 6.18373 3.62751 6.29248 3.73626C6.40123 3.84501 6.40123 4.02501 6.29248 4.13376L4.16623 6.26001C4.11373 6.31251 4.04248 6.34251 3.96748 6.34251Z"
        fill="#3D9CF5"
      />
    </svg>
  );
}
