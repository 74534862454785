import { Card, Col, Form, Row, Select, InputNumber, Slider, Checkbox } from "antd";
import type { SliderSingleProps } from 'antd';
import SecondaryHeading from "components/assets/text/SecondaryHeading";
import { ConstraintsWrapper } from "./styles";
import Field from "redux/classes/Field";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import Subtitle from "components/assets/text/Subtitle";
import ButtonVelocity from "components/assets/utilities/ButtonVelocity";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { modifyConfig } from "redux/slices/user";

const Constraints = () => {
  const dispatch = useAppDispatch();
  const app = useAppSelector(state => state.app)
  const user = useAppSelector(state => state.user)
  const modelInfo = user.models[user.modelIndex];
  const fields = modelInfo.parameters;
  const path = useLocation().pathname;

  const formatter: NonNullable<SliderSingleProps['tooltip']>['formatter'] = (value) => `${value}%`;
  const defaultValues = fields[3].defaultValue;

  const [disabled, setDisabled] = useState(new Array(defaultValues[0].length).fill(true));
  const [disabledAll, setDisabledAll] = useState(true);
  const [minValues, setMinValues] = useState(defaultValues[0].map((value: string) => parseFloat(value)));
  const [maxValues, setMaxValues] = useState(defaultValues[1].map((value: string) => parseFloat(value)));
  const [minAll, setMinAll] = useState(50);
  const [maxAll, setMaxAll] = useState(50);

  const [form] = Form.useForm();

  const applyAllPerc = (side: string) => {
    if (side === 'left') {
      setMinValues(new Array(defaultValues[0].length).fill(minAll));
    }
    else {
      setDisabled(new Array(defaultValues[1].length).fill(disabledAll));
      setMaxValues(new Array(defaultValues[1].length).fill(maxAll));
    }
  };

  useEffect(() => {
    const parameters = { ...user.config.parameters };
    parameters[fields[3].label][0] = minValues;
    disabled.forEach((value, index) => {
      if (value) {
        parameters[fields[3].label][1][index] = -1;
      }
      else {
        parameters[fields[3].label][1][index] = maxValues[index];
      }
    });

    // Dispatch an action to update the Redux store
    dispatch(modifyConfig({
      type: "parameters",
      value: parameters
    }));

  }, [minValues, maxValues, disabled]);

  const applyAll = (side: string, newValue: string) => {
    const values = form.getFieldsValue();
    const keys = Object.keys(values);

    const parameters = { ...user.config.parameters };

    keys.forEach(key => {
      if (key.includes(side)) {
        values[key] = newValue;

        // Update the corresponding parameter in the Redux store
        const fieldId = key.split('_').slice(0, 2).join('_');
        const optionIndex = parseInt(key.split('_')[key.split('_').length - 1]);
        const sideIndex = side === 'left' ? 0 : 1;

        // find the field label from the fieldId
        let fieldLabel = "";
        fields.forEach((field: Field) => {
          if (field.fieldID === fieldId) {
            fieldLabel = field.label;
          }
        });
        if (parameters[fieldLabel]) {
          parameters[fieldLabel][optionIndex][sideIndex] = newValue;
        }
      }
    });

    form.setFieldsValue(values);

    // Dispatch an action to update the Redux store
    dispatch(modifyConfig({
      type: "parameters",
      value: parameters
    }));
  };

  return (
    <ConstraintsWrapper>
      <Card className="height-control">
        <SecondaryHeading title="Specify the Constraints that should be used in your job">Constraints</SecondaryHeading>

        {fields.length > 0 && (
          <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            requiredMark={false}
          >
            <Row gutter={[64, 12]}>
              {fields.map((field: Field) => {
                switch (field.fieldType) {
                  case "sliders":
                    return (
                      <Col span={24} key={field.fieldID}>
                        <Row gutter={[24, 20]}>
                          {
                            field.options[1].slice(0, 1).map((option: string, index: number) => {
                              return (
                                <React.Fragment key={field.fieldID + "_header_" + index}>
                                  <Col
                                    span={8}
                                  />
                                  <Col
                                    span={8}
                                    style={{
                                      marginTop: "-5em",
                                    }}
                                  >
                                    <Row>
                                      <Col span={2} />
                                      <Col span={18}>
                                        <Slider
                                          min={0}
                                          max={100}
                                          onChange={(value) => {
                                            setMinAll(value as number);
                                          }}
                                          value={minAll}
                                          step={1}
                                          tooltip={{ formatter }}
                                        />
                                      </Col>
                                      <Col span={2}>
                                        <InputNumber
                                          style={{ marginLeft: '8px', marginRight: '8px', width: '60px' }}
                                          name={field.fieldID + "_options_item_left_" + index}
                                          min={0}
                                          max={100}
                                          step={1}
                                          value={minAll}
                                          onChange={(value) => {
                                            setMinAll(value as number);
                                          }}
                                          formatter={(value) => `${value}%`}
                                          parser={(value) => value?.replace('%', '') as unknown as number}
                                        />
                                      </Col>
                                    </Row>
                                    <Row justify="center">
                                      <ButtonVelocity
                                        path={path}
                                        type="constraint"
                                        title={option}
                                        onClick={() => applyAllPerc('left')}
                                        >
                                        {option}
                                      </ButtonVelocity>
                                    </Row>
                                  </Col>
                                  <Col
                                    span={8}
                                    style={{
                                      marginTop: "-5em",
                                    }}
                                  >
                                    <Row>
                                      <Col span={18}>
                                        <Slider
                                          min={0}
                                          max={100}
                                          onChange={(value) => {
                                            setMaxAll(value as number);
                                          }}
                                          value={maxAll}
                                          step={1}
                                          disabled={disabledAll}
                                          tooltip={{ formatter }}
                                        />
                                      </Col>
                                      <Col span={2}>
                                        <InputNumber
                                          style={{ marginLeft: '8px', width: '60px' }}
                                          name={field.fieldID + "_options_item_left_" + index}
                                          min={0}
                                          max={100}
                                          step={1}
                                          value={maxAll}
                                          onChange={(value) => {
                                            setMaxAll(value as number);
                                          }}
                                          disabled={disabledAll}
                                          formatter={(value) => `${value}%`}
                                          parser={(value) => value?.replace('%', '') as unknown as number}
                                        />
                                      </Col>
                                      <Col span={2} />
                                      <Col span={2}>
                                        <Checkbox
                                          style={{ marginTop: "0.4em" }}
                                          checked={disabledAll}
                                          onChange={() => {
                                            setDisabledAll(!disabledAll);
                                          }}
                                        >
                                          <span style={{ marginLeft: '-6px' }}>Any</span>
                                        </Checkbox>
                                      </Col>
                                    </Row>
                                    <Row justify="center">
                                      <ButtonVelocity
                                        path={path}
                                        type="constraint"
                                        title={option}
                                        onClick={() => applyAllPerc('right')}
                                        >
                                        {option}
                                      </ButtonVelocity>
                                    </Row>
                                  </Col>
                                </React.Fragment>
                              )
                            })
                          }
                          {
                            field.options[0].map((option: any, index: number) => {
                              return (
                                <Col
                                  span={8}
                                  key={field.fieldID + "_titles_" + index}
                                  style={{ textAlign: "center" }}
                                >
                                  <SecondaryHeading>{option}</SecondaryHeading>
                                </Col>
                              )
                            })
                          }
                          {
                            field.options[1].slice(1).map((option: any, index: number) => {
                              return (
                                <React.Fragment key={field.fieldID + "_options_" + index}>
                                  <Col
                                    span={8}
                                    style={{ marginTop: "1em" }}
                                  >
                                    <Subtitle>{option}</Subtitle>
                                  </Col>
                                  <Col
                                    span={8}
                                  >
                                    <Row>
                                      <Col span={20}>
                                        <Slider
                                          min={0}
                                          max={100}
                                          onChange={(value) => {
                                            const newValues = [...minValues];
                                            newValues[index] = value as number;
                                            setMinValues(newValues);
                                            const parameters = { ...user.config.parameters };
                                            parameters[field.label][0][index] = value;
                                            dispatch(modifyConfig({
                                              type: "parameters",
                                              value: parameters
                                            }));
                                          }}
                                          value={minValues[index]}
                                          step={1}
                                          tooltip={{ formatter }}
                                        />
                                      </Col>
                                      <Col span={2}>
                                        <InputNumber
                                          style={{ marginLeft: '8px', width: '60px' }}
                                          name={field.fieldID + "_options_item_left_" + index}
                                          min={0}
                                          max={100}
                                          step={1}
                                          value={minValues[index]}
                                          onChange={(value) => {
                                            const newValues = [...minValues];
                                            newValues[index] = value as number;
                                            setMinValues(newValues);
                                            const parameters = { ...user.config.parameters };
                                            parameters[field.label][0][index] = value;
                                            dispatch(modifyConfig({
                                              type: "parameters",
                                              value: parameters
                                            }));
                                          }}
                                          formatter={(value) => `${value}%`}
                                          parser={(value) => value?.replace('%', '') as unknown as number}
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col
                                    span={8}
                                  >
                                    <Row>
                                      <Col span={18}>
                                        <Slider
                                          min={0}
                                          max={100}
                                          onChange={(value) => {
                                            const newValues = [...maxValues];
                                            newValues[index] = value as number;
                                            setMaxValues(newValues);
                                            const parameters = { ...user.config.parameters };
                                            parameters[field.label][1][index] = value;
                                            dispatch(modifyConfig({
                                              type: "parameters",
                                              value: parameters
                                            }));
                                          }}
                                          value={maxValues[index]  < 0 ? 100 : maxValues[index]}
                                          step={1}
                                          disabled={disabled[index]}
                                          tooltip={{ formatter }}
                                        />
                                      </Col>
                                      <Col span={2}>
                                        <InputNumber
                                          style={{ marginLeft: '8px', width: '60px' }}
                                          name={field.fieldID + "_options_item_right_" + index}
                                          min={0}
                                          max={100}
                                          step={0.01}
                                          value={maxValues[index]  < 0 ? 100 : maxValues[index]}
                                          onChange={(value) => {
                                            const newValues = [...maxValues];
                                            newValues[index] = value as number;
                                            setMaxValues(newValues);
                                            const parameters = { ...user.config.parameters };
                                            parameters[field.label][1][index] = value;
                                            dispatch(modifyConfig({
                                              type: "parameters",
                                              value: parameters
                                            }));
                                          }}
                                          disabled={disabled[index]}
                                          formatter={(value) => `${value}%`}
                                          parser={(value) => value?.replace('%', '') as unknown as number}
                                        />
                                      </Col>
                                      <Col span={2} />
                                      <Col span={2}>
                                        <Checkbox
                                          checked={disabled[index]}
                                          style={{ marginTop: "0.4em" }}
                                          onChange={() => {
                                            const newValues = [...disabled];
                                            newValues[index] = !newValues[index];
                                            setDisabled(newValues);
                                          }}
                                        >
                                          <span style={{ marginLeft: '-6px' }}>Any</span>
                                        </Checkbox>
                                      </Col>
                                    </Row>
                                  </Col>
                                </React.Fragment>
                              )
                            })
                          }
                        </Row>
                      </Col>
                    )
                  case "dropdowns":
                    return (
                      <Col span={24} key={field.fieldID}>
                        <Row gutter={[64, 20]}>
                          {
                            field.options[1].slice(0, 1).map((option: string, index: number) => {
                              return (
                                <React.Fragment key={field.fieldID + "_header_" + index}>
                                  <Col
                                    span={8}
                                  />
                                  <Col
                                    span={8}
                                    style={{
                                      marginTop: "-5em",
                                      display: "flex",
                                    }}
                                  >
                                    <Form.Item
                                      name={field.fieldID + "_header_item_0"}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please select one"
                                        }
                                      ]}
                                      style={{
                                        flexGrow: 1,
                                        marginRight: "1em",
                                      }}
                                      initialValue={field.defaultValue[index][0]}
                                    >
                                      <Select
                                        options={field.options[2].map((option: any) => ({
                                          label: option,
                                          value: option
                                        }))}
                                        placeholder={"Select one"}
                                        showSearch
                                        disabled={app.submitting}
                                      />
                                    </Form.Item>
                                    <ButtonVelocity
                                      path={path}
                                      type="constraint"
                                      title={option}
                                      onClick={() => applyAll('left', form.getFieldValue(field.fieldID + "_header_item_0"))}
                                    >
                                      {option}
                                    </ButtonVelocity>
                                  </Col>
                                  <Col
                                    span={8}
                                    style={{
                                      marginTop: "-5em",
                                      display: "flex",
                                    }}
                                  >
                                    <Form.Item
                                      name={field.fieldID + "_header_item_1"}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please select one"
                                        }
                                      ]}
                                      style={{
                                        flexGrow: 1,
                                        marginRight: "1em",
                                      }}
                                      initialValue={field.defaultValue[index][1]}
                                    >
                                      <Select
                                        options={field.options[3].map((option: any) => ({
                                          label: option,
                                          value: option
                                        }))}
                                        placeholder={"Select one"}
                                        showSearch
                                        disabled={app.submitting}
                                      />
                                    </Form.Item>
                                    <ButtonVelocity
                                      path={path}
                                      type="constraint"
                                      title={option}
                                      onClick={() => applyAll('right', form.getFieldValue(field.fieldID + "_header_item_1"))}
                                    >
                                      {option}
                                    </ButtonVelocity>
                                  </Col>
                                </React.Fragment>
                              )
                            })
                          }
                          {
                            field.options[0].map((option: any, index: number) => {
                              return (
                                <Col
                                  span={8}
                                  key={field.fieldID + "_titles_" + index}
                                  style={{ textAlign: "center" }}
                                >
                                  <SecondaryHeading>{option}</SecondaryHeading>
                                </Col>
                              )
                            })
                          }
                          {
                            field.options[1].slice(1).map((option: any, index: number) => {
                              return (
                                <React.Fragment key={field.fieldID + "_options_" + index}>
                                  <Col
                                    span={8}
                                    style={{ marginTop: "1em" }}
                                  >
                                    <Subtitle>{option}</Subtitle>
                                  </Col>
                                  <Col
                                    span={8}
                                  >
                                    <Form.Item
                                      name={field.fieldID + "_options_item_left_" + index}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please select one"
                                        }
                                      ]}
                                      initialValue={field.defaultValue[index + 1][0]}
                                    >
                                      <Select
                                        options={field.options[2].map((option: any) => ({
                                          label: option,
                                          value: option
                                        }))}
                                        placeholder={"Select one"}
                                        showSearch
                                        disabled={app.submitting}
                                        onChange={(value) => {
                                          let parameters = { ...user.config.parameters };
                                          parameters[field.label][index][0] = value;
                                          dispatch(modifyConfig({
                                            type: "parameters",
                                            value: parameters
                                          }));
                                        }}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col
                                    span={8}
                                  >
                                    <Form.Item
                                      name={field.fieldID + "_options_item_right_" + index}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please select one"
                                        }
                                      ]}
                                      initialValue={field.defaultValue[index + 1][1]}
                                    >
                                      <Select
                                        options={field.options[3].map((option: any) => ({
                                          label: option,
                                          value: option
                                        }))}
                                        placeholder={"Select one"}
                                        showSearch
                                        disabled={app.submitting}
                                        onChange={(value) => {
                                          let parameters = { ...user.config.parameters };
                                          parameters[field.label][index][1] = value;
                                          dispatch(modifyConfig({
                                            type: "parameters",
                                            value: parameters
                                          }));
                                        }}
                                      />
                                    </Form.Item>
                                  </Col>
                                </React.Fragment>
                              )
                            })
                          }
                        </Row>
                      </Col>
                    )
                  default:
                    return null
                }
              })}
            </Row>
          </Form>
        )}
      </Card>
    </ConstraintsWrapper>
  );
};

export default Constraints;
