import { message } from "antd";
import { AppDispatch } from "redux/store";
import { CallLambda } from "./CallLambda";
import { requestCompleted, requestFailure, requestStart } from "redux/slices/app";
import { setUserID, setJobs, setModels, setUsers, setChatUrl } from "redux/slices/user";
import User from "redux/classes/User";
import Model from "redux/classes/Model";
import Job from "redux/classes/Job";
import QA from "redux/classes/QA";
import Field from "redux/classes/Field";
import Session from "redux/classes/Session";
import NLIConfig from "redux/classes/NLIConfig";

export async function InitializeSession(dispatch: AppDispatch, session: Session) {
    try {
        dispatch(requestStart())
        const emptyPayload = "{}";
        let response: {status: number, data: any} = await dispatch(CallLambda(session, "tableGrabber", emptyPayload));
        if (response.status !== 200) {
            throw new Error(`Error fetching data from the server (${response.status})`);
        }
        const data = response.data;
        const userID = data.userID;
        const config = data.config;
        const users = data.users.map((value: any) => {
            return new User(
                value['user_id'],
                value['name']
            )
        });
        const models = data.models.map((model: any) => {
            const qa = model.qa.map((qa: any, index: number) => {
                return new QA(
                    "qa_" + index,
                    qa['Type'],
                    qa['Category'],
                    qa['Name'],
                    qa['Description'],
                    qa['Fail Threshold'],
                    qa['Warning Threshold'],
                )
            })

            const parameters = model.parameters.map((parameter: any, index: number) => {
                return new Field(
                    "parameter_" + index,
                    parameter.label,
                    parameter.data_type,
                    parameter.field_type,
                    parameter.options ? parameter.options : [],
                    "",
                    parameter.default ? parameter.default : ""
                )
            })

            const inputs = model.inputs.map((input: any, index: number) => {
                return new Field(
                    "input_" + index,
                    input.label,
                    input.data_type,
                    "",
                    [],
                    input.s3_subfolder ? input.s3_subfolder : "",
                    input.default ? input.default : ""
                )
            })

            const outputs = model.outputs.map((output: any, index: number) => {
                return new Field(
                    "output_" + index,
                    output.file_name,
                    output.data_type,
                    output.label,
                    [],
                    "",
                    ""
                )
            })

            let nliConfig = model.nli_config ? new NLIConfig(model.nli_config.help_text, model.nli_config.examples) : undefined;

            return new Model(
                model.model_id,
                model.owner,
                model.name,
                model.alias,
                model.short_description,
                model.long_description,
                model.created,
                qa,
                parameters,
                inputs,
                outputs,
                model.launch_type,
                nliConfig
            )
        });

        const jobs = data.jobs.map((job: any) => {
            return new Job(
                job.job_id,
                job.model_id,
                job.cust_id,
                job.user_id,
                job.alias,
                job.description,
                job.job_name,
                job.result_name,
                job.task_id,
                job.job_status,
                new Date(job.start_time),
                new Date(job.end_time)
            )
        });

        dispatch(setUserID({ userID: userID}));
        dispatch(setUsers({ users: users }));
        dispatch(setJobs({ jobs: jobs }));
        dispatch(setModels({ models: models }));
        if (config?.chatUrl) {
            dispatch(setChatUrl({ chatUrl: config.chatUrl }));
        }

        dispatch(requestCompleted())
    }
    catch {
        dispatch(requestFailure())
        message.error("Username or password does not match");
    }
}
