export default function FailedIcon() {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.25 9.75L5.25 6.75L8.5 9.75L9.25 9L6 6L9.25 3L8.5 2.25L5.25 5.25L2.25 2.25L1.5 3L4.5 6L1.5 9L2.25 9.75Z"
        fill="#F20F01"
      />
    </svg>
  );
}
