import { HeadinghWrapper } from "./styles";

const Heading = ({ children, className, title }: any) => {
  return (
    <HeadinghWrapper>
      <h2 className={className} title={title}>{children}</h2>
    </HeadinghWrapper>
  );
};

export default Heading;
