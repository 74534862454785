import ButtonVelocity from "components/assets/utilities/ButtonVelocity";
import JobStatus from "components/icons/JobStatus";
import { Link } from "react-router-dom";
import Job from "redux/classes/Job";
import Model from "redux/classes/Model";

let modelNames: string[] = []
let ownerIDs: string[] = []

export const columns = (
  modelSelector: (name: string) => void,
  jobSelector: (jobID: string) => void,
  models: Model[],
  jobs: Job[],
  userID: string,
) => [
  {
    title: <span title='Job Alias (32 characters max)'>Alias</span>,
    dataIndex: "jobAlias",
    key: "jobAlias",
    width: 200,
  },
  {
    title: <span title='Service Name (links to info page)'>Service</span>,
    dataIndex: "model",
    key: "model",
    width: 150,
    render: (text: any, record: any) => {
      return (
        <Link
          to={`/${record.alias}/info`}
          onClick={() => { modelSelector(text) }}
        >
          <span title={"Links to info page for " + record.model}>{text}</span>
        </Link>
      )
    },
    filters: jobs.filter(job => {
      modelNames = []
      return job.userID === userID
    }).filter(job => {
      const newModel = !modelNames.includes(job.getModelName(models)!)
      modelNames.push(job.getModelName(models)!)

      return newModel
    }).map(job => {
      return {
        text: job.getModelName(models)!,
        value: job.getModelName(models)!,
        created: job.getModelDate(models)!
      }
    }).sort((a, b) => {
      return a.created > b.created ? -1 : 1
    }),
    onFilter: (value: string, record: any) => record.model === value
  },
  {
    title: <span title='Job ID (links to Job Page)'>Job</span>,
    dataIndex: "job",
    key: "job",
    width: 125,
    render: (text: any, record: any) => {
      return (
        <Link
          to={`/${record.alias}/job/${record.jobID}`}
          onClick={() => {
            modelSelector(record.model)
            jobSelector(record.jobID)
          }}
        >
          <span title={"Links to Job Page for " + record.job}>{text}</span>
        </Link>
      )
    },
  },
  {
    title: <span title='Result ID (links to Results Page)'>Results</span>,
    dataIndex: "result",
    key: "result",
    width: 125,
    render: (text: any, record: any) => {
      if (text === "...") {
        return text;
      }
      else {
        return (
          <Link
            to={`/${record.alias}/results/${record.jobID}`}
            onClick={() => {
              modelSelector(record.model)
              jobSelector(record.jobID)
            }}
          >
            <span title={"Links to Results Page for " + record.result}>{text}</span>
          </Link>
        )
      }
    },
  },
  {
    //title: "Status",
    title: <span title='Job Status [Running, Review, Published, Failed]'>Status</span>,
    key: "status",
    dataIndex: "status",
    width: 100,
    align: "center",
    render: (status: any) => <JobStatus content={status} type="job"/>,
    filters: [
      {
        text: "Running",
        value: "RUNNING",
      },
      {
        text: "Review",
        value: "REVIEW",
      },
      {
        text: "Published",
        value: "PUBLISHED",
      },
      {
        text: "Failed",
        value: "FAILED",
      },
    ],
    onFilter: (value: string, record: any) => record.status.indexOf(value) === 0
  },
];

export const InventoryColumns = (
  modelSelector: (name: string) => void,
  jobSelector: (jobID: string) => void,
  models: Model[]
) => [
  {
    title: <span title='Service Name (links to info page)'>Service</span>,
    dataIndex: "model",
    key: "model",
    width: 200,
    render: (text: any, record: any) => {
      return (
        <Link
          to={`/${record.modelAlias}/info`}
          onClick={() => { modelSelector(text) }}
        >
          <span title={"Links to Model Page for " + record.model}>{text}</span>
        </Link>
      )
    },
  },
  {
    title: <span title='Model Alias (8 characters max)'>Alias</span>,
    dataIndex: "modelAlias",
    key: "modelAlias",
    width: 160,
  },
  {
    title: <span title='Summarized Model Description'>Description</span>,
    dataIndex: "shortDescription",
    key: "shortDescription",
    width: 390,
  },
  {
    title: <span title='Date model was enabled through VELOCITY'>Created</span>,
    dataIndex: "created",
    key: "created",
    width: 130,
  },
  {
    title: <span title='Owner of the model'>Owner</span>,
    dataIndex: "owner",
    key: "owner",
    width: 160,
    filters: models.filter(model => {
      const newOwner = !ownerIDs.includes(model.owner)
      ownerIDs.push(model.owner)

      return newOwner
    }).map(model => {
      ownerIDs = []
      return {
        text: model.owner,
        value: model.owner
      }
    }).sort((a, b) => {
      return a.text! > b.text! ? 1 : -1
    }),
    onFilter: (value: string, record: any) => record.owner === value
  },
  // {
  //   title: <span title="Links to the Result Page of the latest published job for this model">Latest Published Result</span>,
  //   dataIndex: "lastResult",
  //   key: "lastResult",
  //   width: 230,
  //   render: (text: any, record: any) => {
  //     if (text === "...") {
  //       return text;
  //     } else {
  //       return (
  //         <Link
  //           to={`/${record.modelAlias}/results/${record.lastResultTime}`}
  //           onClick={() => {
  //             modelSelector(record.model)
  //             jobSelector(record.lastResultID)
  //           }}
  //         >
  //           <span title={"Links to Results Page for " + record.lastResult}>{text}</span>
  //         </Link>
  //       )
  //     }
  //   },
  // },
  {
    title: <span title="Interact with this model">Action</span>,
    key: "action",
    dataIndex: "action",
    width: 150,
    align: "center",
    render: (text: any, record: any) => (
      <>
        <ButtonVelocity
          type="primary"
          title={"Create a new job for " + record.model}
          path={`/${record.modelAlias}/run`}
          onClick={() => { modelSelector(record.model) }}
        >
          {text}
        </ButtonVelocity>
      </>
    ),
  },
];