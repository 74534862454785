import styled from "styled-components";

export const ResultWrapper = styled.div`
  .right {
    float:right;
  }
  .shifted-down {
    margin-top: 25.66px;
  }

  p {
    font-size: 16px;
  }

  .ant-select-arrow {
    color: #5c5c5c !important;
  }
`;
