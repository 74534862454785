export default function AlertIcon(props) {
  return (
    <svg
      width="69"
      height="69"
      viewBox="0 0 69 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="69" height="69" rx="34.5" fill="#DDEFFD" />
      <path
        d="M35.02 43.53C32.69 43.53 30.36 43.16 28.15 42.42C27.31 42.13 26.67 41.54 26.39 40.77C26.1 40 26.2 39.15 26.66 38.39L27.81 36.48C28.05 36.08 28.27 35.28 28.27 34.81V31.92C28.27 28.2 31.3 25.17 35.02 25.17C38.74 25.17 41.77 28.2 41.77 31.92V34.81C41.77 35.27 41.99 36.08 42.23 36.49L43.37 38.39C43.8 39.11 43.88 39.98 43.59 40.77C43.3 41.56 42.67 42.16 41.88 42.42C39.68 43.16 37.35 43.53 35.02 43.53ZM35.02 26.67C32.13 26.67 29.77 29.02 29.77 31.92V34.81C29.77 35.54 29.47 36.62 29.1 37.25L27.95 39.16C27.73 39.53 27.67 39.92 27.8 40.25C27.92 40.59 28.22 40.85 28.63 40.99C32.81 42.39 37.24 42.39 41.42 40.99C41.78 40.87 42.06 40.6 42.19 40.24C42.32 39.88 42.29 39.49 42.09 39.16L40.94 37.25C40.56 36.6 40.27 35.53 40.27 34.8V31.92C40.27 29.02 37.92 26.67 35.02 26.67Z"
        fill="#3D9CF5"
      />
      <path
        d="M36.88 26.94C36.81 26.94 36.74 26.93 36.67 26.91C36.38 26.83 36.1 26.77 35.83 26.73C34.98 26.62 34.16 26.68 33.39 26.91C33.11 27 32.81 26.91 32.62 26.7C32.43 26.49 32.37 26.19 32.48 25.92C32.89 24.87 33.89 24.18 35.03 24.18C36.17 24.18 37.17 24.86 37.58 25.92C37.68 26.19 37.63 26.49 37.44 26.7C37.29 26.86 37.08 26.94 36.88 26.94Z"
        fill="#3D9CF5"
      />
      <path
        d="M35.02 45.81C34.03 45.81 33.07 45.41 32.37 44.71C31.67 44.01 31.27 43.05 31.27 42.06H32.77C32.77 42.65 33.01 43.23 33.43 43.65C33.85 44.07 34.43 44.31 35.02 44.31C36.26 44.31 37.27 43.3 37.27 42.06H38.77C38.77 44.13 37.09 45.81 35.02 45.81Z"
        fill="#3D9CF5"
      />
    </svg>
  );
}
