import { useEffect, useRef, useState } from 'react';
import { useViewModel } from "mvvm/View";
import { QuestionViewModel } from "../ViewModels/QuestionViewModel";
import TextArea from "antd/es/input/TextArea";
import { CopyIcon } from "components/icons/CopyIcon";
import { PasteIcon } from "components/icons/PasteIcon";
import { MenuOutlined, DeleteOutlined } from '@ant-design/icons';
import { notification } from "antd";

export function QuestionView(props: { model: QuestionViewModel }) {
    const model = useViewModel(props.model);

    const textAreaRef = useRef<any>(null);
    const [isScrolling, setIsScrolling] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMouseOnMenu, setIsMouseOnMenu] = useState(false);
    const [isMouseOnCopy, setIsMouseOnCopy] = useState(false);
    const [isMouseOnPaste, setIsMouseOnPaste] = useState(false);
    const [isMouseOnClear, setIsMouseOnClear] = useState(false);

    const [notify, contextHolder] = notification.useNotification();


    const clearContent = () => {
        model.clearContent();

        const textArea = document.getElementById('question-input') as HTMLTextAreaElement;
        textArea.focus();
    }

    const copyContent = () => {
        navigator.clipboard.writeText(model.inputText);

        notify.info({
            message: "Request copied to clipboard",
            placement: "top"
          })

        const textArea = document.getElementById('question-input') as HTMLTextAreaElement;
        textArea.select();
    }

    const pasteContent = () => {
        navigator.clipboard.readText().then(clipText => {
            const textArea = document.getElementById('question-input') as HTMLTextAreaElement;
            const start = textArea.selectionStart;
            const end = textArea.selectionEnd;
            const text = model.inputText;
            const before = text.substring(0, start);
            const after = text.substring(end, text.length);
            model.inputText = before + clipText + after;
            setTimeout(() => {
                textArea.selectionStart = textArea.selectionEnd = start + clipText.length;
                textArea.focus();
            }, 0);
        });
    }

    useEffect(() => {
        const checkScroll = () => {
            const textArea = textAreaRef.current?.resizableTextArea?.textArea;
            if (textArea) {
                setIsScrolling(textArea.scrollHeight > textArea.clientHeight);
            }
        };

        checkScroll();
        window.addEventListener('resize', checkScroll);

        return () => {
            window.removeEventListener('resize', checkScroll);
        };
    }, [model.inputText]);

    return (
        <>
            {contextHolder}
            <div className="flex-1 flex-column" style={{ position: "relative" }}>
                <div className="flex-row" style={{ margin: "0.5em 0" }}>
                    <div className="flex-1" style={{ paddingLeft: "0.2em" }}><b>Request</b></div>
                    <button className="small-secondary-btn" style={{ margin: "0 0.2em", width: "9em" }} disabled={!model.canClear} onClick={() => model.reset()}>Clear</button>
                    <button className="small-primary-btn" style={{ margin: "0 0.2em", width: "9em" }} disabled={!model.canSubmit} onClick={() => model.submit()}>{model.submitButtonCaption}</button>
                </div>

                <TextArea
                    ref={textAreaRef}
                    className={"flex-1 text-content question-input"}
                    id="question-input"
                    disabled={model.isSubmitting}
                    value={model.inputText}
                    onChange={e => {
                        model.inputText = e.target.value;
                        const textArea = textAreaRef.current?.resizableTextArea?.textArea;
                        if (textArea) {
                            setIsScrolling(textArea.scrollHeight > textArea.clientHeight);
                        }
                    }}
                    placeholder="Enter your request..."
                />

                <button
                    className={"menu-icon " + (isScrolling ? "scrolling " : "")}
                    onMouseEnter={() => setIsMouseOnMenu(true)}
                    onMouseLeave={() => setIsMouseOnMenu(false)}
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                >
                    <MenuOutlined style={{ fontSize: "1.25em", color: isMenuOpen ? "#007acc" : isMouseOnMenu ? "#666666" : "#bbbbbb" }} />
                </button>

                <div className={"copy-icon " + (isMenuOpen ? "shown " : "") + (isScrolling ? "scrolling " : "")}>
                    <button
                        onMouseEnter={() => setIsMouseOnCopy(true)}
                        onMouseLeave={() => setIsMouseOnCopy(false)}
                        onClick={() => copyContent()}
                        disabled={model.inputText.length === 0}
                        style={{ fontSize: "0.8em", border: "none", background: "none", padding: 0 }}
                    >
                        <CopyIcon size={16} hovering={isMouseOnCopy}/>
                    </button>
                </div>

                <div className={"paste-icon " + (isMenuOpen ? "shown " : "") + (isScrolling ? "scrolling " : "")}>
                    <button
                        onMouseEnter={() => setIsMouseOnPaste(true)}
                        onMouseLeave={() => setIsMouseOnPaste(false)}
                        onClick={() => pasteContent()}
                        style={{ fontSize: "0.8em", border: "none", background: "none", padding: 0 }}
                    >
                        <PasteIcon size={16} hovering={isMouseOnPaste}/>
                    </button>
                </div>

                <div className={"clear-icon " + (isMenuOpen ? "shown " : "") + (isScrolling ? "scrolling " : "")}>
                    <button
                        onMouseEnter={() => setIsMouseOnClear(true)}
                        onMouseLeave={() => setIsMouseOnClear(false)}
                        onClick={() => clearContent()}
                        disabled={model.inputText.length === 0}
                        style={{ fontSize: "0.8em", border: "none", background: "none", padding: 0 }}
                    >
                        <DeleteOutlined style={{ fontSize: "1.25em", color: isMouseOnClear ? "#666666" : "#bbbbbb" }} />
                    </button>
                </div>
            </div>
        </>
    )
}