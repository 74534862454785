import styled from "styled-components";

export const ChartDataWrapper = styled.div`
  react {
    fill: red !important;
  }

  .slick-dots li button {
    background: #adadad;
  }
    
  .slick-dots li.slick-active button {
    background: #5c5c5c;
  }

  .centered {
    align-items: center;
    display: flex !important;
    justify-content: center;
  }

  .option {
    width: auto;
    padding-right: 10px;
    float: left;
    margin: -2.5px auto -2.5px -12px;
  }

  .ant-select-selection-item {
    font-family: "PoppinsBold", sans-serif !important;
    font-size: 32px;
    font-weight: 600;
    line-height: 35.2px !important;
  }

  .ant-select-arrow {
    color: #5c5c5c !important;
  }

  .down {
    margin-top: 100px;
  }

  .right {
    margin-right: 16px
  }

  .flow {
    max-height: 700px
  }

  .centered {
    justify-content: center;
  }
`;
