import { Card, Checkbox, Col, Form, Input, Row, Select } from "antd";
import SecondaryHeading from "components/assets/text/SecondaryHeading";
import { TacticsWrapper } from "./styles";
import Field from "redux/classes/Field";
import { useAppSelector } from "redux/hooks";
import { useEffect, useState } from "react";

const Tactics = () => {
  const app = useAppSelector(state => state.app)
  const user = useAppSelector(state => state.user)
  const modelInfo = user.models[user.modelIndex];
  const fields = modelInfo.parameters;

  const [form] = Form.useForm();

  const [selectedPercentage, setSelectedPercentage] = useState(Array(fields.length).fill(0));
  const [newSpend, setNewSpend] = useState(Array(fields.length).fill(0));

  useEffect(() => {
    let newSpendCopy = [...newSpend];
    fields.forEach((field: Field, index: number) => {
      newSpendCopy[index] = (
        parseInt(field.defaultValue.replace(/[$,]/g, ''), 10) *
        (1 + selectedPercentage[index] / 100)
      ).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0
      });
    });
    setNewSpend(newSpendCopy);
  }, [selectedPercentage]);

  return (
    <TacticsWrapper>
      <Card className="height-control">
        <div
          className="tactics-title"
        >
          <SecondaryHeading title="Specify the Tactics that should be used in your job">Tactics</SecondaryHeading>

          <Select
            defaultValue="Tactic"
            className="tactics-title-select"
          >
            <Select.Option value="Group">Group</Select.Option>
            <Select.Option value="Subgroup">Subgroup</Select.Option>
            <Select.Option value="Tactic">Tactic</Select.Option>
          </Select>
        </div>

        {fields.length > 0 && (
          <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            requiredMark={false}
          >
            <Row gutter={[64, 12]}>
              {fields.map((field: Field, index: number) => {
                switch (field.fieldType) {
                  case "tactics":
                    return (
                      <Col span={24} key={field.fieldID}>
                        <Row gutter={[12, 12]}>
                          <Col span={6} key={field.fieldID + "_" + 1}>
                            <Form.Item
                              name={field.label}
                            >
                              <div
                                className="tactics-form-item"
                              >
                                <Checkbox
                                  defaultChecked={true}
                                  disabled={app.submitting}
                                />
                                <div
                                  className="tactics-form-item-label"
                                >
                                  {field.label}
                                </div>
                              </div>
                            </Form.Item>
                          </Col>
                          <Col span={6} key={field.fieldID + "_" + 1}>
                            <label
                              className="tactics-form-cell-label"
                              htmlFor={`input-${field.fieldID + "_" + 1}`}
                            >
                              Current Spend
                            </label>
                            <Input
                              id={`input-${field.fieldID + "_" + 1}`}
                              disabled={true}
                              defaultValue={field.defaultValue}
                            />
                          </Col>
                          <Col span={6} key={field.fieldID + "_" + 2}>
                            <label
                              className="tactics-form-cell-label"
                              htmlFor={`dropdown-${field.fieldID + "_" + 2}`}
                            >
                              % Change
                            </label>
                            <Select
                              id={`dropdown-${field.fieldID + "_" + 2}`}
                              className="tactics-form-cell-select"
                              options={field.options.map((option: any) => ({
                                label: option,
                                value: option
                              }))}
                              placeholder={"Select one"}
                              showSearch
                              disabled={app.submitting}
                              defaultValue={"0%"}
                              onChange={(value: string) => {
                                let selectedPercentageCopy = [...selectedPercentage];
                                selectedPercentageCopy[index] = parseInt(value.replace("%", ""));
                                setSelectedPercentage(selectedPercentageCopy);
                              }}
                            />
                          </Col>
                          <Col span={6} key={field.fieldID + "_" + 3}>
                            <label
                              className="tactics-form-cell-label"
                              htmlFor={`input-${field.fieldID + "_" + 3}`}
                            >
                              New Spend
                            </label>
                            <Input
                              id={`input-${field.fieldID + "_" + 3}`}
                              disabled={true}
                              value={newSpend[index]}
                            />
                          </Col>
                        </Row>
                      </Col>
                    )
                  default:
                    return <></>
                }
              })}
            </Row>
          </Form>
        )}
      </Card>
    </TacticsWrapper>
  );
};

export default Tactics;
