import { Col, Row } from "antd";
import { columns, InventoryColumns } from "components/pages/Home/columns";
import { HomeWrapper } from "./styles";
import StatsCard from "components/panels/Home/StatsCard";
import ModelIcon from "components/icons/ModelIcon";
import InsightIcon from "components/icons/InsightsIcon";
import AlertIcon from "components/icons/AlertIcon";
import TableVelocity from "components/assets/utilities/TableVelocity";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setModelIndex, setJobIndex, setOutput, setQA, setForms, setConfig, setFiles } from "redux/slices/user"
import Model from "redux/classes/Model";
import Job from "redux/classes/Job";
import { RefreshAction } from "redux/actions/Refresh";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { endSubmitting, requestCompleted, requestStart } from "redux/slices/app";
import { useSession } from "components/wrappers/SessionProvider";

const Home = () => {
  const dispatch = useAppDispatch();
  const app = useAppSelector((state) => state.app);
  const user = useAppSelector((state) => state.user);
  const session = useSession();

  useEffect(() => {
    dispatch(setConfig({ reset: true }));
    dispatch(setQA({ qa: [] }));
    dispatch(setOutput({ output: [] }));
    dispatch(setForms({ forms: [] }));
    dispatch(setFiles({ files: [] }));
    dispatch(endSubmitting());
    dispatch(requestCompleted());
  }, [])

  const prepareJobs = (jobs: Job[], models: Model[]) => {
    return jobs.map(job => {
      return {
        key: job.jobID,
        model: job.getModelName(models),
        alias: job.getModelAlias(models),
        jobAlias: job.alias,
        job: job.jobName,
        jobID: job.jobID,
        jobTime: job.jobName.substring(0, 10) + '_' + job.jobName.substring(11, 16),
        result: job.status !== 'RUNNING' ? job.resultName : "...",
        resultTime: job.resultName ?
          job.resultName.substring(0, 10) + '_' + job.resultName.substring(11, 16) :
          "",
        status: job.status,
        user: job.userID
      }
    }).filter(job => {
      return job.user === user.userID
    }).sort((job_a, job_b) => {
      let jobTimeA = job_a.jobTime.toLowerCase()
      let jobTimeB = job_b.jobTime.toLowerCase()

      if (jobTimeA > jobTimeB)
          return -1
      if (jobTimeA < jobTimeB)
          return 1
      return 0
  })
  }

  const prepareModels = (models: Model[], jobs: Job[]) => {
    return models.map(model => {
      const lastResults = jobs.filter(job => {
        return (
          job.modelID === model.modelID &&
          job.resultName &&
          job.status === "PUBLISHED"
        )
      })

      return {
        key: model.modelID,
        model: model.name,
        modelAlias: model.alias,
        shortDescription: model.shortDesc,
        created: model.created,
        owner: model.owner,
        lastResult: lastResults.length > 0 ? lastResults[0].resultName : "...",
        lastResultTime: lastResults.length > 0 ?
          lastResults[0].resultName.substring(0, 10) + '_' + lastResults[0].resultName.substring(11, 16) :
          "...",
        lastResultID: lastResults.length > 0 ? lastResults[0].jobID : "...",
        action: ["Run Model"]
      }
    }).sort((a, b) => {
      return a.model < b.model ? -1 : 1
    })
  }

  const selectModel = (name: string) => {
    dispatch(requestStart())
    dispatch(setModelIndex({ modelName: name }))
  }

  const selectJob = (jobID: string) => {
    dispatch(requestStart())
    dispatch(setJobIndex({ jobID: jobID }))
  }

  const timeToInsights = () => {
    let time = 0
    let count = 0

    user.jobs.filter(job => {
      return job.status !== "FAILED" && job.status !== "RUNNING"
    }).forEach(job => {
      time += (job.endTime.getTime() - job.startTime.getTime()) / 1000
      count++
    })

    const secsPerInsight = Math.floor(time / count)
    const minsPerInsight = Math.floor(secsPerInsight / 60)
    const hoursPerInsight = Math.floor(minsPerInsight / 60)

    // Return the average time to insights to one degree of precision (either seconds, minutes, or hours)
    return count === 0 ? "..." : hoursPerInsight > 0 ? hoursPerInsight + "h" : minsPerInsight > 0 ? minsPerInsight + "m" : secsPerInsight + "s"
  }

  return (
    <HomeWrapper>
      <Row gutter={20}>
        <Col xs={24} sm={24} md={6}>
          <div className="stats-container">
            <StatsCard
              title="Number of Services"
              description="Number of services you have access to through VELOCITY"
              stats={user.models.length}
              icon={<ModelIcon />}
            />
            <StatsCard
              title="Number of Jobs"
              description="Number of jobs you have invoked through VELOCITY"
              stats={!app.loading ? user.jobs.filter(job => job.userID === user.userID).length : "..."}
              icon={<AlertIcon />}
            />
            <StatsCard
              title="Average Time to Answer"
              description="Average time to answer across all your jobs"
              stats={timeToInsights()}
              icon={<InsightIcon />}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={18}>
          <div className="table-container">
            <TableVelocity
              title="My Jobs"
              description="List of all your model runs"
              columns={columns(selectModel, selectJob, user.models, user.jobs, user.userID)}
              data={prepareJobs(user.jobs, user.models)}
              vh="269px"
              loading={app.loading && user.jobs.length === 0}
              refreshHandler={async () => {
                dispatch(requestStart())
                await dispatch(RefreshAction(session))
                dispatch(requestCompleted())
              }}
              path={useLocation().pathname}
            />
          </div>
        </Col>
        <Col md={24} className="mt-24">
          <TableVelocity
            title="Operations Center"
            description="List of all services you have access to through VELOCITY"
            columns={InventoryColumns(selectModel, selectJob, user.models)}
            data={prepareModels(user.models, user.jobs)}
            vh="400px"
          />
        </Col>
      </Row>
    </HomeWrapper>
  );
};

export default Home;
