import { Select } from "antd";
import React, { useState, useEffect } from "react";

interface OptionItem {
  value: string;
  label: string;
}

interface MultiSelectProps {
  options: OptionItem[];
  placeholder: string;
  changeHandler: Function;
  disabled?: any;
  values?: any;
}

const MultiSelect: React.FC<MultiSelectProps> = ({ options, placeholder, changeHandler, disabled, values }) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  useEffect(() => {
    // Check if all options are selected
    const allOptionValues = options.map((option) => option.value);
    const isAllSelected = allOptionValues.every((value) =>
      selectedOptions.includes(value)
    );
    setSelectAllChecked(isAllSelected);
  }, [options, selectedOptions]);

  const handleChange = (selectedValues: string[], changeHandler: Function) => {
    if (selectedValues.includes("select-all")) {
      if (selectAllChecked) {
        // If "Select All" is checked and all options are selected, deselect all
        setSelectedOptions([]);
        changeHandler([])
      } else {
        // Otherwise, select all options
        const allOptionValues = options.map((option) => option.value);
        setSelectedOptions(allOptionValues);
        changeHandler(allOptionValues)
      }
    } else {
      // Remove "Select All" from the selected options if present
      const updatedSelectedValues = selectedValues.filter(
        (value) => value !== "select-all"
      );

      setSelectedOptions(updatedSelectedValues);
      changeHandler(updatedSelectedValues);
    }
  };

  return (
    <Select
      className="multi"
      mode="multiple"
      value={values ? values : selectedOptions}
      onChange={selectedValues => {
        handleChange(selectedValues, changeHandler)
      }}
      placeholder={placeholder}
      options={[
        {
          value: "select-all",
          label: selectAllChecked ? "Deselect All" : "Select All",
        },
        ...options,
      ]}
      disabled={disabled ? true : false}
      maxTagCount={'responsive'}
    />
  );
};

export default MultiSelect;
