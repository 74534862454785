import styled from "styled-components";

export const ModelInfoWrapper = styled.div`
  .card-text {
    color: #949596;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%;
  }
  .card-sub-heading {
    color: #949596 !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 0px;
  }
  .card-sub-text {
    color: #1c1c1c;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin: 0;
  }
  .color-primary {
    margin-bottom: 5px;
  }
  .description {
    margin-top: 14px;
    margin-bottom: 5px;
  }
  .description-text {
    line-height: 170%;
  }
`;
