import styled from "styled-components";

export const TableVhWrapper = styled.div`
  .ant-input-affix-wrapper {
    background-color: #fafafa;
    height: 40px;
    border: none;
    border-radius: 4px;
    width: 239px;
  }
  .ant-input {
    background-color: #fafafa;
    font-family: "PoppinsRegular", sans-serif !important;
  }

  .card-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    .card-heading {
      display: flex;
      align-items: center;
      gap: 17px;
    }
  }
  .card-section {
    border-radius: 8px;
    overflow: hidden;
  }
  .card-section .ant-card-body {
    padding: 0px;
    border-radius: 0 0 8px 8px;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
      rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 10px;
  }
  a {
    color: #3d9cf5;
  }

  .option {
    width: auto;
    padding-right: 20px;
    float: left;
    margin: 0 auto auto -12px;
  }

  .ant-select-selection-item {
    font-family: "PoppinsBold", sans-serif !important;
    font-size: 32px;
    font-weight: 600;
    line-height: 35.2px !important;
  }

  .ant-table-thead .ant-table-cell {
    text-align: left !important;
    white-space: pre
  }

  .job .ant-table-body {
    min-height: 265px;
    height: 265px;
  }

  .formatted {
    white-space: pre;
  }

  .flex {
    display: flex;
  }

  .subtitle {
    position: absolute;
    font-family: "PoppinsRegular", sans-serif !important;
    left: 50%;
    top: 65px;
    transform: translate(-50%, 0);
    font-size: 14px;
    color: #949596 !important;
    white-space: pre;
  }

  .ant-table-placeholder {
    height: 265px;
  }
`;
