import { Row, Col } from "antd";
import InputFilesDisabled from "components/panels/Job/InputFilesDisabled";
import InfoPanelDisabled from "components/panels/Job/InfoPanelDisabled";
import ParametersDisabled from "components/panels/Job/ParametersDisabled";
import ConstraintsDisabled from "components/panels/Job/ConstraintsDisabled";
import ButtonVelocity from "components/assets/utilities/ButtonVelocity";
import { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DeleteJobAction } from "redux/actions/DeleteJob";
import { LoadJobAction } from "redux/actions/LoadJob";
import { LoadResultAction } from "redux/actions/LoadResult";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { requestCompleted, requestFailure, requestStart, startSubmitting } from "redux/slices/app";
import { useSession } from "components/wrappers/SessionProvider";
import Field from "redux/classes/Field";

const JobPage = () => {
  const dispatch = useAppDispatch();
  const app = useAppSelector(state => state.app);
  const user = useAppSelector(state => state.user);
  const pageLocation = useLocation().pathname;
  const modelInfo = user.models[user.modelIndex];
  const jobInfo = user.jobs[user.jobIndex];
  const navigate = useNavigate();
  const loadJob = useRef(false);
  const session = useSession();

  useEffect(() => {
    dispatch(LoadJobAction(session, jobInfo.jobID, modelInfo.alias))
    loadJob.current = true;
  }, [])

  useEffect(() => {
    if (loadJob.current) {
      if (jobInfo.status !== "RUNNING") {
        const getOutput = async () => {
          await dispatch(LoadResultAction(session, jobInfo.jobID, modelInfo.alias, user.config.outputs, user.jobs[user.jobIndex].status))
          dispatch(requestCompleted());
        }
        getOutput().catch(() => dispatch(requestFailure()));
      }
      else {
        dispatch(requestCompleted());
      }
    }
  }, [user.config])

  return (
    <>
      {app.loading ? <div className="loader-centered"/> :
        <>
          <Row gutter={20} className="mt-24">
            <Col md={24} className="input-container">
              <InfoPanelDisabled
                values={user.config ? user.config.meta : []}
              />
            </Col>
          </Row>

          {modelInfo.inputs.length > 0 &&
            <Row>
              <Col md={24}>
                <InputFilesDisabled
                  values={user.config ? user.config.inputs : []}
                />
              </Col>
            </Row>
          }

          {modelInfo.parameters.length > 0 &&
            <Row gutter={20} className="mt-24">
              <Col md={24} className="input-container">
                {modelInfo.parameters.length > 0 &&
                  <ParametersDisabled
                    values={user.config ? user.config.parameters : []}
                  />
                }
              </Col>
            </Row>
          }

          {modelInfo.parameters.filter((field: Field) => field.fieldType === "dropdowns").length > 0 &&
            <Row gutter={20} className="mt-24">
              <Col md={24} className="input-container">
                <ConstraintsDisabled
                  values={user.config ? user.config.parameters : []}
                />
              </Col>
            </Row>
          }

          <Row>
            <Col md={24} className="mt-24">
              <div className="text-end mt-24">
                <ButtonVelocity
                  type="delete"
                  title={jobInfo.status === "RUNNING" ? "Kill this Job" : "Delete this Job (associated files will not be deleted from S3)"}
                  path={pageLocation}
                  onClick={async () => {
                    dispatch(requestStart());
                    dispatch(startSubmitting());
                    await dispatch(DeleteJobAction(session, jobInfo.jobID, jobInfo.taskID));
                    navigate(`/${modelInfo.alias}/info`)
                  }}
                  submitting={app.submitting}
                >
                  Delete Job
                </ButtonVelocity>
              </div>
            </Col>
          </Row>
        </>
      }
    </>
  );
};

export default JobPage;
