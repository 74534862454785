export default class Config {
    meta: {[key: string]: string};
    parameters: {[key: string]: any};
    inputs: {[key: string]: string};
    outputs: {[key: string]: string};

    constructor(meta: {[key: string]: string}, parameters: {[key: string]: any}, inputs: {[key: string]: string}, outputs: {[key: string]: string}) {
        this.meta = meta;
        this.parameters = parameters;
        this.inputs = inputs;
        this.outputs = outputs;
    }

    makeJSON() {
        return JSON.stringify({
            "meta": this.meta,
            "parameters": this.parameters,
            "inputs": this.inputs,
            "outputs": this.outputs
        })
    }
}
