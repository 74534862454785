import styled from "styled-components";

export const MarkdownViewerWrapper = styled.div`
.hljs, .hljs-operator {
    color: #CCCCCC !important;
  }
  
  .hljs {
    background: #1F1F1F;
    
  }
  
  .hljs-string {
    color: #CE9178 !important
  }
  
  .hljs-keyword, .hljs-type {
    color: #57aeec !important;
  }
  
  .hljs-built_in {
    color: #DCDC8B !important;
  }
  
  .hljs::selection, .hljs ::selection {
    background: #264F78 !important;
    color: white !important;
  }

  table {
    border-collapse: collapse;
    margin: 1em;
  }
  
  th, td {
    border: 1px solid #CCCCCC;
    text-align: left;
    padding: 0.25em 1em

  }  
  `;