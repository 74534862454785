import FailedIcon from "components/icons/FailedIcon";
import PassedIcon from "components/icons/PassedIcon";
import PublishedIcon from "components/icons/PublishedIcon";
import ReviewIcon from "components/icons/ReviewIcon";
import RunIcon from "components/icons/RunIcon";
import WarningIcon from "components/icons/WarningIcon";
import { JobStatusWrapper } from "./styles";

const JobStatus = ({ content, type }: any) => {
  let icon = null;
  let className = "status ";
  let title = "";

  switch (content) {
    case "RUNNING":
      icon = <RunIcon />;
      className += "running-status";
      title = "Job is running";
      break;
    case "FAILED":
      icon = <FailedIcon />;
      className += "review-status ";
      title = type === "job" ?
        "Job did not sucessfully execute":
        "QA check failed";
      break;
    case "REVIEW":
      icon = <ReviewIcon />;
      className += "review-status ";
      title = "Job is finished and results are ready for review";
      break;
    case "PUBLISHED":
      icon = <PublishedIcon />;
      className += "published-status";
      title = "Job has been published";
      break;
    case "PASSED":
      icon = <PassedIcon />;
      className += "passed-status";
      title = "QA check passed";
      break;
    case "WARNING":
      icon = <WarningIcon />;
      className += "warning-status";
      title = "QA check may require further review";
      break;
  }

  return (
    <JobStatusWrapper>
      <div className={className} title={title}>
        {icon} {content}
      </div>
    </JobStatusWrapper>
  );
};

export default JobStatus;