import { Col, Row } from "antd";
import Visualizations from "components/assets/utilities/Visualizations";
import { useAppSelector } from "redux/hooks";

const ModelFlowPage = () => {
  const user = useAppSelector((state) => state.user);
  const app = useAppSelector((state) => state.app);

  return (
    <>
      {app.loading ? <div className="loader-centered"/> :
        <Row>
          <Col md={24}>
            <Visualizations
              visuals={user.flow !== "" ? [{
                label: "Model Flow",
                result: user.flow
              }] : []}
              flow
            />
          </Col>
        </Row>
      }
    </>
  );
};

export default ModelFlowPage;
