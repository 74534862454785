import axios from "axios";
import Session from "redux/classes/Session";
import { requestFailure, sendError } from "redux/slices/app";
import { AppDispatch } from "redux/store";

export function CallLambda(session: Session, lambda: string, payload: any) {
    const buildResponse = (status: number, data: any) => {
        return {
            status: status,
            data: data
        }
    }

    const handleError = (dispatch: AppDispatch, error: any) => {
        console.log(error);
        dispatch(requestFailure());
        dispatch(sendError({ message: "API Error - " + lambda }));
        return buildResponse(400, error)
    }

    return async (dispatch: AppDispatch) => {
        try {
            const invoke_url = `${session.apiBaseUrl}/${lambda}`;
            const headers = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${session.accessToken}`,
                    'Accept': 'application/json'
                }
            }

            const response = await axios.post(invoke_url, payload, headers);

            if (response.status === 200) {
                return buildResponse(200, response.data);
            }
            else {
                return handleError(dispatch, response.data.error);
            }
        }
        catch (error) {
            return handleError(dispatch, error);
        }
    }
}