import { Card, Checkbox, Col, Form, Row } from "antd";
import SecondaryHeading from "components/assets/text/SecondaryHeading";
import { SegmentsWrapper } from "./styles";
import Field from "redux/classes/Field";
import { useAppSelector } from "redux/hooks";

const Segments = () => {
  const app = useAppSelector(state => state.app)
  const user = useAppSelector(state => state.user)
  const modelInfo = user.models[user.modelIndex];
  const fields = modelInfo.parameters;

  const [form] = Form.useForm();

  return (
    <SegmentsWrapper>
      <Card className="height-control">
        <SecondaryHeading title="Specify the Segments that should be used in your job">Segments</SecondaryHeading>

        {fields.length > 0 && (
          <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            requiredMark={false}
          >
            <Row gutter={[64, 12]}>
              {fields.map((field: Field) => {
                switch (field.fieldType) {
                  case "segment":
                    return (
                      <Col span={8} key={field.fieldID}>
                        <Form.Item
                          name={field.label}
                        >
                          <div
                            className="segment-form-item"
                          >
                            <Checkbox
                              defaultChecked={field.defaultValue !== null ? field.defaultValue === "true" : false}
                              disabled={app.submitting}
                            />
                            <div
                              className="segment-form-item-label"
                            >
                              {field.label}
                            </div>
                          </div>
                        </Form.Item>
                      </Col>
                    )
                  default:
                    return <></>
                }
              })}
            </Row>
          </Form>
        )}
      </Card>
    </SegmentsWrapper>
  );
};

export default Segments;
