import { Card } from "antd";
import Heading from "components/assets/text/Heading";
import Paragraph from "components/assets/text/Paragraph";
import { StatsCardWrapper } from "./styles";

const StatsCard = ({ title, stats, icon, description }: any) => {
  return (
    <StatsCardWrapper>
      <Card>
        <div className="stats-card-inner">
          <span>
            <Paragraph className="color-secondary" title={description}>{title}</Paragraph>
            <Heading className="color-heading mt-12">{stats}</Heading>
          </span>
          {icon}
        </div>
      </Card>
    </StatsCardWrapper>
  );
};

export default StatsCard;