import styled from "styled-components";

export const ButtonWrapper = styled.div`
  .btn {
    border-radius: 4px;
    font-size: 13px;
    font-family: "PoppinsRegular", sans-serif !important;
    height: auto;
  }


  .primary-btn {
    background-color: #3d9cf5;
    border: 1px solid #3d9cf5;
    color: white;
    padding: 6px 24px;
    :hover {
      color: #fff !important;
      background: #0c77dc !important;
      box-shadow: 0px 16px 20px 0px rgba(0, 0, 0, 0.15) !important;
      transition: all 0.4s;
    }
  }
  .secondary-btn {
    background-color: transparent;
    border: 1px solid #0c77dc;
    color: #0c77dc;
    padding: 14px 46px;
    width: 161px;
    :hover {
      color: white !important;
      background-color: #0c77dc !important;
      box-shadow: 0px 16px 20px 0px rgba(0, 0, 0, 0.15) !important;
      transition: all 0.4s;
    }
  }
  .result-btn {
    background-color: #3d9cf5;
    border: 1px solid #3d9cf5;
    font-size: 16px;
    font-weight: 400;
    color: white;
    padding: 16px 42px;
    :enabled:hover {
      color: #fff !important;
      background: #0c77dc !important;
      box-shadow: 0px 16px 20px 0px rgba(0, 0, 0, 0.15) !important;
      transition: all 0.4s;
    }
  }
  .delete-btn {
    background-color: #3d9cf5;
    border: 1px solid #3d9cf5;
    font-size: 16px;
    font-weight: 400;
    color: white;
    padding: 16px 42.735px;
    :enabled:hover {
      color: #fff !important;
      background: #0c77dc !important;
      box-shadow: 0px 16px 20px 0px rgba(0, 0, 0, 0.15) !important;
      transition: all 0.4s;
    }
  }
  .refresh-btn {
    background-color: #3d9cf5;
    border: 1px solid #3d9cf5;
    color: white;
    padding: 6px 24px;
    :hover {
      color: #fff !important;
      background: #0c77dc !important;
      box-shadow: 0px 16px 20px 0px rgba(0, 0, 0, 0.15) !important;
      transition: all 0.4s;
    }
  }
  .publish-btn {
    background-color: #3d9cf5;
    border: 1px solid #3d9cf5;
    color: #fff;
    font-size: 16px;
    padding: 6px 24px;
    padding-left: 34.37px;
    padding-right: 34.37px;
    :enabled:hover  {
      color: #fff !important;
      background: #0c77dc !important;
      box-shadow: 0px 16px 20px 0px rgba(0, 0, 0, 0.15) !important;
      transition: all 0.4s;
    }
  }
  .published-btn {
    background: #e6e6e6 !important;
    border: 1px solid #e6e6e6;
    color: #8d8d8d;
    font-size: 16px;
    padding: 6px 24px;
  }
  .download-btn {
    background-color: #3d9cf5;
    border: 1px solid #3d9cf5;
    color: #fff;
    font-size: 16px;
    padding: 6px 24px;
    padding-left: 34.37px;
    padding-right: 34.37px;
    :enabled:hover  {
      color: #fff !important;
      background: #0c77dc !important;
      box-shadow: 0px 16px 20px 0px rgba(0, 0, 0, 0.15) !important;
      transition: all 0.4s;
    }
  }

  .loader-button {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #3d9cf5; /* Blue */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin-button 2s linear infinite;
    margin: -2.43px 26.59px -2.43px 26.59px;
  }
  @keyframes spin-button {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .loader-button-small {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #3d9cf5; /* Blue */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin-button-small 2s linear infinite;
    margin: -2.44px 13.41px -2.44px 13.41px;
  }
  @keyframes spin-button-small {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .constraint-btn {
    background-color: #3d9cf5;
    border: 1px solid #3d9cf5;
    color: white;
    padding: 6px;
    :hover {
      color: #fff !important;
      background: #0c77dc !important;
      box-shadow: 0px 16px 20px 0px rgba(0, 0, 0, 0.15) !important;
      transition: all 0.4s;
    }
  }

  .upload-btn {
    position: absolute;
    right: 32px;
    top: 0px;
    background-color: #3d9cf5;
    border: 1px solid #3d9cf5;
    color: white;
    padding: 2px 24px;
    :hover {
      color: #fff !important;
      background: #0c77dc !important;
      box-shadow: 0px 16px 20px 0px rgba(0, 0, 0, 0.15) !important;
      transition: all 0.4s;
    }
  }
`;