import { useViewModel } from "mvvm/View";
import { PageViewModel } from "../ViewModels/PageViewModel";
import { Dropdown, MenuProps, Popover, Table, TableProps } from "antd";
import { useEffect, useRef } from "react";
import ThumbsUpSolidIcon from "components/icons/ThumbsUpSolidIcon";
import ThumbsDownSolidIcon from "components/icons/ThumbsDownSolidIcon";
import { JobOutputView } from "./JobOutputView";
import { JobOutputViewModel } from "../ViewModels/JobOutputViewModel";


export function PageView(props: { model: PageViewModel }) {
    const jobList = useRef<HTMLDivElement>(null);
    const model = useViewModel(props.model);

    const tableContainerRef = useRef<HTMLDivElement>(null);

    const RenderRating = (text: string) => {
        switch (text) {
            case "up":
                return <ThumbsUpSolidIcon />;
            case "down":
                return <ThumbsDownSolidIcon />;
            default:
                return null;
        }
    }
    
    const RenderOutput = (output: JobOutputViewModel) => {
        return <div style={{whiteSpace: "nowrap", paddingRight: "1em"}}>
            <JobOutputView key={output.jobID} model={output} />
        </div>    
    }

    const RenderNoWrap = (text: string) => {
        return <div style={{whiteSpace: "nowrap"}}>{text}</div>
    }
    
    const columns: TableProps["columns"] = [
        { title: "Rating", dataIndex: "rating", key: "rating", width: "1px", align: "center", render: RenderRating },
        { title: "User", dataIndex: "userName", key: "userName", render: RenderNoWrap, width: "1px"},
        { title: "Date/Time", dataIndex: "dateTime", key: "dateTime", width: "1px",  render: RenderNoWrap},
        { title: "Request", dataIndex: "question", width: "30%", key: "question" },
        { title: "User Feedback", dataIndex: "comment", key: "comment" },
        { title: "Output", dataIndex: "output", key: "actions", width: "1px", render: RenderOutput},
    ];

    const ratingFilterItems: MenuProps['items'] = [
        { key: "all", label: "All" },
        { key: "up", label: "Up" },
        { key: "down", label: "Down" },
    ];

    const ratingFilterHandler: MenuProps['onClick'] = ({ key }) => {
        model.ratingFilter = key === "all" ? undefined : key as "up"|"down";
    }

    const userFilterItems: MenuProps['items'] = [{ key: "all", label: "All" }, ...model.users.map(user => ({ key: user.userID, label: user.name }))];

    const userFilterHandler: MenuProps['onClick'] = ({ key }) => {
        model.userIDFilter = key === "all" ? undefined : key as string;
    }

    return  <div className="flex-1 flex-column" style={{background: "white", padding: "0px"}}>
        <div className="flex-row" style={{padding: "1em 0.5em", borderBottom: "1px solid #e7e7e7"}}>
            <div style={{padding: "0 0.25em"}}>
                <Dropdown menu={{ items: ratingFilterItems, onClick: ratingFilterHandler }} trigger={["click"]} disabled={model.loading}>
                    <button className="small-secondary-btn">Rating: {model.ratingFilterDisplay}</button>
                </Dropdown>
            </div>
            <div style={{padding: "0 0.25em"}}>
                <Dropdown menu={{ items: userFilterItems, onClick: userFilterHandler }} trigger={["click"]} disabled={model.loading}>
                    <button className="small-secondary-btn">User: {model.userFilterDisplay}</button>
                </Dropdown>
            </div>
            <div className="flex-1"></div>
            <div>
                <span style={{marginRight: "1.25em"}}>Page {model.pageNumber}</span>
                <button className="small-secondary-btn" disabled={model.isFirstPage || model.loading} style={{margin: "0 0.25em"}} onClick={()=>model.firstPage()}>&lt;&lt;</button>
                <button className="small-secondary-btn" disabled={model.isFirstPage || model.loading} style={{margin: "0 0.25em"}} onClick={()=>model.previousPage()}>&lt;</button>
                <button className="small-secondary-btn" disabled={model.isLastPage || model.loading} style={{margin: "0 0.25em"}} onClick={()=>model.nextPage()}>&gt;</button>
            </div>
            <div style={{marginLeft: "1em"}}>
                <button className="small-secondary-btn" style={{margin: "0 0.25em"}} onClick={()=>model.downloadAllFilteredItems()} disabled={!model.hasFilteredItems}>Download</button>
            </div>
        </div>
        <div ref={tableContainerRef} className="flex-1 scrolling-table-container">
            <Table columns={columns} dataSource={model.pageItems} size="small" rootClassName="flex-1 flex-column" rowClassName="align-top" pagination={false} loading={model.loading} />
        </div>
    </div>;

}
