import { SecondaryHeadingWrapper } from "./styles";

const SecondaryHeading = ({ children, className, title }: any) => {
  return (
    <SecondaryHeadingWrapper>
      <h3 className={className} title={title}>{children}</h3>
    </SecondaryHeadingWrapper>
  );
};

export default SecondaryHeading;
