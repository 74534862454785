export default function ModelIcon(props) {
  return (
    <svg
      width="69"
      height="69"
      viewBox="0 0 69 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="69" height="69" rx="34.5" fill="#DDEFFD" />
      <path
        d="M37 41.25H33C32.59 41.25 32.25 40.91 32.25 40.5C32.25 40.09 32.59 39.75 33 39.75H37C37.41 39.75 37.75 40.09 37.75 40.5C37.75 40.91 37.41 41.25 37 41.25Z"
        fill="#3D9CF5"
      />
      <path
        d="M25 41.25C24.59 41.25 24.25 40.91 24.25 40.5V30.5C24.25 26.09 25.59 24.75 30 24.75C30.41 24.75 30.75 25.09 30.75 25.5C30.75 25.91 30.41 26.25 30 26.25C26.43 26.25 25.75 26.92 25.75 30.5V40.5C25.75 40.91 25.41 41.25 25 41.25Z"
        fill="#3D9CF5"
      />
      <path
        d="M45 41.25C44.59 41.25 44.25 40.91 44.25 40.5V30.5C44.25 26.92 43.57 26.25 40 26.25C39.59 26.25 39.25 25.91 39.25 25.5C39.25 25.09 39.59 24.75 40 24.75C44.41 24.75 45.75 26.09 45.75 30.5V40.5C45.75 40.91 45.41 41.25 45 41.25Z"
        fill="#3D9CF5"
      />
      <path
        d="M30.2 45.75H27.8C25.38 45.75 24.25 44.62 24.25 42.2V38.91C24.25 36.49 25.38 35.36 27.8 35.36H30.2C32.62 35.36 33.75 36.49 33.75 38.91V42.2C33.75 44.62 32.62 45.75 30.2 45.75ZM27.8 36.86C26.21 36.86 25.75 37.32 25.75 38.91V42.2C25.75 43.79 26.21 44.25 27.8 44.25H30.2C31.79 44.25 32.25 43.79 32.25 42.2V38.91C32.25 37.32 31.79 36.86 30.2 36.86H27.8Z"
        fill="#3D9CF5"
      />
      <path
        d="M42.2 45.75H39.8C37.38 45.75 36.25 44.62 36.25 42.2V38.91C36.25 36.49 37.38 35.36 39.8 35.36H42.2C44.62 35.36 45.75 36.49 45.75 38.91V42.2C45.75 44.62 44.62 45.75 42.2 45.75ZM39.8 36.86C38.21 36.86 37.75 37.32 37.75 38.91V42.2C37.75 43.79 38.21 44.25 39.8 44.25H42.2C43.79 44.25 44.25 43.79 44.25 42.2V38.91C44.25 37.32 43.79 36.86 42.2 36.86H39.8Z"
        fill="#3D9CF5"
      />
    </svg>
  );
}
