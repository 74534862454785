import styled from "styled-components";

export const InfoPanelWrapper = styled.div`
  form {
    margin-top: 38px;
  }
  .ant-form-item-label label {
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
  }

  .shifted-up {
    margin-top: -40px;
  }

  .label {
    font-family: "PoppinsRegular", sans-serif !important;
    font-size: 16px;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .right {
    float:right;
  }

  .shifted-down {
    margin-top: 25.66px;
  }

  .centered {
    margin-top: -10px;
  }

  .down {
    margin-bottom: 38px;
  }
  .up {
    margin-top: -80px;
  }
  .spaced {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .status {
    margin-top: 0px;
    margin-bottom: -15px;
  }

  .card-sub-heading {
    color: #949596 !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 0px;
  }
  .card-sub-text {
    color: #1c1c1c;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin: 0;
  }

  .custom-input {
    color: black !important;
  }

  .disabled-select {
    color: black !important;
  }
`;
