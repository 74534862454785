import { SubHeadinghWrapper } from "./styles";

const SubHeading = ({ children, className, title }: any) => {
  return (
    <SubHeadinghWrapper>
      <h3 className={className} title={title}>{children}</h3>
    </SubHeadinghWrapper>
  );
};

export default SubHeading;
