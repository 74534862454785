import Card from "antd/es/card/Card";
import { ChartDataWrapper } from "./styles";
import { Image, Row, Select } from "antd";
import SubHeading from "components/assets/text/SubHeading";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import Viewer from 'react-viewer';
import { useState } from "react";
import Output from "redux/classes/Output";
import { setVisualIndex } from "redux/slices/user";
import ButtonVelocity from "../ButtonVelocity";

const Visualizations = ({
  visuals,
  result,
  flow,
  path,
  download,
  extension,
  description,
}: any) => {
  const dispatch = useAppDispatch();
  const app = useAppSelector(state => state.app)
  const user = useAppSelector(state => state.user)
  const [visible, setVisible] = useState(false);

  return (
    <>
      <ChartDataWrapper>
        <Card>
          <Row className="centered">
            {visuals.length > 1 ? (
              <Select
                className="option"
                options={visuals.map((option: any, index: number) => ({
                  index: index,
                  title: "Click to select a different visualization",
                  label: option.label,
                  value: option.label
                }))}
                onChange={(e) => {
                  for (let i = 0; i < visuals.length; i++) {
                    if (visuals[i].label === e) {
                      dispatch(setVisualIndex({ index: i }))
                    }
                  }
                } }
                defaultValue={visuals[0].label}
                variant="borderless"
                size={"large"}
              />
            ) : visuals.length === 1 && result ? (
              <SubHeading>{visuals[0].label}</SubHeading>
            ) : <></>}

            {download &&
              <ButtonVelocity
                path={path}
                type="download"
                title={"Download " + visuals[user.visualIndex].path}
                onClick={download}
              >
                Download (.{extension})
              </ButtonVelocity>
            }

            {app.loading || visuals.length === 0 ?
              <div className="loader down"/> :
              <div className="centered" >
                <Image
                  className="flow"
                  title="Click to open viewer"
                  src={atob(visuals[user.visualIndex].result)}
                  preview={false}
                  onClick={() => setVisible(!visible)}
                  style={{cursor:'pointer'}}
                />
              </div>
            }
          </Row>
        </Card>
      </ChartDataWrapper>

      <Viewer
        visible={visible}
        images={visuals.map((visual: Output) => ({
          src: atob(visual.result),
          alt: visual.label
        }))}
        activeIndex={user.visualIndex}
        onClose={() => { setVisible(false) } }
        zoomSpeed={0.3}
        noClose={true}
        onMaskClick={() => { setVisible(false) } }
        zIndex={1000000000}
        minScale={1}
        showTotal={false}
      />
    </>
  );
};

export default Visualizations;