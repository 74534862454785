export default function VELOCITY(props) {
  return (
    <div title="Links to Home Page">
      <svg width={180 * props.scale} height={58 * props.scale} viewBox="0 0 59 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.8687 5.48557L6.82422 4.32717L9.76971 1.68359L10.8687 5.48557Z" fill="#1E6692"/>
      <path d="M4.42566 14.3726L1.56348 7.19297H3.61519L4.85782 10.5375C4.94005 10.7495 5.00847 10.9323 5.06308 11.0861C5.1177 11.2398 5.16556 11.3835 5.20667 11.5173C5.24763 11.6508 5.28906 11.7907 5.33002 11.9379C5.37098 12.0852 5.41194 12.2601 5.4529 12.4663H5.11325C5.17461 12.1994 5.23275 11.9735 5.28768 11.7888C5.3426 11.6041 5.40243 11.42 5.46717 11.2366C5.53221 11.0525 5.61597 10.8199 5.71845 10.5388L7.9087 4.55078L9.84167 4.9498L6.05672 14.3726H4.42566Z" fill="#1E6692"/>
      <path d="M9.75 14.3749V7.19531H14.9718V8.86733H11.6991V12.7034H15.1255V14.3758L9.75 14.3749ZM10.7142 11.5215V9.94197H14.5613V11.5219L10.7142 11.5215Z" fill="#1E6692"/>
      <path d="M16.5117 14.3749V7.19531H18.5427V12.6205H21.6723V14.3749H16.5117Z" fill="#1E6692"/>
      <path d="M25.9815 14.4792C25.459 14.4852 24.9403 14.3915 24.453 14.2031C24.0012 14.0271 23.5894 13.762 23.2422 13.4234C22.899 13.0868 22.6285 12.6835 22.4473 12.2383C22.0709 11.2767 22.0709 10.2085 22.4473 9.24683C22.6295 8.80381 22.9 8.40248 23.2422 8.06726C23.5892 7.72792 24.001 7.46185 24.453 7.28486C25.4369 6.91546 26.5214 6.91546 27.5053 7.28486C27.9562 7.46365 28.3675 7.7295 28.7157 8.06726C29.0599 8.40086 29.3308 8.80261 29.511 9.24683C29.6993 9.71985 29.7932 10.2252 29.7871 10.7343C29.7936 11.2485 29.6998 11.7591 29.511 12.2374C29.3318 12.6838 29.0609 13.0876 28.7157 13.4225C28.3674 13.7595 27.9561 14.0244 27.5053 14.2022C27.0197 14.3912 26.5024 14.4852 25.9815 14.4792ZM25.9815 12.5923C26.2173 12.5944 26.4511 12.549 26.669 12.4588C26.8784 12.3712 27.0666 12.24 27.2213 12.074C27.3803 11.9034 27.504 11.703 27.5854 11.4845C27.7566 11.0069 27.7566 10.4847 27.5854 10.0071C27.5041 9.78839 27.3804 9.58787 27.2213 9.41712C27.0665 9.25126 26.8783 9.12012 26.669 9.03237C26.4511 8.94233 26.2173 8.89694 25.9815 8.8989C25.7423 8.89629 25.5051 8.94167 25.2837 9.03237C25.0744 9.11996 24.8862 9.25112 24.7315 9.41712C24.573 9.58646 24.4491 9.78518 24.367 10.0021C24.1962 10.4815 24.1962 11.0051 24.367 11.4845C24.4483 11.7031 24.5722 11.9035 24.7315 12.074C24.886 12.2402 25.0743 12.3714 25.2837 12.4588C25.5051 12.5494 25.7423 12.5945 25.9815 12.5913V12.5923Z" fill="#1E6692"/>
      <path d="M34.4859 14.4811C33.9567 14.487 33.4312 14.3933 32.9367 14.2049C32.4808 14.0304 32.0641 13.7671 31.7107 13.4304C31.3644 13.0972 31.0918 12.6953 30.9103 12.2503C30.718 11.773 30.6222 11.2623 30.6282 10.7477C30.6228 10.2354 30.7224 9.72744 30.9209 9.25515C31.1097 8.80911 31.3872 8.40608 31.7364 8.07052C32.094 7.72909 32.5166 7.46302 32.9791 7.28813C33.4807 7.09964 34.013 7.00601 34.5489 7.01199C34.8864 7.01143 35.2226 7.05459 35.549 7.14039C35.8709 7.22432 36.1804 7.35028 36.4695 7.51502C36.756 7.6788 37.0152 7.88642 37.2376 8.13035L36.0171 9.54648C35.9004 9.41614 35.7697 9.29914 35.6272 9.19763C35.4783 9.09247 35.3138 9.01111 35.1399 8.95647C34.7092 8.82716 34.2481 8.84154 33.8264 8.99743C33.6089 9.07992 33.4114 9.2076 33.2469 9.37205C33.0788 9.54161 32.9478 9.74426 32.8622 9.96713C32.7658 10.2226 32.719 10.494 32.7241 10.767C32.7197 11.0317 32.7666 11.2947 32.8622 11.5416C32.9497 11.7642 33.0864 11.9642 33.2621 12.1265C33.4438 12.2917 33.6566 12.4191 33.888 12.5012C34.1499 12.5931 34.426 12.6382 34.7036 12.6342C34.9066 12.6354 35.1086 12.6043 35.3019 12.5421C35.4846 12.4831 35.6589 12.4003 35.8201 12.2959C35.9718 12.198 36.1128 12.0845 36.2407 11.9572L37.2072 13.4861C37.0099 13.6972 36.7761 13.8709 36.5169 13.9988C36.2101 14.1552 35.8856 14.2741 35.5504 14.3527C35.202 14.4379 34.8446 14.4811 34.4859 14.4811Z" fill="#1E6692"/>
      <path d="M38.1582 14.3768V12.7149H39.41V8.85721H38.1582V7.19531H42.6827V8.85721H41.4401V12.7149H42.6827V14.3768H38.1582Z" fill="#1E6692"/>
      <path d="M45.9452 14.3768V8.94971H43.7725V7.19531H50.2254V8.94971H47.979V14.3768H45.9452Z" fill="#1E6692"/>
      <path d="M53.3217 14.3768V10.7962L53.3935 11.2987L50.4922 7.19531H52.7699L54.9859 10.3755L53.7852 10.3447L55.7849 7.19531H58.0009L55.2206 11.412L55.3748 10.7142V14.3768H53.3217Z" fill="#1E6692"/>
      </svg>
    </div>
  );
}
