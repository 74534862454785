import { useViewModel } from "mvvm/View";
import { PageViewModel } from "../ViewModels/PageViewModel";
import { JobItemView } from "./JobItemView";
import { QuestionView } from "./QuestionView";
import { JobDetailView } from "./JobDetailView";
import { Select } from "antd";
import { useEffect, useState, useRef } from "react";


export function PageView(props: { model: PageViewModel }) {

    const jobList = useRef<HTMLDivElement>(null);
    const model = useViewModel(props.model);
    const selectedJob = model.selectedJob;

    const [tagList, setTagList] = useState<string[]>([]);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);

    const updateTagList = () => {
        let allTags: string[] = []
        for (let job of model.jobs) {
            for (let tag of job.tags) {
                if (!allTags.includes(tag)) {
                    allTags.push(tag);
                }
            }
        }
        setTagList(allTags);
    }

    const updateJobTag = (jobID: string, tags: string[]) => {
        const job = model.jobs.find(job => job.jobID === jobID);
        if (job) {
            job.tags = tags;
        }

        let allTags: string[] = []
        for (let job of model.jobs) {
            for (let tag of job.tags) {
                if (!allTags.includes(tag)) {
                    allTags.push(tag);
                }
            }
        }
        setTagList(allTags);
    }

    useEffect(() => {
        if (jobList.current) {
            jobList.current.scrollTop = 0;

            updateTagList();
        }
    }, [model.jobs])

    return (
        <div className="flex-1 flex-column" style={{ background: "white"}}>
            <div className="flex-2 flex-row" style={{ minHeight: "12em"}}>
                <div className="flex-1 flex-column" style={{ padding: "1em", paddingRight: "0.5em" }}>
                    <QuestionView model={model.question} />
                </div>
                <div className="flex-1 flex-column" style={{ padding: "1em", paddingLeft: "0.5em", position: "relative" }}>
                    <div className="flex-1 flex-column">
                        <div className="flex-row" style={{ padding: "0.5em 0.2em 0.5em 0.2em" }}>
                            <div className="flex-1"><b>Previous Requests</b></div>
                            <Select
                                mode="multiple"
                                className="flex-1"
                                style={{ height: "1.75em", marginBottom: "-0.25em"}}
                                placeholder="Filter by tags..."
                                size="small"
                                maxTagCount='responsive'
                                value={selectedTags}
                                onChange={setSelectedTags}
                                allowClear={true}
                            >
                                {tagList.map(tag => (
                                    <Select.Option key={tag} value={tag}>{tag}</Select.Option>
                                ))}
                            </Select>
                        </div>
                        <div ref={jobList} className="flex-1 scroll" style={{ border: "1px solid #ccc", borderRadius: "0.5em", padding: "0em"}}>
                            {
                                model.jobs
                                    .filter(job => selectedTags.length === 0 || selectedTags.some(tag => job.tags && job.tags.includes(tag)))
                                    .map(job => <JobItemView key={job.jobID} model={job} />)
                            }
                        </div>
                        <div className={"requests-blocker " + (model.isSubmitting ? "loading " : "")} />
                    </div>
                </div>
            </div>
            <div className="flex-5 flex-column" style={{ padding: "1em", paddingTop: "0", minHeight: "12em" }}>
                <div className="flex-1 flex-column"  >
                { selectedJob && <JobDetailView model={selectedJob} tagList={tagList} tagUpdater={updateJobTag} />}
                { !selectedJob && <div className="flex-1 flex-column">
                    <div className="flex-row" style={{ margin: "0.5em 0"}}>
                        <div className="flex-1" style={{paddingLeft: "0.5em"}}><b>Response</b></div>
                    </div>
                    <div className="flex-1 scroll" style={{ border: "1px solid #ccc", borderRadius: "0.5em", padding: "0.5em"}}>
                        <div style={{color: "#999"}}>Submit a request or select a previous request to start.</div>
                    </div>
                    </div>
                }
                </div>
            </div>
        </div>
    )
}