import { Card, Checkbox, Col, DatePicker, Form, Input, Radio, Row, Select } from "antd";
import MultiSelect from "components/assets/utilities/MultiSelect";
import SecondaryHeading from "components/assets/text/SecondaryHeading";
import { ParametersWrapper } from "./styles";
import Field from "redux/classes/Field";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { modifyConfig, setJobPanelsComplete, updateJobPanelsComplete } from "redux/slices/user";
import { useEffect } from "react";
import type { RadioChangeEvent } from 'antd';
import dayjs from "dayjs";

const { TextArea } = Input;
const { RangePicker } = DatePicker;

const Parameters = () => {
  const dispatch = useAppDispatch();
  const app = useAppSelector(state => state.app)
  const user = useAppSelector(state => state.user)
  const modelInfo = user.models[user.modelIndex];
  const fields = modelInfo.parameters;

  const [form] = Form.useForm();

  useEffect(() => {
    if (user.config) {
      let parameters: {[key: string]: any} = {};

      for (let field of fields) {
        switch (field.fieldType) {
          case "textbox small":
          case "textbox large":
          case "date":
          case "week":
          case "month":
          case "quarter":
          case "year":
          case "radio":
          case "dropdown":
            if (field.defaultValue !== null) {
              parameters[field.label] = field.defaultValue
            }
            break;
          case "sliders":
            if (field.defaultValue !== null) {
              parameters[field.label] = [field.defaultValue[0].map((value: string) => parseFloat(value)), field.defaultValue[1].map((value: string) => parseFloat(value))];
            }
            break;
          case "date range":
          case "week range":
          case "month range":
          case "quarter range":
          case "year range":
          case "multi-dropdown":
            if (field.defaultValue !== null) {
              parameters[field.label] = field.defaultValue.split(",")
            }
            break;
          case "checkbox":
            parameters[field.label] = field.defaultValue !== null ? field.defaultValue : false
            break;
          case "checkboxes":
            const values: {[key: string]: boolean} = {}
            field.options.forEach(option => values[option] = option in (field.defaultValue !== null ? field.defaultValue.split(",") : []))
            parameters[field.label] = values
            break;
          case "dropdowns":
            parameters[field.label] = field.defaultValue !== null ? field.defaultValue.slice(1) : null
            break;
        }
      }

      dispatch(modifyConfig({
        type: "parameters",
        value: parameters
      }))
    }
  }, [user.config])

  useEffect(() => {
    if (app.submitting) {
      form.validateFields().then(() => {
        dispatch(updateJobPanelsComplete({ panel: 'parameters', complete: true }))
      }).catch(() => {
        dispatch(setJobPanelsComplete({ jobPanelsComplete: undefined }))
      })
    }
  }, [app.submitting])

  return (
    <ParametersWrapper>
      <Card className="height-control">
        <SecondaryHeading title="Specify the Input Parameters that should be used in your job">Parameters</SecondaryHeading>

        {fields.length > 0 && (
          <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            requiredMark={false}
          >
            <Row gutter={[64, 12]}>
              {fields.map((field: Field) => {
                switch (field.fieldType) {
                  case "checkbox":
                    return (
                      <Col span={8} key={field.fieldID}>
                        <Form.Item
                          label={field.label}
                          name={field.label}
                        >
                          <Checkbox
                            onChange={(e) => {
                              let parameters = user.config.parameters
                              parameters[field.label] = e.target.checked
                              dispatch(modifyConfig({
                                type: "parameters",
                                value: parameters
                              }))
                            }}
                            defaultChecked={field.defaultValue !== null ? field.defaultValue === "true" : false}
                            disabled={app.submitting}
                          />
                        </Form.Item>
                      </Col>
                    )
                  case "checkboxes":
                    return (
                      <Col span={8} key={field.fieldID}>
                        <Form.Item
                          label={field.label}
                          name={field.label}
                        >
                          <Checkbox.Group
                            options={field.options.map((option, index) => ({
                              label: option,
                              value: option,
                              key: `${field.label}-${option}`,
                            }))}
                            value={field.defaultValue !== null ? field.defaultValue.split(",") : undefined}
                            onChange={(e) => {
                              let parameters = user.config.parameters

                              const values: {[key: string]: boolean} = {}
                              field.options.forEach(option => {
                                if (e.includes(option)) {
                                  values[option] = true
                                }
                                else {
                                  values[option] = false
                                }
                              })

                              parameters[field.label] = values
                              dispatch(modifyConfig({
                                type: "parameters",
                                value: parameters
                              }))
                            }}
                            disabled={app.submitting}
                          />
                        </Form.Item>
                      </Col>
                    )
                  case "date":
                  case "week":
                  case "month":
                  case "quarter":
                  case "year":
                    let dateObject: Date | null = null;
                    if (field.defaultValue) {
                      dateObject = new Date(field.defaultValue)
                    }

                    return (
                      <Col span={8} key={field.fieldID}>
                        <Form.Item
                          label={field.label}
                          name={field.label}
                          //if option.length == 2, create a rule where the min date is the first date and the max date is the second date
                          rules={[
                            {
                              required: true,
                              message: "Please select a date"
                            },
                            {
                              min: field.options.length === 2 ? new Date(field.options[0]).getTime() : undefined,
                              max: field.options.length === 2 ? new Date(field.options[1]).getTime() : undefined,
                              message: `Please select a date between ${field.options[0]} and ${field.options[1]}`
                            }
                          ]}
                        >
                          <DatePicker
                            allowClear={false}
                            value={dateObject ? dayjs(dateObject + ' -08:00') : undefined}
                            style={{ 'width': '70%' }}
                            picker={field.fieldType === "date" ? undefined : field.fieldType}
                            onChange={(date, dateString) => {
                              if (date) {
                                let parameters = user.config.parameters
                                parameters[field.label] = dateString
                                dispatch(modifyConfig({
                                  type: "parameters",
                                  value: parameters
                                }))
                              }
                            }}
                            disabled={app.submitting}
                          />
                        </Form.Item>
                      </Col>
                    )
                  case "date range":
                  case "week range":
                  case "month range":
                  case "quarter range":
                  case "year range":
                    return (
                      <Col span={8} key={field.fieldID}>
                        <Form.Item
                          label={field.label}
                          name={field.label}
                          rules={[
                            {
                              required: true,
                              message: "Please select a date range"
                            }
                          ]}
                        >
                          <RangePicker
                            className="range"
                            allowClear={false}
                            value={field.defaultValue !== null ? [dayjs(new Date(field.defaultValue.split(",")[0]) + ' -08:00'), dayjs(new Date(field.defaultValue.split(",")[1]) + ' -08:00')] : undefined}
                            picker={field.fieldType === "week range" ? "week" : (
                              field.fieldType === "month range" ? "month" : (
                                field.fieldType === "quarter range" ? "quarter" : (
                                  field.fieldType === "year range" ? "year" : undefined
                                )
                              )
                            )}
                            onChange={(date, dateString) => {
                              if (date) {
                                let parameters = user.config.parameters
                                parameters[field.label] = dateString
                                dispatch(modifyConfig({
                                  type: "parameters",
                                  value: parameters
                                }))
                              }
                            }}
                            disabled={app.submitting}
                          />
                        </Form.Item>
                      </Col>
                    )
                  case "radio":
                    return (
                      <Col span={8} key={field.fieldID}>
                        <Form.Item
                          label={field.label}
                          name={field.label}
                        >
                          <Radio.Group
                            className="radio"
                            size="large"
                            value={field.defaultValue}
                            onChange={(e: RadioChangeEvent) => {
                              let parameters = user.config.parameters
                              parameters[field.label] = e.target.value
                              dispatch(modifyConfig({
                                type: "parameters",
                                value: parameters
                              }))
                            }}
                            disabled={app.submitting}
                          >
                            {field.options.map((option: any, index: number) => {
                              return (
                                <Radio.Button
                                  value={option}
                                  key={`${field.label}-${index}`}
                                >{option}</Radio.Button>
                              )
                            })}
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    )
                  case "dropdown":
                    return (
                      <Col span={8} key={field.fieldID}>
                        <Form.Item
                          hasFeedback
                          label={field.label}
                          name={field.label}
                          rules={[
                            {
                              required: true,
                              message: "Please select one"
                            }
                          ]}
                        >
                          <Select
                            id={field.label}
                            options={field.options.map((option: any) => ({
                              label: option,
                              value: option,
                              key: `${field.label}-${option}`
                            }))}
                            value={field.defaultValue}
                            placeholder={"Select one"}
                            onChange={(value) => {
                              let parameters = { ...user.config.parameters };
                              parameters[field.label] = value;

                              // Prepare an object to update multiple form values
                              const updatedValues: { [key: string]: any } = {};
                              fields.forEach((f) => {
                                f.options.forEach((o) => {
                                  if (o[0] === field.label && o[1] === value) {
                                    updatedValues[f.label] = o[2]; // Set the new value for the related field
                                  }
                                });
                              });

                              // Update form values using setFieldsValue
                              form.setFieldsValue(updatedValues);

                              // iterate through all fields, if you find a field that has an options array where the first element is the label of the current field, set the value of that field to the third element of the options array
                              for (let f of fields) {
                                for (let o of f.options) {
                                  if (o[0] === field.label && o[1] === value) {
                                    const formItem = document.getElementById(f.label) as HTMLInputElement
                                    if (formItem) {
                                      formItem.value = o[2]
                                    }
                                    parameters[f.label] = o[2]
                                  }
                                }
                              }

                              // Update local state or context if necessary
                              dispatch(modifyConfig({
                                type: "parameters",
                                value: parameters
                              }));
                            }}
                            showSearch
                            disabled={app.submitting}
                          />
                        </Form.Item>
                      </Col>
                    )
                  case "multi-dropdown":
                    return (
                      <Col span={8} key={field.fieldID}>
                        <Form.Item
                          label={field.label}
                          name={field.label}
                          rules={[
                            {
                              required: true,
                              message: "Please select one or more values"
                            }
                          ]}
                        >
                          <MultiSelect
                            options={field.options.map((option: any) => ({
                              label: option,
                              value: option,
                              key: `${field.label}-${option}`
                            }))}
                            placeholder={"Select one"}
                            changeHandler={(e: string[]) => {
                              let parameters = user.config.parameters
                              parameters[field.label] = e
                              dispatch(modifyConfig({
                                type: "parameters",
                                value: parameters
                              }))
                            }}
                            disabled={app.submitting}
                          />
                        </Form.Item>
                      </Col>
                    )
                  case "textbox small":
                    return (
                      <Col span={8} key={field.fieldID}>
                        <Form.Item
                          label={field.label}
                          name={field.label}
                          rules={[
                            {
                              required: true,
                              message: "Please enter a value"
                            },
                            {
                              validator: (_, value) => {
                                if (value && field.dataType === 'int') {
                                  if (!/^\d+$/.test(value)) {
                                    return Promise.reject("Please enter an integer");
                                  }

                                  if (field.options.length !== 2 || parseInt(value) < parseInt(field.options[0]) || parseInt(value) > parseInt(field.options[1])) {
                                    return Promise.reject(`Please enter a value between ${field.options[0]} and ${field.options[1]}`);
                                  }
                                }
                                return Promise.resolve();
                              },
                            },
                            {
                              validator: (_, value) => {
                                if (value && field.dataType === 'float') {
                                  if (!/^\d+(\.\d+)?$/.test(value)) {
                                    return Promise.reject("Please enter a float");
                                  }

                                  if (field.options.length === 2 && (parseFloat(value) < parseFloat(field.options[0]) || parseFloat(value) > parseFloat(field.options[1]))) {
                                    return Promise.reject(`Please enter a value between ${field.options[0]} and ${field.options[1]}`);
                                  }
                                }
                                return Promise.resolve();
                              },
                            },
                            {
                              validator: (_, value) => {
                                if (value && field.dataType === 'int[]') {
                                  const strArray = value.split(',')
                                  const intArray = strArray.map((x: string) => /^\d+$/.test(x.trim()) ? parseInt(x) : NaN)

                                  if (intArray.includes(NaN)) {
                                    return Promise.reject("Please enter a comma-separated list of integers");
                                  }

                                  if (field.options.length !== 2 || !intArray.every((x: number) => x >= parseInt(field.options[0]) && x <= parseInt(field.options[1]))) {
                                    return Promise.reject(`Please enter a comma-separated list of integers between ${field.options[0]} and ${field.options[1]}`);
                                  }
                                }
                                return Promise.resolve();
                              },
                            },
                            {
                              validator: (_, value) => {
                                if (value && field.dataType === 'float[]') {
                                  const strArray = value.split(',')
                                  const floatArray = strArray.map((x: string) => /^\d+(\.\d+)?$/.test(x.trim()) ? parseFloat(x) : NaN)

                                  if (floatArray.includes(NaN)) {
                                    return Promise.reject("Please enter a comma-separated list of floats");
                                  }

                                  if (field.options.length !== 2 || !floatArray.every((x: number) => x >= parseFloat(field.options[0]) && x <= parseFloat(field.options[1]))) {
                                    return Promise.reject(`Please enter a comma-separated list of floats between ${field.options[0]} and ${field.options[1]}`);
                                  }
                                }
                                return Promise.resolve();
                              },
                            }
                          ]}
                        >
                          <Input
                            type="text"
                            id={field.label}
                            value={field.defaultValue}
                            onChange={e => {
                              let parameters = user.config.parameters

                              if (field.dataType === 'string') {
                                parameters[field.label] = e.target.value
                              }
                              else if (field.dataType === 'int[]') {
                                const strArray = e.target.value.split(',')
                                const intArray = strArray.map(x => /^\d+$/.test(x.trim()) ? parseInt(x) : NaN)

                                parameters[field.label] = intArray
                                if (intArray.includes(NaN)) {
                                  parameters[field.label] = undefined
                                }
                              }
                              dispatch(modifyConfig({
                                type: "parameters",
                                value: parameters
                              }))
                            }}
                            placeholder={field.dataType === 'int[]' ? "Comma-separated list of integers" : ""}
                            disabled={app.submitting}
                            style={{ height: "3.75em" }}
                          />
                        </Form.Item>
                      </Col>
                    )
                  case "textbox large":
                    return (
                      <Col span={24} key={field.fieldID}>
                        <Form.Item
                          label={field.label}
                          name={field.label}
                          rules={[
                            {
                              required: true,
                              message: "Please enter a value"
                            },
                            {
                              validator: (_, value) => {
                                if (value && field.dataType === 'int') {
                                  if (!/^\d+$/.test(value)) {
                                    return Promise.reject("Please enter an integer");
                                  }

                                  if (field.options.length !== 2 || parseInt(value) < parseInt(field.options[0]) || parseInt(value) > parseInt(field.options[1])) {
                                    return Promise.reject(`Please enter a value between ${field.options[0]} and ${field.options[1]}`);
                                  }
                                }
                                return Promise.resolve();
                              },
                            },
                            {
                              validator: (_, value) => {
                                if (value && field.dataType === 'float') {
                                  if (!/^\d+(\.\d+)?$/.test(value)) {
                                    return Promise.reject("Please enter a float");
                                  }

                                  if (field.options.length !== 2 || parseFloat(value) < parseFloat(field.options[0]) || parseFloat(value) > parseFloat(field.options[1])) {
                                    return Promise.reject(`Please enter a value between ${field.options[0]} and ${field.options[1]}`);
                                  }
                                }
                                return Promise.resolve();
                              },
                            },
                            {
                              validator: (_, value) => {
                                if (value && field.dataType === 'int[]') {
                                  const strArray = value.split(',')
                                  const intArray = strArray.map((x: string) => /^\d+$/.test(x.trim()) ? parseInt(x) : NaN)

                                  if (intArray.includes(NaN)) {
                                    return Promise.reject("Please enter a comma-separated list of integers");
                                  }

                                  if (field.options.length !== 2 || !intArray.every((x: number) => x >= parseInt(field.options[0]) && x <= parseInt(field.options[1]))) {
                                    return Promise.reject(`Please enter a comma-separated list of integers between ${field.options[0]} and ${field.options[1]}`);
                                  }
                                }
                                return Promise.resolve();
                              },
                            },
                            {
                              validator: (_, value) => {
                                if (value && field.dataType === 'float[]') {
                                  const strArray = value.split(',')
                                  const floatArray = strArray.map((x: string) => /^\d+(\.\d+)?$/.test(x.trim()) ? parseFloat(x) : NaN)

                                  if (floatArray.includes(NaN)) {
                                    return Promise.reject("Please enter a comma-separated list of floats");
                                  }

                                  if (field.options.length !== 2 || !floatArray.every((x: number) => x >= parseFloat(field.options[0]) && x <= parseFloat(field.options[1]))) {
                                    return Promise.reject(`Please enter a comma-separated list of floats between ${field.options[0]} and ${field.options[1]}`);
                                  }
                                }
                                return Promise.resolve();
                              },
                            }
                          ]}
                        >
                          <TextArea
                            rows={8}
                            className="large"
                            value={field.defaultValue}
                            onChange={(e) => {
                              let parameters = user.config.parameters
                              parameters[field.label] = e.target.value
                              dispatch(modifyConfig({
                                type: "parameters",
                                value: parameters
                              }))
                            }}
                            disabled={app.submitting}
                          />
                        </Form.Item>
                      </Col>
                    )
                  default:
                    return null
                }
              })}
            </Row>
          </Form>
        )}
      </Card>
    </ParametersWrapper>
  );
};

export default Parameters;
