import { Card, Col, Form, Input, Row } from "antd";
import SecondaryHeading from "components/assets/text/SecondaryHeading";
import { ConstraintsWrapper } from "./styles";
import Field from "redux/classes/Field";
import { useAppSelector } from "redux/hooks";
import React from "react";
import Subtitle from "components/assets/text/Subtitle";

const ConstraintsDisabled = ({
  values
}: any) => {
  const user = useAppSelector(state => state.user)
  const modelInfo = user.models[user.modelIndex];
  const jobInfo = user.jobs[user.jobIndex];
  const fields = modelInfo.parameters;

  return (
    <ConstraintsWrapper>
      {Object.keys(values).length === 0 ? null : (
        <Card>
          <SecondaryHeading title={"Input Constraints that were used in " + jobInfo.jobName}>Constraints</SecondaryHeading>

          <Form layout="vertical" autoComplete="off">
            <Row gutter={[64, 12]}>
              {fields.map((field: Field) => {
                switch (field.fieldType) {
                  case "dropdowns":
                    return (
                      <Col span={24} key={field.fieldID}>
                        <Row gutter={[64, 20]}>
                          {
                            field.options[0].map((option: any, index: number) => {
                              return (
                                <Col
                                  span={8}
                                  key={field.fieldID + "_titles_" + index}
                                  style={{ textAlign: "center" }}
                                >
                                  <SecondaryHeading>{option}</SecondaryHeading>
                                </Col>
                              )
                            })
                          }
                          {
                            field.options[1].slice(1).map((option: any, index: number) => {
                              return (
                                <React.Fragment key={field.fieldID + "_options_" + index}>
                                  <Col
                                    span={8}
                                    style={{ marginTop: "1em" }}
                                  >
                                    <Subtitle>{option}</Subtitle>
                                  </Col>
                                  <Col
                                    span={8}
                                  >
                                    <Input
                                      className="custom-input"
                                      type="text"
                                      value={values[field.label][index][0]}
                                      disabled
                                    />
                                  </Col>
                                  <Col
                                    span={8}
                                  >
                                    <Input
                                      className="custom-input"
                                      type="text"
                                      value={values[field.label][index][1]}
                                      disabled
                                    />
                                  </Col>
                                </React.Fragment>
                              )
                            })
                          }
                        </Row>
                      </Col>
                    )
                  default:
                    return null
                }
              })}
            </Row>
          </Form>
        </Card>
      )}
    </ConstraintsWrapper>
  );
};

export default ConstraintsDisabled;
