import styled from "styled-components";

export const JobStatusWrapper = styled.div`
  .status {
    display: inline-flex;
    align-items: center;
    padding: 4.5px 13px;
    border-radius: 24px;
    gap: 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: 150%;
    width: 100px;
    justify-content: center;
  }
  .running-status {
    background: rgba(18, 203, 103, 0.1);
    color: #12cb67;
  }
  .review-status {
    color: #f2ab01;
    background: rgba(242, 171, 1, 0.1);
  }
  .published-status {
    color: #3d9cf5;
    background: rgba(49, 123, 235, 0.1);
  }
  .passed-status {
    color: #12cb67;
    background: rgba(18, 203, 103, 0.1);
  }
  .warning-status {
    color: #f2ab01;
    background: rgba(242, 171, 1, 0.1);
  }
  .failed-status {
    color: #f20f01;
    background: rgba(242, 15, 1, 0.1);
  }
`;
